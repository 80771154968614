export const sidebarMenu = [
    {
        id: 1,
        label: 'ABOUT US',
        route:`/about-us`
    },
    
    {
        id: 2,
        label: 'PRIVACY',
        route:`/privacy`
    },
    {
        id: 3,
        label: 'CAREERS',
        route:`/career-opportunites`
    },
    {
        id: 4,
        label: 'AMBASSADOR PROGRAM',
        route:`/ambassador-program`
    },
    {
        id: 5,
        label: 'FUNDRAISE',
        route:`/fundraiser-program`
    }
]
export const AboutUs = [
    {
      title: '',
      content: 'StitchZone is a premier embroidery company specializing in custom Greek clothing for fraternities and sororities. With a commitment to quality and personalization, we cater to the unique needs of Greek organizations, companies, sports teams, and individuals seeking custom embroidery solutions.',
    },
    {
      title: 'Our Vision',
      content: 'At StitchZone, our vision is to be the leading provider of high-quality, custom-embroidered apparel, known for our attention to detail and customer-centric approach. We aim to enhance the pride and unity of organizations and individuals through our bespoke embroidery services.',
    },
    {
      title: 'What We Offer',
      content: `1. Greek Clothing: We specialize in creating customized apparel for fraternities and sororities, including shirts, hoodies, jackets, and accessories. Our designs celebrate Greek life and heritage, ensuring members can proudly display their affiliations.`,
    },
    {
      title: '',
      content: `2. Corporate Embroidery: StitchZone offers professional embroidery services for companies looking to brand their uniforms, promotional items, and corporate gifts. Our high-quality embroidery adds a touch of professionalism and distinction to your corporate identity.`,
    },
    {
      title: '',
      content: `3. Team Apparel: Whether for sports teams, clubs, or community groups, we provide custom embroidery for uniforms, hats, and gear. Our designs help foster team spirit and unity.`,
    },
    {
      title: '',
      content: `4. Personalized Items: From monogrammed towels to personalized gifts, we cater to individuals seeking custom embroidery for personal use. Our services are perfect for adding a unique touch to your belongings or gifting a special someone.`,
    },
    {
      title: 'Our Commitment to Quality',
      content: `Quality is at the heart of everything we do at StitchZone. We use top-of-the-line embroidery machines and premium threads to ensure that every stitch is precise and durable. Our experienced team works diligently to bring your designs to life with meticulous attention to detail.`,
    },
    {
      title: 'Customization Process',
      content: `Our customization process is designed to be seamless and customer-friendly:`,
    },
    {
      title: '',
      content: `• Consultation: We begin with a detailed consultation to understand your specific needs and design preferences.`,
    },
    {
      title: '',
      content: `• Design Approval: Our design team creates a digital mock-up for your approval before starting the embroidery process.`,
    },
    {
      title: '',
      content: `• Embroidery: Using advanced technology, we embroider your approved design onto the selected apparel or items.`,
    },
    {
      title: '',
      content: `• Quality Check: Every piece undergoes a rigorous quality check to ensure it meets our high standards before delivery.`,
    },
    {
      title: 'Join the StitchZone Community',
      content: `Join a community that values quality, creativity, and personalization. At StitchZone, we are dedicated to providing exceptional embroidery services that meet your unique needs. Whether you're part of a Greek organization, a business, a team, or an individual, StitchZone is here to help you make a statement.`,
    },
    {
      title: '',
      content: `Contact Us:`,
    },
    {
      title: '',
      content: `• Website: www.stitchzone.com`,
    },
    {
      title: '',
      content: `• Email: info@stitchzone.com`,
    },
    {
      title: '',
      content: `• Social Media: Follow us on Facebook, Instagram, and Twitter @StitchZone`,
    },
    {
      title: '',
      content: `Experience the art of embroidery with StitchZone – where every stitch tells your story.`,
    },
  ];

export const privacy = [
  {
    title:``,
    content:`At StitchZone, we understand the importance of your privacy and are committed to protecting it. This Privacy Statement outlines how we collect, use, disclose, and safeguard your information when you visit our website or make a purchase from us.`
  },
  {
    title:`Information We Collect`,
    content:`When you visit our website, we may collect personal information such as your name, email address, shipping address, and payment information. This information is collected when you voluntarily submit it to us through our online forms or during the checkout process.`
  },
  {
    title:`How We Use Your Information`,
    content:`We use the information we collect in the following ways:`
   },
  {
    title:``,
    content:`
  1. To Process Your Orders: We use your name, shipping address, and payment information to process and fulfill your orders.`},
  {
    title:``,
    content:`2. To Communicate With You: We may use your email address to send you order confirmations, updates, and marketing communications (if you have opted in to receive them).`
  },
  {
    title:``,
    content:`3. To Improve Our Website: We analyze how our visitors use our website to improve the user experience and optimize our website's performance.`
  },
  {
    title:`Data Security`,
    content:`We take the security of your information seriously. We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, disclosure, alteration, or destruction.`
  },
  {
    title:`Sharing Your Information`,
    content:`We do not sell, trade, or otherwise transfer your personal information to outside parties except as necessary to fulfill your order (e.g., to shipping carriers) or as required by law.`
  },
  {
    title:`Your Rights`,
    content:`You have the right to access, correct, update, or delete your personal information at any time. If you would like to exercise any of these rights, please contact us using the contact information provided below.`
  },
  {
    title:`Changes to This Privacy Statement`,
    content:`We may update this Privacy Statement from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Statement on our website.`
  },
  {
    title:`Contact Us`,
    content:`If you have any questions about this Privacy Statement or our privacy practices, please contact us at info@stitchzone.com or at (123) 456-7890`
  },
]

export const career = [
  {
    title:``,
    content:`Are you ready to take the next step in your career journey? We want to hear from you! If you're enthusiastic about joining our team and believe you have what it takes to excel with us, don't hesitate to reach out. Simply email us your information, resume, and availability to jobs@stitchzone.com. We look forward to connecting with you and discovering how you can contribute to our innovative and vibrant team.`
  },
  {
    title:``,
    content:`• Production Assistant `,
    color:`blue`
  },
  {
    title:``,
    content:`• Sales Associate `,
    color:`blue`
  },
  {
    title:``,
    content:`• Social Media Specialist`,
    color:`blue`
  }
]
export const ambassador=[
  {
    title:`Partner with Us`,
    content:`Welcome to StitchZone’'s Ambassador Program designed for organizations that value creativity and quality craftsmanship! We invite you to join us in promoting our embroidery products while enjoying exclusive benefits and collaborative opportunities.`
  },
  {
    title:`About the Program`,
    content:`Our Ambassador Program is tailored for organizations that want to align with a brand known for its dedication to quality and creativity. As an ambassador, your organization will play a crucial role in representing our brand to your community and beyond.`
  },
  {
    title:`Benefits of Partnership`,
    content:`Exclusive Discounts: Your organization may receive special discounts on our high-quality embroidery products.
Early Access: Be the first to know about new product launches and have the opportunity to provide feedback and suggestions.
Collaboration Opportunities: Partner with us on joint promotional activities, contests, and events to enhance visibility and engagement.
Feature Opportunities: Showcase your organization's projects and achievements on our platform and social media channels.`
  },
  {
    title:`Ambassador Responsibilities`,
    content:`Promotion: Share your organization's experiences with our products on your social media platforms and through your communication channels.
content Creation: Create engaging content such as reviews, testimonials, and stories featuring our embroidery products.
Networking: Connect with other ambassadors and organizations in our network, sharing knowledge and inspiring creativity.`
  },
  {
    title:`How to Apply`,
    content:`To apply for our Ambassador Program for organizations, please fill out the application form [link to application form]. We review applications on a rolling basis and will contact selected ambassadors directly.`
  },
  {
    title:`Join Us Today!`,
    content:`Join Us Today!Partner with us to promote creativity and quality craftsmanship within your organization and beyond. Apply now to become a part of our ambassador community and enjoy exclusive benefits and collaborative opportunities.
`
  },
  {
    title:``,
    content:`For more information or inquiries, please contact us at info@stitchzone.com or (123) 456-7890.`
  },
]
export const fundraiser = [
  {
    title:``,
    content:`We are excited to be a part of something truly impactful. At StitchZone, we believe in your organization and your passion to reach your goals.`
  },
  {
    title:``,
    content:`A special fundraiser discount code will be given to your organization. Through this campaign, we aim to assist in reaching your outcomes`
  },
  {
    title:``,
    content:`If you refer others to purchase item(s) using this special fundraiser discount code, they will be given a 5% discount off the regular advertised purchased price. Your organization will then be given a 5% commission of that purchase.`
  },
  {
    title:``,
    content:`This fundraising promotion runs for 3 months, per school year.`
  },
  {
    title:`Note:`,
    content:` This fundraiser is not valid for promotional discount pricing, discounted group orders and rush orders. The fundraiser discount code will have an expiration date noted. The commission check will be paid at the end of the promotion, with a minimum of $25.00 earned.`
  }
]
export const shipingAndPickup = [
  {
    title:`Shipping vs. Pick-Up: Choose What Works Best for You`,
    content:`At StitchZone, we understand that convenience is key when it comes to receiving your beautifully crafted embroidery pieces. That’s why we offer you the flexibility to choose between shipping your order directly to your doorstep or picking it up in person at our location.`
  },
  {
    title:`Shipping Options:`,
    content:`- Nationwide Delivery: Enjoy the convenience of having your order shipped right to your door. We offer reliable shipping services to ensure your embroidered items arrive safely and on time, wherever you are in the country.` },
  {
    title:``,
    content:`- Shipping Rates: Our shipping rates are calculated based on your location and the size of your order. We strive to keep these costs affordable while maintaining high standards of delivery. Please note that international and RUSH shipments will be subject to additional charges. Select the shipping option that best suits your needs during checkout. Prices vary based on the delivery speed selected and the total weight of your order.`
  },
  {
    title:``,
    content:`- Domestic Shipping Rates (within the United States).`
  },
  {
    title:``,
    content:`- Tracking: Stay informed with tracking information provided for all shipped orders. You’ll know exactly when to expect your package.`
  },
  {
    title:`Pick-Up Option:`,
    content:`- Local Pick-Up: If you’re located nearby or prefer a more personal touch, you can choose to pick up your order directly from our store. This option allows you to inspect your items in person and interact with our friendly staff.
  - Convenience: Skip the shipping time and costs by picking up your order at a time that suits you. We’ll notify you as soon as your items are ready for collection.`
  },
  {
    title:``,
    content:`- Convenience: Skip the shipping time and costs by picking up your order at a time that suits you. We’ll notify you as soon as your items are ready for collection.`
  },
  {
    title:`How to Choose:`,
    content:`When placing your order, simply select your preferred delivery method during checkout. Whether you opt for the convenience of shipping or the personalized service of pick-up, we are committed to ensuring your experience with StitchZone is seamless and enjoyable.`
  },
  {
    title:``,
    content:`For any questions regarding shipping rates, pick-up times, or general inquiries, feel free to reach out to our customer service team. We’re here to assist you every step of the way.`
  },
]

export const refundAndReturn = [
  {
    title:``,
    content:`At StitchZone, we strive to ensure your satisfaction with every order. As all our items are custom-made, please review our policy regarding refunds and returns below:`
  },
  {
    title:`Refunds:`,
    content:`- Defective or Incorrect Items: If you receive a defective item or an incorrect item, please contact us within 72 hours of receiving your order. We will replace the item or issue a refund, depending on your preference. We may require photo evidence of the issue for our records.`
  },
  {
    title:`Returns:`,
    content:` - Return Criteria: Due to the custom nature of our products, we do not accept returns for reasons other than defects or incorrect items received.
  - Defective or Incorrect Item Return Process: If you believe your item is defective or you received the wrong item, please contact our customer service team within 72 hours of receiving your order. Do not send the item back without contacting us first. We may request the item to be returned for inspection before issuing a replacement or refund.`
  },
  {
    title:`Additional Information:`,
    content:` - Order Verification: All orders undergo thorough examination by at least two members of our production staff to ensure accuracy in size and quantity (where applicable) and to detect any defects before shipping.
  - Responsibility After Delivery: StitchZone is not responsible for issues with orders that have been opened or left unattended after delivery.
  - Group Screen Printing Orders: Reprinting of missing or defective items in group screen printing orders is subject to criteria for a re-order. Please contact us promptly within 72 hours of receiving your order if there is an issue.
  - Time Limit for Returns: Items must be reported as defective or incorrect within 72 hours of delivery. Unauthorized returns may be refused and returned to the customer.
  - Return Deadline: Items must be returned within two weeks of receipt for any reason.
` },
{
  title:``,
  content:`If you have any questions or concerns about our refunds and returns policy, please contact our customer service team at info@stitchzone.com or (123) 456-7890. We are here to assist you.`
}
]
export const contactUs = [
  {
    title:``,
    content:`Thank you for visiting StitchZone! Whether you have a question about our products, need assistance with an order, or have feedback to share, we're here to help. Please feel free to reach out to us using any of the following methods:`
  },
  {
    title:`Customer Service Hours:`,
    content:`Monday - Friday: 10:00 AM - 1:00 PM (PST)`
  },
  {
    title:`Phone:`,
    content:`For immediate assistance, call us at: (123) 456-7890`
  },
  {
    title:`Email:`,
    content:`Send us an email at: info@stitchzone.com `
  },
  {
    title:`Storefront Address (By Appointment Only)::`,
    content:`4412 Technology Drive `
  },
  {
    title:``,
    content:`Fremont, CA 94538 `
  },
  {
    title:`Feedback:`,
    content:`We value your feedback! Let us know how we're doing or suggest improvements by emailing us at info@stitchzone.com .`
  },
  {
    title:``,
    content:`Please note that our storefront address is by appointment only. To schedule a visit, please contact us in advance. We strive to respond to all inquiries during regular business hours. Your satisfaction is our priority, and we look forward to assisting you.`
  },
  {
    title:``,
    content:`
Thank you for choosing StitchZone for all your embroidery needs!`
  },
  ]

  export const shippingRates = [
    { ordervalue: "Below $20.00", standard: "$12.50", priority: "$22.50", express: "$30.50" },
    { ordervalue: "From $20.00 to $39.99", standard: "$15.50", priority: "$25.50", express: "$40.50" },
    { ordervalue: "From $40.00 to $69.99", standard: "$18.50", priority: "$28.50", express: "$50.50" },
    { ordervalue: "From $70.00 to $99.99", standard: "$21.50", priority: "$31.50", express: "$60.50" },
    { ordervalue: "From $100.00 to $149.99", standard: "$24.50", priority: "$34.50", express: "$70.50" },
    { ordervalue: "From $150.00 to $199.99", standard: "$27.50", priority: "$37.50", express: "$80.50" },
    { ordervalue: "From $200.00 to $249.99", standard: "$30.50", priority: "$40.50", express: "$90.50" },
    { ordervalue: "From $250.00 to $299.99", standard: "$33.50", priority: "$43.50", express: "$100.50" },
    { ordervalue: "From $300.00 to $349.99", standard: "$36.50", priority: "$46.50", express: "$110.50" },
    { ordervalue: "From $350.00 to $399.99", standard: "$39.50", priority: "$49.50", express: "$120.50" },
    { ordervalue: "From $400.00 to $449.99", standard: "$42.50", priority: "$52.50", express: "$130.50" },
    { ordervalue: "From $450.00 to $499.99", standard: "$45.50", priority: "$55.50", express: "$140.00" },
    { ordervalue: "From $500.00 to $599.99", standard: "$48.50", priority: "$58.50", express: "$150.00" },
    { ordervalue: "From $600.00 to $699.99", standard: "$51.50", priority: "$61.50", express: "$160.00" },
    { ordervalue: "From $700.00 to $799.99", standard: "$54.50", priority: "$64.50", express: "$170.00" },
    { ordervalue: "From $800.00 to $899.99", standard: "$57.50", priority: "$67.50", express: "$180.00" },
    { ordervalue: "From $900.00 and $999.99", standard: "$60.50", priority: "$70.50", express: "$190.00" },
    { ordervalue: "From $1000.00 and $1999.00", standard: "$70.50", priority: "$90.50", express: "$200.00" },
    { ordervalue: "From $2000.00 and $2999.00", standard: "$90.50", priority: "$120.50", express: "$300.00" },
    { ordervalue: "From $3000.00 and $3999.00", standard: "$100.50", priority: "$150.50", express: "$500.00" },
    { ordervalue: "From $4000.00 and $4999.00", standard: "$150.50", priority: "$200.50", express: "$700.00" },
    { ordervalue: "From $5000.00 and $5999.00", standard: "$200.50", priority: "$300.50", express: "$1000.00" }
  ];