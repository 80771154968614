// export const fontFamiliesToLoad = [
//   "Open Sans",
//   "Montserrat",
//   "Source Sans Pro",
//   "Boogaloo",
//   "Source Code Pro",
// ];

export const fontFamiliesToLoad = [
  "Open Sans",
  "Agatha",
  "Algerian",
  "Anaconda",
  "Architech",
  "Arial Rounded",
];

export const fontList = fontFamiliesToLoad.map((name) => ({
  label: name,
  value: name,
}));

export const letterPlacementList = [
  { label: "Center of chest", value: "0.25" },
  { label: "Middle", value: "0.50" },
  { label: "Bottom", value: "0.75" },
];

export const foreGroundColours = [
  { label: "Red", value: "#d92f2f" },
  { label: "Green", value: "#1eab1e" },
  { label: "Blue", value: "blue" },
  { label: "Black", value: "#000000" },
];
export const backGroundColours = [
  { label: "Red", value: "#d92f2f" },
  { label: "Green", value: "#1eab1e" },
  { label: "Blue", value: "blue" },
  { label: "Black", value: "#000000" },
];

export const defaultEnglishText = "";
export const defaulGreekText = "";

export const tshirtColors = [
  {
    name: "Sky Blue",
    value: "#27A6FF",
  },
  {
    name: "Orange",
    value: "#E97931",
  },
  {
    name: "Yellow",
    value: "#FFCD27",
  },
];

export const authCookieName = "authDetails";
export const userDetails = "userDetails";

export const Embroidary_Postions = {
  CenterY: "20%",
  TopY: "2%",
  BottomY: "98%",
  RightY: "50%",
  LeftY: "50%",
  CenterDesignY: "55%",
  CenterDesignX: "45%",
  HoodY: "7%"
};

export const Embroidary_Locations = {
  Over_Kappa: "Over_Kappa",
  UnderNumber: "UnderNumber",
};

export const Embroidary = {
  design: "design",
  embroidaryLocation: "embroidaryLocation",
  aboveText: "Above Text",
  aboveGreekLetter: "Above Greek Letter",
  overGreekLetter: "Over Greek Letter",
  overText: "Over Text",
  underGreekLetter: "Under Greek Letter",
  underNumber: "Under Number",
  underText: "Under Text",
  fontStyles: "fontStyles",
  leftChest: "Left Chest",
  leftChestAlignToFirstGreekLetter: "Left Chest Align To First Greek Letter",
  rightChest: "Right Chest",
  rightChestAlignToFirstGreekLetter: "Right Chest Align To First Greek Letter",
  centreChest: "Center Chest",
  topOfBack: "Top of Back",
  centreOfBack: "Center of Back",
  bottomOfBack: "Bottom of Back"
};

export const Embroidary_Text_Style = {
  normal: "",
  flipped: "__flip__"
}

export const GreekLetters = ["Α", "Β", "Γ", "Δ", "Ε", "Ζ", "Η", "Θ", "Ι", "Κ", "Λ", "Μ", "Ν", "Ξ", "Ο", "Π", "Ρ", "Σ", "Τ", "Υ", "Φ", "Χ", "Ψ", "Ω"];
