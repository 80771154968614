import React, { useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { RadioButton, Textarea } from "@happeouikit/form-elements";
import { Input, Dropdown, Checkbox } from "@happeouikit/form-elements";
import { TextZeta, BodyUI } from "@happeouikit/typography";
import { fontList, marginL, marginM } from "../../../constants";
import {
  DesignerViewStates,
  ForegroundBackgroundSettings,
  IAppState,
  IAppliqueData,
  ICustomizationOptions,
  IFontStyle,
  ILetterPlacement,
  IMaterialFillStrokeBackGround,
  IMaterialFillStrokeForeGround,
  IThreadToMatch,
  LetterSizes,
  PreviewSettingNames,
  StichTypes,
  UpdatePreviewParams,
  NUMBERS,
} from "../../../models";
import {
  GreekLetterSizeId,
  IOneProductV2,
  LetterplacementID,
  StitchingTypeId,
} from "../../../models/ApiResponses/IGetProductResponseV2";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { addPriceToCart, removePriceToCart } from "../../../actions";
import { NumbersList } from "../../../models/interfaces/Numbers";
import { useFilePicker } from "use-file-picker";
import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
  ImageDimensionsValidator,
} from "use-file-picker/validators";
import { useImperativeFilePicker } from "use-file-picker";
import { toast } from "@happeouikit/toast";
import { Modal } from "@happeouikit/modals";

const Font_Color_Select = {
  label: "Select Color",
  value: "",
};

const Foreground_Color_Select = {
  label: "Select Foreground Color",
  value: "",
};

const Foreground_Thread_Select = {
  label: "Thread to match",
  value: "",
};

const Background_Color_Select = {
  label: "Select Background Color",
  value: "",
};

const Background_Thread_Select = {
  label: "Thread to match",
  value: "",
};

interface EnglishTextSettingsProps {
  updatePreview(newSetting: UpdatePreviewParams, key: string): void;
  updateAppliqueView(applique: any): void;
  availableForegroundOptions: ICustomizationOptions;
  availableBackgroundOptions: ICustomizationOptions;
  fontStyleOptions: Array<IFontStyle>;
  availableThreadOptions: Array<IThreadToMatch>;
  appliqueData: IAppliqueData;
  selectedProduct: IOneProductV2;
  selectedSide: string;
  appliqueView: {
    [key: string]: {
      enteredText: string;
      fontFamilyName: string;
      letterSize: {
        _id: string;
        size: string;
      };
      stitchingType: {
        _id: string;
        stitchingType: string;
        price: number;
        currency: string;
      };
      letterPlacement: any;
      specialInstructions: string;
      isCenterApplique?: boolean;
      heatSealedLetter: {
        isactive: boolean;
        cost: string;
      };
      foreGroundSettings: IMaterialFillStrokeForeGround;
      backgroundSettings: IMaterialFillStrokeBackGround;
      selectedNumber?: NumbersList;
      textOrNumber?: string;
      customFiles: any;
    };
  };
  alphabetTypeContainer: any;
}
interface EnglishPropsFromRedux {
  letterplacementId: Array<LetterplacementID>;
  stitchingTypeId: StitchingTypeId[];
  greekLetterSizeId: GreekLetterSizeId[];
  addPriceToCart(data: any): void;
  removePriceToCart(data: any): void;
}

interface EnglishTextSettingsState {
  selectedForeMaterial: any;
  selectedBackMaterial: any;
  selectedForeColor: any;
  selectedBackColor: any;
  selectedForeThreadColor: any;
  selectedBackThreadColor: any;
  customFiles: any;
  confirmModalHTML: any;
  isConfirmModalOpen: Boolean;
}

export class EnglishTextSettingsComponent extends React.Component<
  EnglishTextSettingsProps & EnglishPropsFromRedux,
  EnglishTextSettingsState
> {
  private NumberOptions = Object.keys(NUMBERS).map((key) => ({
    label: key,
    value: (NUMBERS as any)[key as any],
  }));

  constructor(props: EnglishTextSettingsProps & EnglishPropsFromRedux) {
    super(props);

    const {
      availableForegroundOptions,
      availableBackgroundOptions,
      availableThreadOptions,
      letterplacementId,
      appliqueView,
      selectedSide,
    } = props;
    const defaultForeFGroundMaterial =
      availableForegroundOptions[Object.keys(availableForegroundOptions)[0]];

    const defaultBackGroundMaterial =
      availableBackgroundOptions[Object.keys(availableBackgroundOptions)[0]];

    this.state = {
      selectedForeMaterial: defaultForeFGroundMaterial,
      selectedBackMaterial: defaultBackGroundMaterial,
      selectedForeColor: {},
      selectedBackColor: {},
      selectedForeThreadColor: {},
      selectedBackThreadColor: {},
      customFiles: [],
      confirmModalHTML: "",
      isConfirmModalOpen: false,
    };

    this.setSpecialInstructions = this.setSpecialInstructions.bind(this);
    this.setTextChange = this.setTextChange.bind(this);
    this.setFontStyle = this.setFontStyle.bind(this);
    this.setLetterPlacementChange = this.setLetterPlacementChange.bind(this);
    this.setLetterSize = this.setLetterSize.bind(this);
    this.setForeGroundFillStorkeAndMarker =
      this.setForeGroundFillStorkeAndMarker.bind(this);
    this.setBackGroundFillStorkeAndMarker =
      this.setBackGroundFillStorkeAndMarker.bind(this);
    this.setCustomFileChange = this.setCustomFileChange.bind(this);

    //this.updateInitialPreview();
    
  }

  componentDidMount() {
    this.updateInitialPreview();
    /* console.log("componentDidMount"); */
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.selectedSide !== this.props.selectedSide) {
      /* console.log("componentDidUpdate"); */

      const {
        availableForegroundOptions,
        availableBackgroundOptions
      } = this.props;
      const defaultForeFGroundMaterial =
        availableForegroundOptions[Object.keys(availableForegroundOptions)[0]];
  
      const defaultBackGroundMaterial =
        availableBackgroundOptions[Object.keys(availableBackgroundOptions)[0]];
      this.setState({
        selectedForeMaterial: defaultForeFGroundMaterial,
        selectedBackMaterial: defaultBackGroundMaterial,
        selectedForeColor: {},
        selectedBackColor: {},
        selectedForeThreadColor: {},
        selectedBackThreadColor: {},
        customFiles: [],
        confirmModalHTML: "",
        isConfirmModalOpen: false
      });

      this.updateInitialPreview();
    }
  }

  updateInitialPreview() {
    const {
      letterPlacement,
      fontFamilyName,
      letterSize,
      foreGroundSettings,
      backgroundSettings,
      enteredText,
    } = this.props.appliqueView[this.props.selectedSide];

    /*console.log(
      this.props.appliqueView[this.props.selectedSide],
      "this.props.appliqueView[this.props.selectedSide];"
    );*/

    const { appliqueData, fontStyleOptions, letterplacementId, selectedSide } = this.props;

    //console.log(letterplacementId);

    if (appliqueData.name == "Script with Tail") {
      this.setFontStyle({
        label: fontStyleOptions[5].name /* Athletic Script */,
        value: fontStyleOptions[5]._id,
      });
    }
    //console.log("letterPlacement +++++++++++++++++++ ", letterPlacement);
    if (appliqueData.name == "Monogram") {
      if (this.props.selectedSide == "front") {
        this.setLetterPlacementChange({
          label: "Left of Chest",
          value: "",
          x_axis: 15,
          y_axis: 35,
        });
      } else if (this.props.selectedSide == "back") {
        this.setLetterPlacementChange({
          label: "Center of Back",
          value: "",
          x_axis: 50,
          y_axis: 50,
        });
      }
    } else if (appliqueData.name == "Simple Text") {
      if (this.props.selectedSide == "front") {
        this.setLetterPlacementChange({
          label: "Left of Chest",
          value: "",
          x_axis: 15,
          y_axis: 35,
        });
      } else if (this.props.selectedSide == "back") {
        this.setLetterPlacementChange({
          label: "Center of Back",
          value: "",
          x_axis: 50,
          y_axis: 50,
        });
      }
    } else {
      if(letterPlacement){
        const { value: letterPlacementValue } = letterPlacement;
        if(appliqueData.name === "Simple Text") {
          this.props.updatePreview(
            {
              settingName: PreviewSettingNames.letterPlacement,
              settingVal: {
                value: {
                  x_axis: selectedSide == "front"?letterPlacementValue.x_axis:"30",
                  y_axis: selectedSide == "front"?"25":"40",
                },
              },
            },
            DesignerViewStates.Applique
          );
        } else if(appliqueData.name === "Monogram") {
          this.props.updatePreview(
            {
              settingName: PreviewSettingNames.letterPlacement,
              settingVal: {
                value: {
                  x_axis: selectedSide == "front"?letterPlacementValue.x_axis:"30",
                  y_axis: selectedSide == "front"?"25":"40",
                },
              },
            },
            DesignerViewStates.Applique
          );
        } else {
          this.props.updatePreview(
            {
              settingName: PreviewSettingNames.letterPlacement,
              settingVal: {
                value: letterPlacementValue,
              },
            },
            DesignerViewStates.Applique
          );
        }
      }
    }

    //console.log(letterSize, "letterSizeletterSize");
    if (appliqueData.name === "Monogram") {
      if(selectedSide === "front"){
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.letterSize,
            settingVal: {
              value: '1' //letterSize.size.split('"')[0],
            },
          },
          DesignerViewStates.Applique
        );
      } else if(selectedSide === "back"){
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.letterSize,
            settingVal: {
              value: '7' //letterSize.size.split('"')[0],
            },
          },
          DesignerViewStates.Applique
        );
      }
    } else if (appliqueData.name === "Simple Text") {
      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.letterSize,
          settingVal: {
            value: '1' //letterSize.size.split('"')[0],
          },
        },
        DesignerViewStates.Applique
      );
    } else if (appliqueData.name === "Script with Tail") {
      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.letterSize,
          settingVal: {
            value: '2' //letterSize.size.split('"')[0],
          },
        },
        DesignerViewStates.Applique
      );
    } else {
      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.letterSize,
          settingVal: {
            value: letterSize.size.split('"')[0],
          },
        },
        DesignerViewStates.Applique
      );
    }
    

    if (appliqueData.name != "Script with Tail") {
      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.fontFamily,
          settingVal: {
            value: fontFamilyName,
          },
        },
        DesignerViewStates.Applique
      );
    }

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.foreGroundFillStrokeMarker,
        settingVal: {
          value: foreGroundSettings,
        },
      },
      DesignerViewStates.Applique
    );

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.backGroundFillStrokeMarker,
        settingVal: {
          value: backgroundSettings,
        },
      },
      DesignerViewStates.Applique
    );

    if (enteredText.trim().length > 0) {
      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.contentText,
          settingVal: {
            value: enteredText,
          },
        },
        DesignerViewStates.Applique
      );
    } else {
      if (this.props.selectedSide === "back") {
        const newNumbersList: NumbersList = [
          ...this.props.appliqueView[this.props.selectedSide].selectedNumber!,
        ];
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.contentText,
            settingVal: {
              value:
                newNumbersList.join("").length === 0
                  ? enteredText
                  : newNumbersList.join(""),
            },
          },
          DesignerViewStates.Applique
        );
      }
    }
  }

  setCustomFileChange(fileArr: any) {
    this.setState(
      {
        customFiles: fileArr,
      },
      () => {
        //console.log("this.state.customFiles : ", this.state.customFiles);

        const { selectedSide, appliqueView } = this.props;

        this.props.updateAppliqueView({
          ...appliqueView,
          [selectedSide]: {
            ...appliqueView[selectedSide],
            customFiles: this.state.customFiles,
          },
        });

        if (this.props.appliqueData.name.toLowerCase() === "imprint") {
          let customFileBase64String =
            this.state.customFiles.length > 0
              ? this.state.customFiles[0].content
              : ""; // create base64 Image
          /*console.log(
            "customFileBase64String ++++++++++++++++++++++++++++ ",
            customFileBase64String
          );*/
          this.props.updatePreview(
            {
              settingName: PreviewSettingNames.customFilePreview,
              settingVal: {
                value: customFileBase64String,
              },
            },
            DesignerViewStates.Applique
          );

          if (
            appliqueView[selectedSide].enteredText.length > 0 &&
            this.state.customFiles.length > 0
          ) {
            this.confirmModalOpen(
              <div>
                As you choose image over text. So text will get removed.
              </div>
            );
            this.props.updateAppliqueView({
              ...appliqueView,
              [selectedSide]: {
                ...appliqueView[selectedSide],
                enteredText: "",
              },
            });

            this.props.updatePreview(
              {
                settingName: PreviewSettingNames.contentText,
                settingVal: {
                  value: "",
                },
              },
              DesignerViewStates.Applique
            );
          }
        }
      }
    );
  }

  setTextChange(e: ChangeEvent<HTMLInputElement>) {
    const { selectedSide, appliqueData, appliqueView } = this.props;
    let text = e.target.value;
    /*console.log(
      "CUSTOM FILES : ",
      appliqueView[selectedSide].customFiles.length
    );*/
    if (
      appliqueData.name.toLowerCase() === "imprint" &&
      this.state.customFiles.length > 0
    ) {
      /*console.log(
        "-----------------------------------------------------------------------------------------------"
      );*/
      this.confirmModalOpen(
        <div>As you choose text over image. So image will get removed.</div>
      );
      this.setState(
        {
          customFiles: [],
        },
        () => {
          this.props.updateAppliqueView({
            ...appliqueView,
            [selectedSide]: {
              ...appliqueView[selectedSide],
              customFiles: this.state.customFiles,
            },
          });
          this.props.updatePreview(
            {
              settingName: PreviewSettingNames.customFilePreview,
              settingVal: {
                value: "",
              },
            },
            DesignerViewStates.Applique
          );

          if (appliqueData.name == "Monogram" && e.target.value.length > 4) {
            toast.error({
              message: `Maximum 4 letters are allowed.`,
              delay: 2000,
            });
            text = text.slice(0, 4);
          } else if (appliqueData.name == "Simple Text" && e.target.value.length > 4) {
            toast.error({
              message: `Maximum 4 letters are allowed.`,
              delay: 2000,
            });
            text = text.slice(0, 4);
          } else if (
            appliqueData.name == "Script with Tail" &&
            e.target.value.length > 10
          ) {
            toast.error({
              message: `Maximum 12 letters are allowed.`,
              delay: 2000,
            });
            text = text.slice(0, 12);
          }

          this.props.updateAppliqueView({
            ...this.props.appliqueView,
            [selectedSide]: {
              ...this.props.appliqueView[selectedSide],
              enteredText: text,
            },
          });

          this.props.updatePreview(
            {
              settingName: PreviewSettingNames.contentText,
              settingVal: {
                value: text,
              },
            },
            DesignerViewStates.Applique
          );
        }
      );
      //console.log(appliqueView[selectedSide].customFiles);
    } else {
      if (appliqueData.name == "Monogram" && e.target.value.length > 4) {
        toast.error({
          message: `Maximum 4 letters are allowed.`,
          delay: 2000,
        });
        text = text.slice(0, 4);
      } else if (appliqueData.name == "Simple Text" && e.target.value.length > 4) {
        toast.error({
          message: `Maximum 4 letters are allowed.`,
          delay: 2000,
        });
        text = text.slice(0, 4);
      } else if (
        appliqueData.name == "Script with Tail" &&
        e.target.value.length > 12
      ) {
        toast.error({
          message: `Maximum 12 letters are allowed.`,
          delay: 2000,
        });
        text = text.slice(0, 12);
      }
      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          enteredText: text,
        },
      });

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.contentText,
          settingVal: {
            value: text,
          },
        },
        DesignerViewStates.Applique
      );
    }
  }

  private setNumberChange(
    value: { label: string; value: NUMBERS },
    index: number
  ) {
    const { selectedSide } = this.props;
    /* const newNumbersList: NumbersList = [
      ...this.props.appliqueView[selectedSide].selectedNumber!,
    ]; */

    let newNumbersList = [
      ...this.props.appliqueView[selectedSide].selectedNumber!,
    ];

    if (value.value == " " && newNumbersList[2] != " ") {
      /* console.log("setNumberChange-removePriceToCart"); */
      this.props.removePriceToCart({
        price: 2,
      });
    }

    /* check if all previous options are already selected */
    let isAllPreviousOptionsNotSelected = this.props.appliqueView[
      selectedSide
    ].selectedNumber!.filter(
      (value, idx) => idx < index && newNumbersList[idx] == " "
    );

    if (isAllPreviousOptionsNotSelected.length == 0) {
      /* if the 3rd number selection then add $2 into total cart price calculation */
      if (index == 2 && value.value != " " && newNumbersList[2] == " ") {
        /* console.log("setNumberChange-addPriceToCart"); */
        this.props.addPriceToCart({
          price: 2,
        });
      }

      if (value.value == " ") {
        newNumbersList = newNumbersList.map((v: any, i: number) =>
          i < index ? v : " "
        );
      } else {
        newNumbersList[index] = value.value;
      }
    } else {
      toast.error({
        message: `Please fill all the previous options first. `,
        delay: 2000,
      });
    }

    this.props.updateAppliqueView({
      ...this.props.appliqueView,
      [selectedSide]: {
        ...this.props.appliqueView[selectedSide],
        selectedNumber: newNumbersList,
      },
    });

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.contentText,
        settingVal: {
          value: newNumbersList.join(""),
        },
      },
      DesignerViewStates.Applique
    );
  }

  setSpecialInstructions(e: ChangeEvent<HTMLInputElement>) {
    const { selectedSide } = this.props;
    this.props.updateAppliqueView({
      ...this.props.appliqueView,
      [selectedSide]: {
        ...this.props.appliqueView[selectedSide],
        specialInstructions: e.target.value,
      },
    });
  }

  setFontStyle(selectedOption: { label: string; value: string }) {
    const fontFamilyName = selectedOption.value;
    const { selectedSide } = this.props;
    /*console.log("this.props.fontStyleOptions : ", this.props.fontStyleOptions);
    console.log(
      "this.props.appliqueView[selectedSide].fontFamilyName : ",
      this.props.appliqueView[selectedSide].fontFamilyName
    );*/
    const selectedFont = this.props.fontStyleOptions.find((i: any) => {
      return (
        i._id ===
        (this.props.appliqueView[selectedSide].fontFamilyName as any).value
      );
    });

    //console.log("selectedFont : ", selectedFont);

    if (selectedFont) {
      /* console.log("setFontStyle - removePriceToCart"); */
      this.props.removePriceToCart({
        price: (selectedFont as any)["price"],
      });
    }

    const selectedFontFromAPI = this.props.fontStyleOptions.find((i: any) => {
      return i._id === selectedOption.value;
    });

    /*console.log("selectedOption : ", selectedOption);
    console.log("selectedFontFromAPI : ", selectedFontFromAPI);*/
    /* console.log("setFontStyle - addPriceToCart"); */
    this.props.addPriceToCart({
      price: (selectedFontFromAPI as any)["price"],
    });

    this.props.updateAppliqueView({
      ...this.props.appliqueView,
      [selectedSide]: {
        ...this.props.appliqueView[selectedSide],
        fontFamilyName: selectedOption,
      },
    });

    /*let lastEnteredText = this.props.appliqueView[selectedSide].enteredText;
    this.props.updateAppliqueView({
      ...this.props.appliqueView,
      [selectedSide]: {
        ...this.props.appliqueView[selectedSide],
        enteredText: "",
      },
    });
    setTimeout(() => {
      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          enteredText: lastEnteredText,
        },
      });
    }, 100);*/

    /*console.log(
      "this.props.appliqueView[selectedSide].fontFamilyName : ",
      this.props.appliqueView[selectedSide].fontFamilyName
    );*/

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.fontFamily,
        settingVal: {
          value: selectedOption,
        },
      },
      DesignerViewStates.Applique
    );
  }

  setLetterPlacementChange(selectedOption: {
    label: string;
    value: string;
    x_axis: number;
    y_axis: number;
  }) {
    const { x_axis, y_axis } = selectedOption;
    const { selectedSide, appliqueData, appliqueView } = this.props;

    if(appliqueData.name === "Simple Text") {
      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          letterPlacement: {
            label: selectedOption.label,
            value: {
              x_axis: selectedSide == "front"?x_axis:"30",
              y_axis: selectedSide == "front"?y_axis:"40",
            },
          },
        },
      });

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.letterPlacement,
          settingVal: {
            value: {
              x_axis: selectedSide == "front"?x_axis:"30",
              y_axis: selectedSide == "front"?y_axis:"40",
            },
          },
        },
        DesignerViewStates.Applique
      );
    } else if(appliqueData.name === "Monogram") {
      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          letterPlacement: {
            label: selectedOption.label,
            value: {
              x_axis: selectedSide == "front"?x_axis:"30",
              y_axis: selectedSide == "front"?y_axis:"40",
            },
          },
        },
      });

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.letterPlacement,
          settingVal: {
            value: {
              x_axis: selectedSide == "front"?x_axis:"30",
              y_axis: selectedSide == "front"?y_axis:"40",
            },
          },
        },
        DesignerViewStates.Applique
      );
    } else {
      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          letterPlacement: {
            label: selectedOption.label,
            value: {
              x_axis,
              y_axis,
            },
          },
        },
      });
      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.letterPlacement,
          settingVal: {
            value: { x_axis, y_axis },
          },
        },
        DesignerViewStates.Applique
      );
    }
  }

  setLetterSize(letterSize: { _id: string; size: string }) {
    const { selectedSide } = this.props;
    this.props.updateAppliqueView({
      ...this.props.appliqueView,
      [selectedSide]: {
        ...this.props.appliqueView[selectedSide],
        letterSize,
      },
    });

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.letterSize,
        settingVal: {
          value: letterSize.size.split('"')[0],
        },
      },
      DesignerViewStates.Applique
    );
  }

  setForeGroundFillStorkeAndMarker(
    value: string,
    type: ForegroundBackgroundSettings
  ) {
    const { availableForegroundOptions, selectedSide, availableThreadOptions, appliqueData } =
      this.props;
    const {
      foreGroundSettings: { material, stroke },
    } = this.props.appliqueView[selectedSide];
    //debugger;

    if (type === ForegroundBackgroundSettings.material) {
      //console.log(`${type} === ${ForegroundBackgroundSettings.material}`);
      if (Object.keys(this.state.selectedForeMaterial).length > 0) {
        /* console.log(
          "this.state.selectedForeMaterial : ",
          this.state.selectedForeMaterial
        ); */
        /* console.log(
          "setForeGroundFillStorkeAndMarker - material - removePriceToCart"
        ); */
        this.props.removePriceToCart({
          price: (this.state.selectedForeMaterial as any)["price"],
        });
      }
      
      let selectedMaterial = Object.values(availableForegroundOptions).find(
        ({ value: _value }) => {
          return _value == value;
        }
      ) || { availableFills: [] };
      //console.log("selectedMaterial : ", selectedMaterial);
      const newValues = {
        material: value,
        // fill: !value ? "" : selectedMaterial?.availableFills[0].value,
        fill: "",
        stroke,
      };

      this.setState({
        selectedForeMaterial: selectedMaterial,
      });
      /* console.log("selectedMaterial ++++++++++ ", selectedMaterial); */
      if (selectedMaterial) {
        /* console.log(
          "setForeGroundFillStorkeAndMarker - material - addPriceToCart"
        ); */
        this.props.addPriceToCart({
          price: (selectedMaterial as any)["price"],
        });
      }

      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          foreGroundSettings: newValues,
        },
      });

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.foreGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    } else if (type === ForegroundBackgroundSettings.fill) {
      //console.log(`${type} === ${ForegroundBackgroundSettings.fill}`);
      /*console.log(
        "this.state.selectedForeColor : ",
        this.state.selectedForeColor
      );*/
      if (this.state.selectedForeColor) {
        if (Object.keys(this.state.selectedForeColor).length > 0) {
          /* console.log(
            "setForeGroundFillStorkeAndMarker - fill - removePriceToCart"
          ); */
          this.props.removePriceToCart({
            price: (this.state.selectedForeColor as any)["price"],
          });
        }
      }

      let selectedFill:any = "";
      selectedFill = availableForegroundOptions[
        this.state.selectedForeMaterial?.label as string
      ].availableFills.find((filll) => value === filll.value);

      this.setState({
        selectedForeColor: selectedFill,
      }); 

      if (selectedFill) {
        /* console.log("setForeGroundFillStorkeAndMarker - fill - addPriceToCart"); */
        this.props.addPriceToCart({
          price: (selectedFill as any)["price"],
        });
      }

      const newValues = {
        ...this.props.appliqueView[selectedSide].foreGroundSettings,
        [type]: selectedFill?.value as string,
      };
      
      //debugger;
      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          foreGroundSettings: newValues,
        },
      });
      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.foreGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    } else {
      /* Thgread color / Stroke */
      if (this.state.selectedForeThreadColor) {
        if (Object.keys(this.state.selectedForeThreadColor).length > 0) {
          /* console.log(
            "setForeGroundFillStorkeAndMarker - Stroke - removePriceToCart"
          ); */
          this.props.removePriceToCart({
            price: (this.state.selectedForeThreadColor as any)["price"],
          });
        }
      }

      //console.log("availableThreadOptions : ", availableThreadOptions);

      let selectedThread = Object.values(availableThreadOptions).find(
        ({ value: _value }) => {
          return _value == value;
        }
      );

      this.setState({
        selectedForeThreadColor: selectedThread,
      });

      if (selectedThread) {
        /* console.log(
          "setForeGroundFillStorkeAndMarker - Stroke - addPriceToCart"
        ); */
        this.props.addPriceToCart({
          price: (selectedThread as any)["price"],
        });
      }

      let newValues = {};
      if(appliqueData.name !== "Simple Text" && appliqueData.name !== "Monogram"){
        newValues = {
          ...this.props.appliqueView[selectedSide].foreGroundSettings,
          [type]: value, // stroke value
        };
      } else {
        if(appliqueData.name === "Monogram" && selectedSide === "back"){
          newValues = {
            ...this.props.appliqueView[selectedSide].foreGroundSettings,
            [type]: value, // stroke value
          };
        } else {
          newValues = {
            material: "",
            // fill: !value ? "" : selectedMaterial?.availableFills[0].value,
            fill: value,
            stroke: value,
          }
        }
      }

      const st = this.props.stitchingTypeId.find((i: any) => {
        return i.stitchingType === "Double/Satin";
      });

      if(value != ""){
        this.props.updateAppliqueView({
          ...this.props.appliqueView,
          [selectedSide]: {
            ...this.props.appliqueView[selectedSide],
            foreGroundSettings: newValues,
            stitchingType: {
              _id: st?._id,
              stitchingType: st?.stitchingType,
              price: st?.price,
              currency: st?.currency,
            }
          },
        });
      } else {
        this.props.updateAppliqueView({
          ...this.props.appliqueView,
          [selectedSide]: {
            ...this.props.appliqueView[selectedSide],
            foreGroundSettings: newValues,
          },
        });
      }

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.foreGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    }
  }

  setBackGroundFillStorkeAndMarker(
    value: string,
    type: ForegroundBackgroundSettings
  ) {
    const { availableBackgroundOptions, selectedSide, availableThreadOptions } =
      this.props;
    const {
      backgroundSettings: { material, stroke },
    } = this.props.appliqueView[selectedSide];

    if (type === ForegroundBackgroundSettings.material) {
      if (Object.keys(this.state.selectedBackMaterial).length > 0) {
        /* console.log(
          "setBackGroundFillStorkeAndMarker - material - removePriceToCart"
        ); */
        this.props.removePriceToCart({
          price: (this.state.selectedBackMaterial as any)["price"],
        });
      }

      if (this.state.selectedBackColor) {
        if (Object.keys(this.state.selectedBackColor).length > 0) {
          /* console.log(
            "setBackGroundFillStorkeAndMarker - fill - removePriceToCart"
          ); */
          this.props.removePriceToCart({
            price: (this.state.selectedBackColor as any)["price"],
          });
        }
      }

      let selectedMaterial = Object.values(availableBackgroundOptions).find(
        ({ value: _value }) => _value == value
      ) || { availableFills: [] };

      const newValues = {
        material: value,
        // fill: !value ? "" : selectedMaterial?.availableFills[0].value,
        fill: "",
        stroke,
      };

      this.setState({
        selectedBackMaterial: selectedMaterial,
        selectedBackColor: {},
      });

      /* console.log(
        "setBackGroundFillStorkeAndMarker - material - addPriceToCart"
      ); */
      this.props.addPriceToCart({
        price: (selectedMaterial as any)["price"],
      });

      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          backgroundSettings: newValues,
        },
      });
      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.backGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    } else if (type === ForegroundBackgroundSettings.fill) {
      if (this.state.selectedBackColor) {
        if (Object.keys(this.state.selectedBackColor).length > 0) {
          /* console.log(
            "setBackGroundFillStorkeAndMarker - fill - removePriceToCart"
          ); */
          this.props.removePriceToCart({
            price: (this.state.selectedBackColor as any)["price"],
          });
        }
      }
      const selectedFill = availableBackgroundOptions[
        this.state.selectedBackMaterial?.label as string
      ].availableFills.find((filll) => value === filll.value);

      this.setState({
        selectedBackColor: selectedFill,
      });

      if (selectedFill) {
        /* console.log("setBackGroundFillStorkeAndMarker - fill - addPriceToCart"); */
        this.props.addPriceToCart({
          price: (selectedFill as any)["price"],
        });
      }

      const newValues = {
        ...this.props.appliqueView[selectedSide].backgroundSettings,
        [type]: selectedFill?.value as string,
      };

      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          backgroundSettings: newValues,
        },
      });
      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.backGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    } else {
      /* Thgread color / Stroke */
      if (this.state.selectedBackThreadColor) {
        if (Object.keys(this.state.selectedBackThreadColor).length > 0) {
          /* console.log(
            "setBackGroundFillStorkeAndMarker - Stroke - removePriceToCart"
          ); */
          this.props.removePriceToCart({
            price: (this.state.selectedBackThreadColor as any)["price"],
          });
        }
      }

      /* console.log("availableThreadOptions : ", availableThreadOptions); */

      let selectedThread = Object.values(availableThreadOptions).find(
        ({ value: _value }) => {
          return _value == value;
        }
      );

      this.setState({
        selectedBackThreadColor: selectedThread,
      });

      if (selectedThread) {
        /* console.log(
          "setBackGroundFillStorkeAndMarker - Stroke - addPriceToCart"
        ); */
        this.props.addPriceToCart({
          price: (selectedThread as any)["price"],
        });
      }

      const newValues = {
        ...this.props.appliqueView[selectedSide].backgroundSettings,
        [type]: value, // stroke value
      };

      const st = this.props.stitchingTypeId.find((i: any) => {
        return i.stitchingType === "Double/Satin";
      });

      if(value != ""){
        this.props.updateAppliqueView({
          ...this.props.appliqueView,
          [selectedSide]: {
            ...this.props.appliqueView[selectedSide],
            backgroundSettings: newValues,
            stitchingType: {
              _id: st?._id,
              stitchingType: st?.stitchingType,
              price: st?.price,
              currency: st?.currency,
            }
          },
        });
      } else {
        this.props.updateAppliqueView({
          ...this.props.appliqueView,
          [selectedSide]: {
            ...this.props.appliqueView[selectedSide],
            backgroundSettings: newValues,
          },
        });
      }
      

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.backGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    }
  }

  confirmModalClose = () => {
    this.setState({ isConfirmModalOpen: false, confirmModalHTML: "" });
  };
  confirmModalOpen = (html: any) => {
    this.setState({ isConfirmModalOpen: true, confirmModalHTML: html });
  };

  privatetextSizes = Object.keys(LetterSizes);

  render() {
    const {
      availableForegroundOptions,
      availableThreadOptions,
      availableBackgroundOptions,
      appliqueData,
      fontStyleOptions,
      letterplacementId,
      greekLetterSizeId,
      selectedSide,
    } = this.props;

    let letterplacementMonogramFront: Array<{
      _id: string;
      name: string;
      anchor: string;
      side: string;
      x_axis: number;
      y_axis: number;
    }> = [];
    let letterplacementMonogramBack: Array<{
      _id: string;
      name: string;
      anchor: string;
      side: string;
      x_axis: number;
      y_axis: number;
    }> = [];
    let letterplacementSimpleTextFront: Array<{
      _id: string;
      name: string;
      anchor: string;
      side: string;
      x_axis: number;
      y_axis: number;
    }> = [];
    let letterplacementSimpleTextBack: Array<{
      _id: string;
      name: string;
      anchor: string;
      side: string;
      x_axis: number;
      y_axis: number;
    }> = [];

    if (appliqueData.name === "Monogram") {
      letterplacementMonogramFront = [
        {
          _id: "63e6c1b2df476e713fe65d24",
          name: "Left of Chest",
          side: "front",
          anchor: "center",
          x_axis: 15,
          y_axis: 35,
        },
        /* {
          _id: "63e6c1b2df476e713fe65d25",
          name: "Center of Chest",
          side: "front",
          anchor: "center",
          x_axis: 50,
          y_axis: 25,
        }, */
        {
          _id: "63e6c1b2df476e713fe65d26",
          name: "Right of Chest",
          side: "front",
          anchor: "center",
          x_axis: 50,
          y_axis: 35,
        },
      ];

      letterplacementMonogramBack = [
        {
          _id: "63e6c1b2df476e713fe65d27",
          name: "Top of Back",
          side: "back",
          anchor: "center",
          x_axis: 50,
          y_axis: 5,
        },
        {
          _id: "63e6c1b2df476e713fe65d28",
          name: "Middle of Back",
          side: "back",
          anchor: "center",
          x_axis: 50,
          y_axis: 50,
        },
        {
          _id: "63e6c1b2df476e713fe65d29",
          name: "Bottom of Back",
          side: "back",
          anchor: "center",
          x_axis: 50,
          y_axis: 95,
        },
      ];
    } else if(appliqueData.name === "Simple Text"){
      letterplacementSimpleTextFront = [
        {
          _id: "63e6c1b2df476e713fe65d24",
          name: "Left of Chest",
          side: "front",
          anchor: "center",
          x_axis: 15,
          y_axis: 35,
        },
        /* {
          _id: "63e6c1b2df476e713fe65d25",
          name: "Center of Chest",
          side: "front",
          anchor: "center",
          x_axis: 50,
          y_axis: 25,
        }, */
        {
          _id: "63e6c1b2df476e713fe65d26",
          name: "Right of Chest",
          side: "front",
          anchor: "center",
          x_axis: 50,
          y_axis: 35,
        },
      ];

      letterplacementSimpleTextBack = [
        {
          _id: "63e6c1b2df476e713fe65d27",
          name: "Top of Back",
          side: "back",
          anchor: "center",
          x_axis: 50,
          y_axis: 5,
        },
        {
          _id: "63e6c1b2df476e713fe65d28",
          name: "Middle of Back",
          side: "back",
          anchor: "center",
          x_axis: 50,
          y_axis: 50,
        },
        {
          _id: "63e6c1b2df476e713fe65d29",
          name: "Bottom of Back",
          side: "back",
          anchor: "center",
          x_axis: 50,
          y_axis: 95,
        },
      ];
    }

    console.info("appliqueData : ", appliqueData);

    if (appliqueData.name == "Imprint") {
      appliqueData.heatSealedLetter = false;
    }

    const {
      enteredText,
      textOrNumber,
      isCenterApplique,
      heatSealedLetter,
      fontFamilyName,
      letterPlacement,
      letterSize,
      specialInstructions,
      foreGroundSettings: { material, stroke, fill },
      backgroundSettings: {
        material: bgMaterial,
        stroke: bgStroke,
        fill: bgFill,
      },
      selectedNumber,
      customFiles,
    } = this.props.appliqueView[selectedSide];
    //console.log("selectedSide", selectedSide, this.props.appliqueView);

    const foreGroundMaterialOptions = Object.keys(
      availableForegroundOptions
    ).map((key: any) => ({
      label: (availableForegroundOptions as any)[key].label,
      value: (availableForegroundOptions as any)[key].value,
    }));

    const selectedForegroundMaterial = foreGroundMaterialOptions.find(
      ({ value }) => value === material
    );

    const foreGroundFillOptions =
      availableForegroundOptions[selectedForegroundMaterial?.label]
        ?.availableFills || [];

    const selectedforeGroundFill =
      (foreGroundFillOptions?.length > 0 &&
        foreGroundFillOptions?.find((option: any) => option.value === fill)) ||
      Foreground_Color_Select;
    //console.log(selectedforeGroundFill, "selectedforeGroundFill");

    const selectedFontColorFill =
      (foreGroundFillOptions?.length > 0 &&
        foreGroundFillOptions?.find((option: any) => option.value === fill)) ||
      Font_Color_Select;

    const selectedStroke =
      availableThreadOptions.find(({ value }) => value === stroke) ||
      Foreground_Thread_Select;

    const backgroundMaterialOptions = Object.keys(
      availableBackgroundOptions
    ).filter((key: any) => (key === "Solid Color" || key === "Patterns")).map((key: any) => ({
        label: (availableBackgroundOptions as any)[key].label,
        value: (availableBackgroundOptions as any)[key].value,
    }));

    const selectedBackgroundMaterial = backgroundMaterialOptions.find(
      ({ value }) => value === bgMaterial
    );

    const backgroundFills =
      availableBackgroundOptions[selectedBackgroundMaterial?.label]
        ?.availableFills || [];

    const selectedBackgroundFills =
      (backgroundFills.length > 0 &&
        backgroundFills.find(
          (currentFill: any) => currentFill.value === bgFill
        )) ||
      Background_Color_Select;

    const selectedBgStroke =
      availableThreadOptions.find(({ value }) => value === bgStroke) ||
      Background_Thread_Select;

    const getTextField = () => {
      return (
        <FormGroup>
          <Input
            placeholder="Text"
            autoComplete="off"
            value={enteredText}
            onChange={this.setTextChange}
            label="Text"
          />
        </FormGroup>
      );
    };

    const getNumbers = () => {
      return selectedNumber?.map((number, index) => {
        const a = this.NumberOptions.find(
          (i) => i.value === selectedNumber[index]
        );
        return (
          <DropDownWrapper key={index}>
            <Dropdown
              options={this.NumberOptions}
              onChange={(value: { label: string; value: NUMBERS }) =>
                this.setNumberChange(value, index)
              }
              value={a}
              placeholder={`Select Number ${index + 1}`}
            />
          </DropDownWrapper>
        );
      });
    };

    const isRenderTextorNumber = () => {
      const { selectedSide } = this.props;
      if (appliqueData.name == "Monogram" && selectedSide === "back") {
        /* if (textOrNumber === "text") {
          return getTextField();
        } else { */
          return <div style={{display:"flex", justifyContent:"space-between"}}>{getNumbers()}</div>;
        //}
      } else {
        return getTextField();
      }
    };

    const isRenderLetterPlacement = () => {
      return (
        <LetterPlacementContainer>
          <Dropdown
            label="Letter Placement"
            options={letterplacementId
              ?.map(({ name, side, anchor, x_axis, y_axis }) => ({
                label: name,
                value: anchor,
                side,
                x_axis,
                y_axis,
              }))
              .filter((i) => i.side === selectedSide)}
            onChange={this.setLetterPlacementChange}
            value={letterPlacement}
          />
        </LetterPlacementContainer>
      );
    };

    const handleIsCenterApplique = () => {
      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          isCenterApplique:
            !this.props.appliqueView[selectedSide].isCenterApplique,
        },
      });
    };

    const handleIsHeatSealedLetters = () => {
      /* console.log(
        "this.props.appliqueView[selectedSide].heatSealedLetter : ",
        this.props.appliqueView[selectedSide].heatSealedLetter
      ); */
      if (this.props.appliqueView[selectedSide].heatSealedLetter) {
        /* console.log("handleIsHeatSealedLetters - PriceToCart"); */
        this.props.appliqueView[selectedSide].heatSealedLetter.isactive
          ? this.props.removePriceToCart({
              price: parseFloat(
                (this.props.appliqueView[selectedSide].heatSealedLetter as any)[
                  "cost"
                ]
              ),
            })
          : this.props.addPriceToCart({
              price: parseFloat(
                (this.props.appliqueView[selectedSide].heatSealedLetter as any)[
                  "cost"
                ]
              ),
            });
      }
      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          heatSealedLetter: {
            ...this.props.appliqueView[selectedSide].heatSealedLetter,
            isactive:
              !this.props.appliqueView[selectedSide].heatSealedLetter.isactive,
          },
        },
      });
    };

    // if (selectedSide === "back" && isCenterApplique) {
    //   this.props.updatePreview(
    //     {
    //       settingName: PreviewSettingNames.letterPlacement,
    //       settingVal: {
    //         value: {
    //           x_axis: 50,
    //           y_axis: 50,
    //         },
    //       },
    //     },
    //     DesignerViewStates.Applique
    //   );
    // }

    //console.log("textOrNumber ============================== ", textOrNumber);

    return (
      <>
        {selectedSide === "back" &&
          appliqueData.name != "Simple Text" &&
          appliqueData.name != "Imprint" &&
          appliqueData.name != "Monogram" &&
          appliqueData.name != "Script with Tail" &&
          appliqueData.name != "Custom" && (
            <Checkbox
              label={"Center of Back Number"}
              value={isCenterApplique}
              checked={isCenterApplique}
              onChange={() => handleIsCenterApplique()}
            />
          )}

        {/*appliqueData.name == "Monogram" && selectedSide === "back" && (
          <RadioContainer>
            <div style={{ display: "flex", gap: "10px" }}>
              <RadioButton
                type="radio"
                name={"text"}
                label={"Text"}
                checked={textOrNumber === "text"}
                onChange={(e: any) => {
                  this.props.updateAppliqueView({
                    ...this.props.appliqueView,
                    [selectedSide]: {
                      ...this.props.appliqueView[selectedSide],
                      textOrNumber: e.target.name,
                      selectedNumber: [
                        NUMBERS["Select Numbers"],
                        NUMBERS["Select Numbers"],
                        NUMBERS["Select Numbers"],
                      ],
                    },
                  });
                  // this.props.resetForm(selectedSide, {
                  //   textOrNumber: e.target.name,
                  // });
                  this.props.updatePreview(
                    {
                      settingName: PreviewSettingNames.contentText,
                      settingVal: {
                        value: enteredText,
                      },
                    },
                    DesignerViewStates.Applique
                  );
                }}
              />
              <RadioButton
                type="radio"
                name={"numbers"}
                label={"Numbers"}
                checked={
                  textOrNumber === "numbers" ||
                  typeof textOrNumber == "undefined"
                }
                onChange={(e: any) => {
                  this.props.updateAppliqueView({
                    ...this.props.appliqueView,
                    [selectedSide]: {
                      ...this.props.appliqueView[selectedSide],
                      textOrNumber: e.target.name,
                    },
                  });

                  // this.props.resetForm(selectedSide, {
                  //   textOrNumber: e.target.name,
                  // });
                  this.props.updatePreview(
                    {
                      settingName: PreviewSettingNames.contentText,
                      settingVal: {
                        value: enteredText,
                      },
                    },
                    DesignerViewStates.Applique
                  );
                }}
              />
            </div>
          </RadioContainer>
        )*/}

        <FormContainer>
          <LeftSection>
            {appliqueData.name != "Custom" && isRenderTextorNumber()}

            {/* {appliqueData.fontStyle && ( */}

            {appliqueData.name != "Script with Tail" &&
              appliqueData.name != "Custom" && (
                <FormGroup>
                  {/* <TextZeta className="bold">Font Style</TextZeta> */}
                  <Dropdown
                    options={fontStyleOptions.map(
                      ({ name, _id, price, currency }) => ({
                        label:
                          price > 0 ? `${name} +(${currency}${price})` : name,
                        value: _id,
                      })
                    )}
                    onChange={this.setFontStyle}
                    label="Font Style"
                    value={fontFamilyName}
                  />
                </FormGroup>
              )}

            {/* )} */}
          </LeftSection>

          <RightSection>
            {appliqueData.letterPlacement && !isCenterApplique && appliqueData.name !== "Monogram" && appliqueData.name !== "Simple Text" && (
              <FormGroup>
                <Dropdown
                  label="Letter Placement"
                  options={letterplacementId
                    ?.map(({ name, side, anchor, x_axis, y_axis }) => ({
                      label: name,
                      value: anchor,
                      side,
                      x_axis,
                      y_axis,
                    }))
                    .filter((i) => i.side === selectedSide)}
                  onChange={this.setLetterPlacementChange}
                  value={letterPlacement}
                />
              </FormGroup>
            )}

            {appliqueData.letterSize && (
              <RightSection>
                <TextZeta className="bold">Letter Size</TextZeta>
                <RadioContainer>
                  {greekLetterSizeId.map((greekLetter, i) => (
                    <RadioButton
                      key={`greekLetterSize` + i}
                      name="letterSize"
                      label={greekLetter.size}
                      onChange={() =>
                        this.setLetterSize({
                          _id: greekLetter._id,
                          size: greekLetter.size,
                        })
                      }
                      checked={greekLetter.size === letterSize.size}
                      // onChange={() => {}}
                    />
                  ))}
                </RadioContainer>
              </RightSection>
              // <FormGroup>
              //   <TextZeta className="bold">Letter Size</TextZeta>
              //   <RadioButton
              //     name="letterSize"
              //     label={`4" Standard`}
              //     onClick={() => this.setLetterSize(LetterSizes["4inch"])}
              //     checked={letterSize === LetterSizes["4inch"]}
              //     onChange={() => {}}
              //   />
              //   <RadioButton
              //     name="letterSize"
              //     label={`3" (Designer Patterns not availale)`}
              //     onClick={() => this.setLetterSize(LetterSizes["3inch"])}
              //     checked={letterSize === LetterSizes["3inch"]}
              //     onChange={() => {}}
              //   />
              // </FormGroup>
            )}

            {appliqueData.name === "Monogram" && (
              <>
                <FormGroup>
                  {selectedSide === "back" ? (
                    !isCenterApplique ? (
                      isRenderLetterPlacement()
                    ) : null
                  ) : (
                    <Dropdown
                      label="Letter Placement"
                      options={letterplacementMonogramFront
                        ?.map(({ name, side, anchor, x_axis, y_axis }) => ({
                          label: name,
                          value: anchor,
                          side,
                          x_axis,
                          y_axis,
                        }))
                        .filter((i) => i.side === selectedSide)}
                      onChange={this.setLetterPlacementChange}
                      value={letterPlacement}
                    />
                  )}
                </FormGroup>
                </>
            )}

            {appliqueData.name === "Simple Text" && (
              <>
                <FormGroup>
                  {selectedSide === "back" ? (
                    !isCenterApplique ? (
                      isRenderLetterPlacement()
                    ) : null
                  ) : (
                    <Dropdown
                      label="Letter Placement"
                      options={letterplacementSimpleTextFront
                        ?.map(({ name, side, anchor, x_axis, y_axis }) => ({
                          label: name,
                          value: anchor,
                          side,
                          x_axis,
                          y_axis,
                        }))
                        .filter((i) => i.side === selectedSide)}
                      onChange={this.setLetterPlacementChange}
                      value={letterPlacement}
                    />
                  )}
                </FormGroup>
                </>
            )}
            {/*appliqueData.name != "Monogram" && (
              <>
                <FormGroup>
                  <BodyUI class="regular">Letter Size</BodyUI>
                  <RadioContainer>
                    {greekLetterSizeId.map((greekLetter, i) => (
                      <RadioButton
                        key={`greekLetterSize` + i}
                        name="letterSize"
                        label={greekLetter.size}
                        onChange={() =>
                          this.setLetterSize({
                            _id: greekLetter._id,
                            size: greekLetter.size,
                          })
                        }
                        checked={greekLetter.size === letterSize.size}
                        // onChange={() => {}}
                      />
                    ))}
                  </RadioContainer>
                </FormGroup>
              </>
            )*/}
          </RightSection>
        </FormContainer>

        {appliqueData.name == "Imprint" && (
          <>
            <FormGroupColumn>
              <div>
                <Dropdown
                  options={[
                    {
                      label: "Select Font Color",
                      value: "",
                    },
                    ...foreGroundFillOptions,
                  ]}
                  onChange={(selectedOption: any) =>
                    this.setForeGroundFillStorkeAndMarker(
                      selectedOption.value,
                      ForegroundBackgroundSettings.fill
                    )
                  }
                  label="Thread Color"
                  value={selectedFontColorFill}
                />
              </div>
            </FormGroupColumn>
          </>
        )}
        {appliqueData.name === "Simple Text" && (
          <>
            <FormGroupColumn>
              <div>
                <Dropdown
                  options={[
                    {
                      label: "Select Font Color",
                      value: "",
                    },
                    ...availableThreadOptions,
                  ]}
                  onChange={(selectedOption: any) =>
                    this.setForeGroundFillStorkeAndMarker(
                      selectedOption.value,
                      ForegroundBackgroundSettings.stroke
                    )
                  }
                  label="Thread Color"
                  value={selectedStroke}
                />
              </div>
            </FormGroupColumn>
          </>
        )}
        {appliqueData.name == "Monogram" && selectedSide === "front" && (
          <>
            <FormGroupColumn>
              <div>
                <Dropdown
                  options={[
                    {
                      label: "Select Font Color",
                      value: "",
                    },
                    ...availableThreadOptions,
                  ]}
                  onChange={(selectedOption: any) =>
                    this.setForeGroundFillStorkeAndMarker(
                      selectedOption.value,
                      ForegroundBackgroundSettings.stroke
                    )
                  }
                  label="Thread Color"
                  value={selectedStroke}
                />
              </div>
            </FormGroupColumn>
          </>
        )}
        {appliqueData.name == "Monogram" && selectedSide === "back" && (
          <>
            <div>
              <div
                style={{
                  boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
                  border: "1px solid #ccc",
                  padding: "10px 6px",
                  borderRadius: "0.25rem",
                  margin: "10px 0",
                }}
              >
                <TextZeta
                  className="bold"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Foreground{" "}
                  <a
                    href="/colors"
                    target="_blank"
                    style={{
                      fontSize: "12px",
                      textDecoration: "none",
                      paddingLeft: "10px",
                    }}
                  >
                    View Colors
                  </a>
                </TextZeta>
                <FormGroupColumn>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Select Foreground Material",
                          value: "",
                        },
                        ...foreGroundMaterialOptions,
                      ]}
                      onChange={(selectedOption: any) =>
                        this.setForeGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.material
                        )
                      }
                      value={selectedForegroundMaterial}
                      label="Foreground Material"
                    />
                  </div>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Select Foreground Color",
                          value: "",
                        },
                        ...foreGroundFillOptions,
                      ]}
                      onChange={(selectedOption: any) =>
                        this.setForeGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.fill
                        )
                      }
                      label="Foreground Color"
                      value={selectedforeGroundFill}
                    />
                  </div>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Thread to match",
                          value: "",
                        },
                        ...availableThreadOptions,
                      ]}
                      onChange={(selectedOption: any) =>
                        this.setForeGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.stroke
                        )
                      }
                      label="Foreground Thread Color"
                      value={selectedStroke}
                    />
                  </div>
                </FormGroupColumn>
              </div>

              <div
                style={{
                  boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
                  border: "1px solid #ccc",
                  padding: "10px 6px",
                  borderRadius: "0.25rem",
                  margin: "10px 0",
                }}
              >
                <TextZeta
                  className="bold"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Background
                  <a
                    href="/colors"
                    target="_blank"
                    style={{
                      fontSize: "12px",
                      textDecoration: "none",
                      paddingLeft: "10px",
                    }}
                  >
                    View Colors
                  </a>
                </TextZeta>
                <FormGroupColumn>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Select Background Material ",
                          value: "",
                        },
                        ...backgroundMaterialOptions,
                      ]}
                      value={selectedBackgroundMaterial}
                      onChange={(selectedOption: any) =>
                        this.setBackGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.material
                        )
                      }
                      label="Background Material"
                    />
                  </div>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Select Background Color",
                          value: "",
                        },
                        ...backgroundFills,
                      ]}
                      value={selectedBackgroundFills}
                      onChange={(selectedOption: any) =>
                        this.setBackGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.fill
                        )
                      }
                      label="Background Color"
                    />
                  </div>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Thread to match",
                          value: "",
                        },
                        ...availableThreadOptions,
                      ]}
                      value={selectedBgStroke}
                      onChange={(selectedOption: any) =>
                        this.setBackGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.stroke
                        )
                      }
                      label="Background Thread Color"
                    />
                  </div>
                </FormGroupColumn>
              </div>
            </div>
          </>
        )}
        {appliqueData.name == "Script with Tail" && (
          <>
            <div>
              <div
                style={{
                  boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
                  border: "1px solid #ccc",
                  padding: "10px 6px",
                  borderRadius: "0.25rem",
                  margin: "10px 0",
                }}
              >
                <TextZeta
                  className="bold"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Foreground{" "}
                  <a
                    href="/colors"
                    target="_blank"
                    style={{
                      fontSize: "12px",
                      textDecoration: "none",
                      paddingLeft: "10px",
                    }}
                  >
                    View Colors
                  </a>
                </TextZeta>
                <FormGroupColumn>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Select Foreground Material",
                          value: "",
                        },
                        ...foreGroundMaterialOptions,
                      ]}
                      onChange={(selectedOption: any) =>
                        this.setForeGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.material
                        )
                      }
                      value={selectedForegroundMaterial}
                      label="Foreground Material"
                    />
                  </div>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Select Foreground Color",
                          value: "",
                        },
                        ...foreGroundFillOptions,
                      ]}
                      onChange={(selectedOption: any) =>
                        this.setForeGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.fill
                        )
                      }
                      label="Foreground Color"
                      value={selectedforeGroundFill}
                    />
                  </div>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Thread to match",
                          value: "",
                        },
                        ...availableThreadOptions,
                      ]}
                      onChange={(selectedOption: any) =>
                        this.setForeGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.stroke
                        )
                      }
                      label="Foreground Thread Color"
                      value={selectedStroke}
                    />
                  </div>
                </FormGroupColumn>
              </div>

              <div
                style={{
                  boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
                  border: "1px solid #ccc",
                  padding: "10px 6px",
                  borderRadius: "0.25rem",
                  margin: "10px 0",
                }}
              >
                <TextZeta
                  className="bold"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Background
                  <a
                    href="/colors"
                    target="_blank"
                    style={{
                      fontSize: "12px",
                      textDecoration: "none",
                      paddingLeft: "10px",
                    }}
                  >
                    View Colors
                  </a>
                </TextZeta>
                <FormGroupColumn>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Select Background Material ",
                          value: "",
                        },
                        ...backgroundMaterialOptions,
                      ]}
                      value={selectedBackgroundMaterial}
                      onChange={(selectedOption: any) =>
                        this.setBackGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.material
                        )
                      }
                      label="Background Material"
                    />
                  </div>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Select Background Color",
                          value: "",
                        },
                        ...backgroundFills,
                      ]}
                      value={selectedBackgroundFills}
                      onChange={(selectedOption: any) =>
                        this.setBackGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.fill
                        )
                      }
                      label="Background Color"
                    />
                  </div>
                  <div>
                    <Dropdown
                      options={[
                        {
                          label: "Thread to match",
                          value: "",
                        },
                        ...availableThreadOptions,
                      ]}
                      value={selectedBgStroke}
                      onChange={(selectedOption: any) =>
                        this.setBackGroundFillStorkeAndMarker(
                          selectedOption.value,
                          ForegroundBackgroundSettings.stroke
                        )
                      }
                      label="Background Thread Color"
                    />
                  </div>
                </FormGroupColumn>
              </div>
            </div>
          </>
        )}

        <div>
          {appliqueData.foreground && appliqueData.name !== "Simple Text" && (
            <div
              style={{
                boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
                border: "1px solid #ccc",
                padding: "10px 6px",
                borderRadius: "0.25rem",
                margin: "10px 0",
              }}
            >
              <TextZeta
                className="bold"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                Foreground{" "}
                <a
                  href="/colors"
                  target="_blank"
                  style={{
                    fontSize: "12px",
                    textDecoration: "none",
                    paddingLeft: "10px",
                  }}
                >
                  View Colors
                </a>
              </TextZeta>
              <FormGroupColumn>
                <div>
                  <Dropdown
                    options={[
                      {
                        label: "Select Foreground Material",
                        value: "",
                      },
                      ...foreGroundMaterialOptions,
                    ]}
                    onChange={(selectedOption: any) =>
                      this.setForeGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.material
                      )
                    }
                    value={selectedForegroundMaterial}
                    label="Foreground Material"
                  />
                </div>
                <div>
                  <Dropdown
                    options={[
                      {
                        label: "Select Foreground Color",
                        value: "",
                      },
                      ...foreGroundFillOptions,
                    ]}
                    onChange={(selectedOption: any) =>
                      this.setForeGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.fill
                      )
                    }
                    label="Foreground Color"
                    value={selectedforeGroundFill}
                  />
                </div>
                <div>
                  <Dropdown
                    options={[
                      {
                        label: "Thread to match",
                        value: "",
                      },
                      ...availableThreadOptions,
                    ]}
                    onChange={(selectedOption: any) =>
                      this.setForeGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.stroke
                      )
                    }
                    label="Foreground Thread Color"
                    value={selectedStroke}
                  />
                </div>
              </FormGroupColumn>
            </div>
          )}
          {(appliqueData.background ||
            appliqueData.name == "Twill Letters") && appliqueData.name !== "Simple Text" && (
            <div
              style={{
                boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
                border: "1px solid #ccc",
                padding: "10px 6px",
                borderRadius: "0.25rem",
                margin: "10px 0",
              }}
            >
              <TextZeta
                className="bold"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                Background
                <a
                  href="/colors"
                  target="_blank"
                  style={{
                    fontSize: "12px",
                    textDecoration: "none",
                    paddingLeft: "10px",
                  }}
                >
                  View Colors
                </a>
              </TextZeta>
              <FormGroupColumn>
                <div>
                  <Dropdown
                    options={[
                      {
                        label: "Select Background Material ",
                        value: "",
                      },
                      ...backgroundMaterialOptions,
                    ]}
                    value={selectedBackgroundMaterial}
                    onChange={(selectedOption: any) =>
                      this.setBackGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.material
                      )
                    }
                    label="Background Material"
                  />
                </div>
                <div>
                  <Dropdown
                    options={[
                      {
                        label: "Select Background Color",
                        value: "",
                      },
                      ...backgroundFills,
                    ]}
                    value={selectedBackgroundFills}
                    onChange={(selectedOption: any) =>
                      this.setBackGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.fill
                      )
                    }
                    label="Background Color"
                  />
                </div>
                <div>
                  <Dropdown
                    options={[
                      {
                        label: "Thread to match",
                        value: "",
                      },
                      ...availableThreadOptions,
                    ]}
                    value={selectedBgStroke}
                    onChange={(selectedOption: any) =>
                      this.setBackGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.stroke
                      )
                    }
                    label="Background Thread Color"
                  />
                </div>
              </FormGroupColumn>
            </div>
          )}
        </div>

        {appliqueData.heatSealedLetter &&
          appliqueData.name !== "Monogram" &&
          appliqueData.name != "Custom" && (
            <div style={{ display: "flex" }}>
              <Checkbox
                value={heatSealedLetter}
                checked={heatSealedLetter.isactive}
                onChange={() => handleIsHeatSealedLetters()}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginLeft: "1.5rem",
                  flexFlow: "column wrap",
                }}
              >
                <span>
                  {" "}
                  Heat Sealed Letters{" "}
                  <StyledPrice>+(${heatSealedLetter.cost})</StyledPrice>
                </span>
                <span style={{ fontSize: ".8rem" }}>
                  Increase the longevity of your applique letters, by making
                  them bond with the garment.
                </span>
              </div>
            </div>
          )}

        {appliqueData.specialInstruction && (
          <BottomSection>
            <Textarea
              label="Special Instructions"
              onChange={this.setSpecialInstructions}
              value={specialInstructions}
            ></Textarea>
          </BottomSection>
        )}

        {appliqueData.name == "Imprint" && (
          <Modal
            footer={false}
            isOpen={this.state.isConfirmModalOpen}
            width={"25%"}
            close={this.confirmModalClose}
            iconClose={true}
          >
            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.state.confirmModalHTML}
            </div>
          </Modal>
        )}

        <div
          style={{
            boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
            border: "1px solid #ccc",
            padding: "10px 6px",
            borderRadius: "0.25rem",
            margin: "10px 0",
          }}
        >
          <label>Custom File Upload</label>
          <FormGroupColumn>
            <ImperativeFilePicker
              setCustomFileChange={this.setCustomFileChange}
              setTextChange={this.setTextChange}
              files={this.state.customFiles}
              appliqueInfo={appliqueData}
            />
          </FormGroupColumn>
        </div>
      </>
    );
  }
}

const ImperativeFilePicker = ({
  setCustomFileChange,
  setTextChange,
  files,
  appliqueInfo,
}: any) => {
  const isMultiple = appliqueInfo.name == "Imprint" ? false : true;
  const {
    openFilePicker,
    filesContent,
    loading,
    errors,
    plainFiles,
    clear,
    removeFileByIndex,
    removeFileByReference,
  } = useImperativeFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: isMultiple,
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      // this callback is always called, even if there are errors
      //console.log("onFilesSelected", plainFiles, filesContent, errors);
    },
    onFilesRejected: ({ errors }) => {
      // this callback is called when there were validation errors
      //console.log("onFilesRejected", errors);
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      // this callback is called when there were no validation errors
      //console.log("onFilesSuccessfullySelected", plainFiles, filesContent);
      //console.log("plainFiles", plainFiles);
      setCustomFileChange(filesContent);
    },
    onFileRemoved: (removedFile, removedIndex) => {
      // this callback is called when a file is removed from the list of selected files
      //console.log("onFileRemoved", removedFile, removedIndex);
    },
    validators: [
      new FileAmountLimitValidator({ max: 4 }),
      new FileTypeValidator(["jpg", "jpeg", "png"]),
      new FileSizeValidator({ maxFileSize: 2 * 1024 * 1024 /* 2 MB */ }),
      new ImageDimensionsValidator({
        maxHeight: 900, // in pixels
        maxWidth: 1600,
        minHeight: 50,
        minWidth: 50,
      }),
    ],
  });

  const removeImage = (removedIndex: number) => {
    // this callback is called when a file is removed from the list of selected files
    //console.log("remove Index", removedIndex);
    let fileArr = files;
    fileArr.splice(removedIndex, 1);
    setCustomFileChange(fileArr);
  };

  const clearAll = () => {
    clear();
    setCustomFileChange([]);
  };

  useEffect(() => {
    clear();
  }, [files]);

  if (errors.length) {
    /* console.log(errors); */

    return (
      <div>
        <button onClick={() => openFilePicker()}>
          Something went wrong, retry!{" "}
        </button>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {errors.map((err: any, i) => (
            <div key={`err_${i}`}>
              {err.name} : {err.reason}
              {/* e.g. "name":"FileAmountLimitError",
              "reason":"MAX_AMOUNT_OF_FILES_EXCEEDED" */}
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <button onClick={async () => openFilePicker()}>Select file</button>
      <button onClick={() => clearAll()}>Clear</button>
      <br />
      Amount of selected files:
      {files.length}
      <br />
      {/*Amount of filesContent:
      {filesContent.length}
  <br />*/}
      <div
        style={
          appliqueInfo.name == "Imprint"
            ? {
                marginBottom: "16px",
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }
            : {
                marginBottom: "16px",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }
        }
      >
        {files.map((file: any, i: number) => (
          <div style={{ margin: "5px" }} key={`files_${i}`}>
            <div
              style={{ display: "flex", alignItems: "center" }}
              key={file.name}
            >
              <div>{file.name}</div>
              <button style={{ marginLeft: 24 }} onClick={() => removeImage(i)}>
                Remove
              </button>
              {/*<button
              style={{ marginLeft: 24 }}
              onClick={() => removeFileByIndex(i)}
            >
              Remove by index
            </button>*/}
            </div>
            <div>
              {appliqueInfo.name == "Imprint" && (
                <img alt={file.name} src={file.content}></img>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  const {
    selectedProduct: { letterplacementId, stitchingTypeId, greekLetterSizeId, subCategoryId },
  } = state.products;

  let actualGreekLetterSizeId:any = [];
  if(subCategoryId.name.toLocaleUpperCase().includes("STOLE")){
    actualGreekLetterSizeId = greekLetterSizeId.map((greekLetterSize, i) => {
      let inch = parseInt(greekLetterSize.size.split('"')[0]);
      if(inch < 4){
        return greekLetterSize;
      }
    }).filter((item) => item != null);
  } else actualGreekLetterSizeId = greekLetterSizeId;
  
  return {
    letterplacementId,
    stitchingTypeId,
    greekLetterSizeId : greekLetterSizeId.sort((a, b) => {
      if ( a._id < b._id ){
        return 1;
      }
      if ( a._id > b._id ){
        return -1;
      }
      return 0;
    }),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addPriceToCart: (data: any) => dispatch(addPriceToCart(data)),
    removePriceToCart: (data: any) => dispatch(removePriceToCart(data)),
  };
};

export const EnglishTextSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(EnglishTextSettingsComponent);

const FormGroup = styled.div`
  margin-bottom: ${marginM};
`;

const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DropDownWrapper = styled.div`
  width: 27%;
  margin: 0 10px 10px 0;
`;

const LetterPlacementContainer = styled.div`
  // width: 50%;
  margin: 0 10px 10px 0;
`;

const FormContainer = styled.div`
  display: flex;
`;

const LeftSection = styled.section`
  flex: 1;
  margin-right: ${marginL};
`;

const RightSection = styled.section`
  flex: 1;
`;

const FormGroupColumn = styled.div`
  margin-bottom: ${marginM};
  display: flex;
  justify-content: space-between;
`;

const BottomSection = styled.section``;

const StyledPrice = styled.span<{ color?: string }>`
  text-align: center;
  font-size: 0.85rem;
  color: ${(props) => props.color || "red"};
  flex: 1;
  align-self: center;
`;
