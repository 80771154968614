import React, { useState } from "react";

import {
  StyledInput,
  StyledButton,
  StyledDropdown,
  ButtonContainer,
  StyledTextarea,
  StyledContact
} from "./styled.component";
import { useDispatch } from "react-redux";
import { toast } from "@happeouikit/toast";

import { connect } from "react-redux";
import { footerClient } from "../../ApiClients/FooterClient";
import { contactUsResponse } from "../../models/ApiResponses/FooterResponse";

interface ContactFormProps {}

interface FormData {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string|null;
  phone: string;
  email: string;
  organization: string;
  school: string;
  comments: string;
}

export const ContactForm: React.FC<ContactFormProps> = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    email: "",
    organization: "",
    school: "",
    comments: "",
  });
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const validateForm = (): boolean => {
    const newErrors: Partial<FormData> = {};
    const requiredFields = [
      "firstName",
      "lastName",
      "address1",
      "city",
      "state",
      "zip",
      "country",
      "phone",
      "email",
      "organization",
      "school",
    ];

    // Loop through the required fields and check if they are empty
    requiredFields.forEach((field) => {
      if (!formData[field as keyof FormData]) {
        newErrors[field as keyof FormData] = `${separateCamelCase(field)} is required`;
      }
    });
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
    if (formData.phone && !phoneRegex.test(formData.phone)) {
      newErrors.phone = "Phone number must be in the format XXX-XXX-XXXX";
    }

    // Validate email format (basic email validation)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target; 
    // Format the phone number while typing
    if (name === "phone") {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedPhoneNumber,
      }));
    }
    else if(name ==="zip"){
      let zip = value.length >5?value.slice(0,-1) :value
       setFormData((prevState) => ({
         ...prevState,
         [name]:zip.replace(/\D/g, ''),
       }));
     }
    else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  function separateCamelCase(input: string): string {
    return input
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^([a-z])/, (match) => match.toUpperCase())
      .trim();
  }
  // Phone number formatting (XXX-XXX-XXXX)
  const formatPhoneNumber = (value: string): string => {
    // Remove any non-digit characters
    const cleaned = value.replace(/\D/g, "");

    // Format the phone number into the pattern XXX-XXX-XXXX
    if (cleaned.length <= 3) {
      return cleaned;
    } else if (cleaned.length <= 6) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    } else {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    }
  };


  const handleDropdownChange = (value: any): void => {
    setFormData((prevState) => ({
      ...prevState,
      country: value,
    }));
  };

  // Handle form submit
   // Handle form submit
   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate the form before submission
    if (!validateForm()) {
      return; // If validation fails, prevent submission
    }
       footerClient
         .postContactUs(formData,dispatch)
         .then((res: contactUsResponse) => {
           if (!res?.error) {
             toast.success({ message: res?.message });
            }
         })
         .catch((e) => {
           let err = JSON.parse(e?.metadata?.apiErrorMessage); 
           //toast.error({ message: "Log in failed.", delay: 10000 });
           toast.error({ title: err.message, description: err.error, delay: 10000 });
         });
  };

  // Handle form clear
  const handleClear = () => {
    setFormData({
      firstName: "",
      lastName: "",
      address1: "",
      address2: "", // Optional, cleared on clear
      city: "",
      state: "",
      zip: "",
      country: "",
      phone: "",
      email: "",
      organization: "",
      school: "",
      comments: "",
    });
    setErrors({}); // Clear errors on clear
  };
  return (
    <>
      <StyledContact> Contact</StyledContact>
      <p> Please fill out the following information </p>
      <p style={{color: "red"}}> * indicates required fields</p>
      <form onSubmit={handleSubmit}>
      <StyledInput
        type="text"
        placeholder="First Name *"
        name="firstName"
        value={formData.firstName}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {errors.firstName && <p>{errors.firstName}</p>}

      <StyledInput
        type="text"
        placeholder="Last Name *"
        name="lastName"
        value={formData.lastName}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {errors.lastName && <p>{errors.lastName}</p>}

      <StyledInput
        type="text"
        placeholder="Address Line 1 *"
        name="address1"
        value={formData.address1}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {errors.address1 && <p>{errors.address1}</p>}

      {/* Address Line 2 is optional */}
      <StyledInput
        type="text"
        placeholder="Address Line 2"
        name="address2"
        value={formData.address2}
        onChange={handleInputChange}
        autoComplete="off"
      />

      <StyledInput
        type="text"
        placeholder="City *"
        name="city"
        value={formData.city}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {errors.city && <p>{errors.city}</p>}

      <StyledInput
        type="text"
        placeholder="State/Province/Region *"
        name="state"
        value={formData.state}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {errors.state && <p>{errors.state}</p>}

      <StyledInput
        type="text"
        placeholder="Zip *"
        name="zip"
        value={formData.zip}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {errors.zip && <p>{errors.zip}</p>}

      <StyledDropdown
      placeholder="Country *"
      value={formData.country}
      options={[
        { label: 'United States', value: 'US' },
        { label: 'Canada', value: 'Canada' },
      ]}
      onChange={handleDropdownChange}
    />
      {errors.country && <p>{errors.country}</p>}

      <StyledInput
        type="text"
        placeholder="Phone Number *"
        name="phone"
        value={formData.phone}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {errors.phone && <p>{errors.phone}</p>}

      <StyledInput
        type="text"
        placeholder="Email *"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {errors.email && <p>{errors.email}</p>}

      <StyledInput
        type="text"
        placeholder="Organization *"
        name="organization"
        value={formData.organization}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {errors.organization && <p>{errors.organization}</p>}

      <StyledInput
        type="text"
        placeholder="School *"
        name="school"
        value={formData.school}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {errors.school && <p>{errors.school}</p>}

      <StyledTextarea
        rows={5}
        cols={86}
        placeholder="Enter questions or comments here"
        name="comments"
        value={formData.comments}
        onChange={handleInputChange}
      />

      <ButtonContainer>
        <StyledButton type="submit">Submit</StyledButton>
        <StyledButton type="button" onClick={handleClear}>Clear</StyledButton>
      </ButtonContainer>
      </form>
      <div style={{marginBottom: "25px"}} />
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  postContactUs : footerClient.postContactUs(FormData, dispatch)
});

export const Header = connect(
  null,
  mapDispatchToProps
)(ContactForm);