import React from "react";
import Accordion from "./Accordion";
import {
  Container,
  StyledPBlue,
  AccordionCard,
  FAQHeader,
  StyledDiv,
} from "./styled.component";
import SideMenuBar from "../FooterPages/SideMenuBar";
import { sidebarMenu } from "../FooterPages/StaticData.Constant";

interface FAQPageProps {}
interface FAQPageState {
  active: any;
}

const Faq = [
  {
    title: "ORDERING",
    data: [
      {
        question: "Where is the shopping cart?",
        answer:
          "StitchZone does not offer a saved shopping cart. Once you close your browser the information may be lost.",
      },
      {
        question: "I want to order different items, how do I do that?",
        answer:
          "Submit an on-line order form for the first item that you want, in STEP 4 you can ADD ANOTHER ITEM.",
      },
      {
        question:
          "I want to order many pieces of the same item, but each one has a different nickname and number, do I have to submit a different order form for each one?",
        answer:
          "No, you do not need to submit multiple orders. Complete the order form for one item, in STEP 4 you DUPLICATE the item then EDIT it changing only the information that is different for that item. (i.e. size, nickname, number)",
      },
      {
        question: "Did I get charged for submitting an order inquiry?",
        answer:
          "No, you did not get charged when you submitted your order inquiry. After you received the quote from our sales rep AND you accept the quote, you will return to our site and click the PAY FOR ORDER button to finish the process. At that time you will enter your credit card information.",
      },
      {
        question: "When do you start work on my order?",
        answer:
          "We start production once payment is received in full. Checks and E-checks need to CLEAR before we can start production on your order.",
      },
      {
        question: "How long do I have to wait before I get my stuff?",
        answer:
          "If you selected to have your order processed using our Standard production time (2 to 3 weeks) not including shipping time, and however fast you wanted shipping. This time may vary during our busy season.",
      },
      {
        question: "Do you offer RUSH service?",
        answer:
          "Yes, we offer RUSH service for orders. Please enter the NEED BY DATE when submitting your order. Depending on the amount of time we have for production the percentage of the RUSH FEE can vary from 20% to 100%. This price does not include the shipping time and method, therefore Premium shipping prices may be applied.",
      },
      {
        question:
          "When will I get my order in hand, if I indicate a NEED BY DATE?",
        answer:
          "You will receive your order either prior or the day of the date you have indicated. As long as you have paid for the order by the date specified in the quote which was emailed to you. Failure to pay by the date specified will result in a delay of the order.",
      },
    ],
  },
  {
    title: "PAYMENT",
    data: [
      {
        question: "What forms of payment do we accept?",
        answer:
          "StitchZone accepts Master Card, VISA, PayPal, Money Order and Checks, however with checks payment will not be accepted until the check clears the bank.",
      },
      {
        question: "Why is my card beling declined?",
        answer:
          "StitchZone has placed a high security check on our system, for the safety of our customers. Possible reasons as to why the card is being Declined ,1. The BILLING address that you entered does not match what the credit card company has on file. Please contact them to varify that address. 2. There is a limit on the amount that the card can charge",
      },
    ],
  },
];

export class FAQPage extends React.Component<FAQPageProps, FAQPageState> {
  constructor(props: FAQPageState) {
    super(props);
    this.state = {
      active: null,
    };
  }

  handleAccordion = (id: String) => {
    this.setState({ active: id });
  };

  render() {
    return (
      <Container>
        <SideMenuBar menuItems={sidebarMenu}/>
        <StyledDiv>        
        <StyledPBlue>Frequently Asked questions</StyledPBlue>
        {Faq.map((faq, i) => {
          return (
            <AccordionCard key={i}>
              <FAQHeader>{faq.title}</FAQHeader>
              {faq.data.map((query, index) => (
                <Accordion
                  key={index}
                  active={this.state.active}
                  id={i.toString() + index}
                  handleToggle={this.handleAccordion}
                  question={query.question}
                >
                  {query.answer}
                </Accordion>
              ))}
            </AccordionCard>
          );
        })}
        </StyledDiv>
      </Container>
    );
  }
}
