import React from "react";
import {
  Section,
  Logo,
  Desc,
  FormContainer,
  Title,
  SubTitle,
  Link,
  SignUpCta,
  SignUpLink,
} from "./styled.component";
import { ForgetPasswordForm } from "./ForgotPassword.form";
import LogoImg from "../../images/Logo.png";
import { IForgotPasswordRequestParams, IResetPasswordRequestParams, ResetPasswordResponse,ForgotPasswordResponse } from "../../models";
import { authClient } from "../../ApiClients/AuthClient";
import { toast } from "@happeouikit/toast";
import { authCookieName, userDetails } from "../../constants";
import { Navigate } from "react-router-dom";
import { ResetPasswordForm } from "./ResetPassword.form";

interface forgetPasswordPageProps {}

interface forgetPasswordPageState {
  test:boolean
  currentPath:string
}

export class ForgetPasswordPage extends React.Component<forgetPasswordPageProps, forgetPasswordPageState> {
  constructor(props: forgetPasswordPageProps) {
    super(props);
    this.state = {
      test:window.location.href.toLocaleLowerCase().includes("password?token")?false:true,
      currentPath: window.location.href
    };
    if (sessionStorage.getItem("userDetails") != null) {
      window.location.href = "/";
    }
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  handleChangePassword(params: IForgotPasswordRequestParams) {
    authClient
      .forgetPassword(params)
      .then((res: ForgotPasswordResponse) => {
        console.log(res.data);
        if (!res?.error) {
          toast.success({ message: res.message });
        this.setState({test:false});
        } else {
          toast.error({
            message:typeof res?.error === 'string' && res?.error?.includes("not registered")? `${res?.error}`:"Wrong email. Please try again",
            delay: 10000, 
          });
        }
      })
      .catch((e) => {
        console.log("🚀 ~ file: forgotPassword.page.tsx:58 ~ handlechangePassword ~ e", e);
        toast.error({ message: "change password failed.", delay: 10000 });
      });
  }
  
  handleResetPassword(params: IResetPasswordRequestParams) {
    authClient
      .resetPassword(params)
      .then((res: ResetPasswordResponse) => {
        console.log(res.data);
        if (!res?.error) {
          toast.success({ message: res.message });
          window.location.pathname = "/login";
        } else {
          toast.error({
            message:typeof res?.error === 'string' && (res?.error?.includes("Token") || res?.error?.includes("password"))? `${res?.error}`:"Wrong email. Please try again",
            delay: 10000,
          });
        }
      })
      .catch((e) => {
        console.log("🚀 ~ file: resetPassword.page.tsx:79 ~ handlecresetPassword ~ e", e);
        toast.error({ message: "reset  password failed.", delay: 10000 });
      });

  }

  render() {
    return (
      <Section>
        <FormContainer>
          <Title>Forgot Password?</Title>
          <SubTitle>Enter Account Details</SubTitle>
         {this.state.test ?
          <ForgetPasswordForm handleChangePassword={this.handleChangePassword} />
          :
          <ResetPasswordForm handleResetPassword={this.handleResetPassword}/>
          }
          <Link href="/login" >Login</Link>
          <SignUpCta>
            Don't have an account?{" "}
            <SignUpLink href="/sign-up">Sign Up</SignUpLink>
          </SignUpCta>
        </FormContainer>
      </Section>
    );
  }
}
