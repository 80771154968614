import React, { Component } from 'react';
import MainLayout from './MainLayout'; 
import MainContent, { MainContentProps } from './MainContent'; 

class CreateMainLayout extends Component<MainContentProps> {
  render() {
    const { heading, subHeadings } = this.props;

    return (
      <MainLayout
        Content={MainContent} 
        heading={heading}    
        subHeadings={subHeadings}
      />
    );
  }
}

export default CreateMainLayout;