import React from "react";
import { TextBeta, TinyText } from "@happeouikit/typography";
import { RadioButton } from "@happeouikit/form-elements";
import styled from "styled-components";
import {
  DesignerViewStates,
  GREEK_ALPHABETS,
  GreekLettersList,
  IAppliqueData,
  IAppState,
  ICustomizationOptions,
  IFontStyle,
  ILetterPlacement,
  IMaterialFillStrokeSash,
  IMaterialFillStrokeBackGround,
  IMaterialFillStrokeForeGround,
  IThreadToMatch,
  NUMBERS,
  PreviewSettingNames,
  UpdatePreviewParams,
  ICartState,
} from "../../../models";
import { EnglishTextSettings } from "./EnglishTextSettings";
import { GreekTextSettings } from "./GreekTextSettings";
import {
  defaulGreekText,
  defaultEnglishText,
  marginM,
  marginS,
  marginXL,
  paddingM,
  paddingS,
  primaryColor,
  GreekLetters
} from "../../../constants";
import { connect, useSelector } from "react-redux";
import {
  GreekLetterSizeId,
  IOneProductV2,
  LetterplacementID,
  StitchingTypeId,
  IColorV2,
} from "../../../models/ApiResponses/IGetProductResponseV2";
import { NumbersList } from "../../../models/interfaces/Numbers";
import { Dispatch } from "redux";
import {
  addPriceToCart,
  updatePriceToCart,
  updateAppqeFrontPriceToCart,
  updateAppqeBackPriceToCart,
  updateAppliqueRedux,
} from "../../../actions";

export enum ALPHABET_TYPES {
  NUMBER = "Number",
  GREEK = "Greek",
  ROMAN = "English",
}

interface AppliqueProps {
  updatePreview(params: UpdatePreviewParams, key: string): void;
  clearPreview(): Promise<void>;
  onNext(appliqueId: any): void;
  onBack(): void;
  selectedSide: string;
  cartAddedData(obj: any): void;
  designViews: any;
  updateAppliqueDesignViews(data: any): void;
  selectedColor: IColorV2 | undefined;
  selectedSizes: Record<string, number> | undefined;
  editCartProduct: any;
}

interface AppliquePropsFromRedux {
  availableForegroundOptions: ICustomizationOptions;
  availableBackgroundOptions: ICustomizationOptions;
  fontStyleOptions: Array<IFontStyle>;
  isCustomizationOptionsLoaded: boolean;
  availableThreadOptions: Array<IThreadToMatch>;
  appliques: IAppliqueData[];
  selectedApplique?: IAppliqueData;
  selectedProduct: IOneProductV2;
  subCategoryId: any;
  letterplacementId: Array<LetterplacementID>;
  stitchingTypeId: StitchingTypeId[];
  greekLetterSizeId: GreekLetterSizeId[];
  updateAppliqueRedux(appliqueView: AppliqueView): void;
  appliqueView: any;
  cartInfo: ICartState;
  addPriceToCart(data: any): void;
  updatePriceToCart(data: any): void;
  updateAppqeFrontPriceToCart(data: any): void;
  updateAppqeBackPriceToCart(data: any): void;
}

export interface AppliqueView {
  [key: string]: {
    alphabetType: ALPHABET_TYPES;
    letterSize: {
      _id: string;
      size: string;
    };
    stitchingType: {
      _id: string;
      stitchingType: string;
      price: number;
      currency: string;
    };
    letterPlacement: any;
    specialInstructions: string;
    selectedGreekText: any; //GreekLettersList;
    sashSettings?: IMaterialFillStrokeSash;
    foreGroundSettings: IMaterialFillStrokeForeGround;
    backgroundSettings: IMaterialFillStrokeBackGround;
    selectedNumber?: NumbersList;
    isCenterApplique?: boolean;
    heatSealedLetter: {
      isactive: boolean;
      cost: string;
    };
    greekorNumber?: string;
    enteredText: string;
    fontFamilyName: any;
    customFiles: any;
  };
}

interface AppliqueState {
  selectedApplique?: IAppliqueData;
  appliqueView: AppliqueView;
}

const StitchingTypeDefault = {
  _id: "",
  stitchingType: "",
  price: 0,
  currency: "",
};

const LetterSizeDefault = {
  _id: "",
  size: "",
};

const LetterplacementDefault = {
  label: "",
  value: {
    x_axis: "",
    y_axis: "",
  },
};

const FontFamilyDefault = {
  label: "",
  value: "",
};

class Applique extends React.Component<
  AppliqueProps & AppliquePropsFromRedux,
  AppliqueState
> {

  alphabetTypeContainer = React.createRef();

  constructor(props: AppliqueProps & AppliquePropsFromRedux) {
    super(props);

    const {
      availableForegroundOptions,
      availableBackgroundOptions,
      availableThreadOptions,
      letterplacementId,
      stitchingTypeId,
      greekLetterSizeId,
      fontStyleOptions,
      appliqueView,
      selectedProduct,
      selectedSide,
      editCartProduct,
      subCategoryId
    } = props;
    const defaultForeFGroundMaterial = availableForegroundOptions[Object.keys(availableForegroundOptions)[0]];
    //console.log("=========== defaultForeFGroundMaterial ================ ", defaultForeFGroundMaterial);
    const defaultSashMaterial = availableForegroundOptions[Object.keys(availableForegroundOptions)[1]];
    const defaultBackGroundMaterial =
      availableBackgroundOptions[Object.keys(availableBackgroundOptions)[0]];

    const frontObj = this.props.designViews["applique"].find((i: any) => {
      return i.view === "front";
    });
    const backObj = this.props.designViews["applique"].find((i: any) => {
      return i.view === "back";
    });

    if(editCartProduct){
      let isGreekLettersF = editCartProduct.customizedProducts.appliqueId.front.letterSize.size.indexOf("Greek") !== -1;
      let letterSizeF = editCartProduct.customizedProducts.appliqueId.front.letterSize;
      let stitchingTypeF = editCartProduct.customizedProducts.appliqueId.front.stitchingType;
      let specialInstructionF = editCartProduct.customizedProducts.appliqueId.front.specialInstruction;
      let organizationF = editCartProduct.customizedProducts.appliqueId.front.organization;
      let heatSealedLetterF = editCartProduct.customizedProducts.appliqueId.front.heatSealedLetter;
      let foregroundF = {
        fill: editCartProduct.customizedProducts.appliqueId.front.foreground?.foregroundColor?.value,
        stroke: editCartProduct.customizedProducts.appliqueId.front.foreground?.threadColor?.value,
        material: editCartProduct.customizedProducts.appliqueId.front.foreground?.foregroundMaterial?._id,
      }
      let backgroundF = {
        fill: editCartProduct.customizedProducts.appliqueId.front.background?.backgroundColor?.value,
        stroke: editCartProduct.customizedProducts.appliqueId.front.background?.threadColor?.value,
        material: editCartProduct.customizedProducts.appliqueId.front.background?.backgroundMaterial?._id,
      }

      /* organization text */
      let selectedGreekTextF = [
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
      ];

      if(isGreekLettersF && organizationF){
        organizationF.split("").map((lett:any, i: number) => {
          selectedGreekTextF[i] = lett;
        });
      }

      let isGreekLettersB = editCartProduct.customizedProducts.appliqueId.back.letterSize.size.indexOf("Greek") !== -1;
      let letterSizeB = isGreekLettersB?editCartProduct.customizedProducts.appliqueId.back.letterSize:{
        _id: "",
        size: '7.0"'
      };
      let stitchingTypeB = editCartProduct.customizedProducts.appliqueId.back.stitchingType;
      let specialInstructionB = editCartProduct.customizedProducts.appliqueId.back.specialInstruction;
      let organizationB = editCartProduct.customizedProducts.appliqueId.back.organization;
      let heatSealedLetterB = editCartProduct.customizedProducts.appliqueId.back.heatSealedLetter;
      let foregroundB = {
        fill: editCartProduct.customizedProducts.appliqueId.back.foreground?.foregroundColor?.value,
        stroke: editCartProduct.customizedProducts.appliqueId.back.foreground?.threadColor?.value,
        material: editCartProduct.customizedProducts.appliqueId.back.foreground?.foregroundMaterial?._id,
      }
      let backgroundB = {
        fill: editCartProduct.customizedProducts.appliqueId.back.background?.backgroundColor?.value,
        stroke: editCartProduct.customizedProducts.appliqueId.back.background?.threadColor?.value,
        material: editCartProduct.customizedProducts.appliqueId.back.background?.backgroundMaterial?._id,
      }

      /* organization text */
      let selectedGreekTextB = [
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
      ];

      if(isGreekLettersB && organizationB){
        organizationB.split("").map((lett:any, i: number) => {
          selectedGreekTextB[i] = lett;
        });
      }

      this.state = {
        appliqueView: {
          front: {
            alphabetType: isGreekLettersF
              ? ALPHABET_TYPES.GREEK
              : ALPHABET_TYPES.ROMAN,
            letterSize:
              letterSizeF.size
                ? {
                    _id: letterSizeF._id,
                    size: letterSizeF.size,
                  }
                : LetterSizeDefault,
            stitchingType:
              stitchingTypeF.stitchingType
                ? {
                    _id: stitchingTypeF._id,
                    stitchingType: stitchingTypeF.stitchingType,
                    price: stitchingTypeF.price,
                    currency: stitchingTypeF.currency,
                  }
                : StitchingTypeDefault,
            letterPlacement:
              letterplacementId.length > 0
                ? letterplacementId
                    .filter((i) => i.side === this.props.selectedSide)
                    .map((i: any) => {
                      return {
                        label: i.name,
                        value: {
                          x_axis: i.x_axis,
                          y_axis: i.y_axis,
                        },
                      };
                    })[0]
                : LetterSizeDefault,
            specialInstructions: specialInstructionF,
            greekorNumber: "greek",
            selectedGreekText: selectedGreekTextF,
            foreGroundSettings: {
              fill: foregroundF.fill || "",
              stroke: foregroundF.stroke || "",
              material: foregroundF.material || defaultForeFGroundMaterial.value,
            },
            backgroundSettings: {
              fill: backgroundF.fill || "",
              stroke: backgroundF.stroke || "",
              material: backgroundF.material || defaultBackGroundMaterial.value,
            },
            enteredText: organizationF,
            heatSealedLetter: heatSealedLetterF,
            fontFamilyName:
              fontStyleOptions.length > 0
                ? {
                    label: fontStyleOptions[0].name,
                    value: fontStyleOptions[0]._id,
                  }
                : FontFamilyDefault,
            customFiles: [],
          },
          back: {
            alphabetType: isGreekLettersB
              ? ALPHABET_TYPES.GREEK
              : ALPHABET_TYPES.ROMAN,
            letterSize:
              letterSizeB.size
                ? {
                    _id: letterSizeB._id,
                    size: letterSizeB.size,
                  }
                : LetterSizeDefault,
            stitchingType:
              stitchingTypeB.stitchingType
                ? {
                    _id: stitchingTypeB._id,
                    stitchingType: stitchingTypeB.stitchingType,
                    price: stitchingTypeB.price,
                    currency: stitchingTypeB.currency,
                  }
                : StitchingTypeDefault,
            letterPlacement:
              letterplacementId.length > 0
                ? letterplacementId
                    .filter((i) => i.side === this.props.selectedSide)
                    .map((i: any) => {
                      return {
                        label: i.name,
                        value: {
                          x_axis: i.x_axis,
                          y_axis: i.y_axis,
                        },
                      };
                    })[0]
                : LetterSizeDefault,
            specialInstructions: specialInstructionB,
            isCenterApplique: true,
            heatSealedLetter: heatSealedLetterB || selectedProduct.heatSealedLetter,
            greekorNumber: isGreekLettersB?"greek":"numbers",
            selectedGreekText: selectedGreekTextB,
            selectedNumber: [
              NUMBERS["Select Numbers"],
              NUMBERS["Select Numbers"],
              NUMBERS["Select Numbers"],
            ],
            foreGroundSettings: {
              fill: foregroundB.fill || "",
              stroke: foregroundB.stroke || "",
              material: foregroundB.material || defaultForeFGroundMaterial.value,
            },
            backgroundSettings: {
              fill: backgroundB.fill || "",
              stroke: backgroundB.stroke || "",
              material: backgroundB.material || defaultBackGroundMaterial.value,
            },
            enteredText: organizationB || "",
            fontFamilyName:
              fontStyleOptions.length > 0
                ? {
                    label: fontStyleOptions[0].name,
                    value: fontStyleOptions[0]._id,
                  }
                : FontFamilyDefault,
            customFiles: [],
          }
        },
      };
    } else {

      if (Boolean(frontObj.value)) {
        this.state = {
          appliqueView: {
            front: {
              ...appliqueView["front"],
            },
          },
        };
      } else {
        let selectDefaultLetterSizeArr:any = [];
        if(selectedProduct.subCategoryId.name.toLocaleUpperCase().includes("STOLE")){
          selectDefaultLetterSizeArr = greekLetterSizeId.filter((v) => v.size.indexOf("3") !== -1);
        } else {
          selectDefaultLetterSizeArr = greekLetterSizeId.filter((v) => v.size.indexOf("4") !== -1);
        }

        this.state = {
          appliqueView: {
            front: {
              alphabetType: props.selectedApplique?.greekLetter
                ? ALPHABET_TYPES.GREEK
                : ALPHABET_TYPES.ROMAN,
              letterSize:
                greekLetterSizeId.length > 0 && selectDefaultLetterSizeArr[0]
                  ? {
                      _id: selectDefaultLetterSizeArr[0]._id,
                      size: selectDefaultLetterSizeArr[0].size,
                    }
                  : LetterSizeDefault,
              stitchingType:
                stitchingTypeId.length > 0
                  ? {
                      _id: stitchingTypeId[0]._id,
                      stitchingType: stitchingTypeId[0].stitchingType,
                      price: stitchingTypeId[0].price,
                      currency: stitchingTypeId[0].currency,
                    }
                  : StitchingTypeDefault,
              letterPlacement:
                letterplacementId.length > 0
                  ? letterplacementId
                      .filter((i) => i.side === this.props.selectedSide)
                      .map((i: any) => {
                        return {
                          label: i.name,
                          value: {
                            x_axis: i.x_axis,
                            y_axis: i.y_axis,
                          },
                        };
                      })[0]
                  : LetterSizeDefault,
              // letterPlacement:
              //   letterplacementId.length > 0
              //     ? {
              //         label: letterplacementId[0].name,
              //         value: {
              //           x_axis: letterplacementId[0].x_axis,
              //           y_axis: letterplacementId[0].y_axis,
              //         },
              //       }
              //     : LetterplacementDefault,
              specialInstructions: "",
              greekorNumber: "greek",
              selectedGreekText: [
                GREEK_ALPHABETS["Select Letter"],
                GREEK_ALPHABETS["Select Letter"],
                GREEK_ALPHABETS["Select Letter"],
                GREEK_ALPHABETS["Select Letter"],
                GREEK_ALPHABETS["Select Letter"],
                GREEK_ALPHABETS["Select Letter"],
              ],
              sashSettings: {
                fill: "",
                stroke: "",
                material: defaultSashMaterial.value,
              },
              foreGroundSettings: {
                fill: "",
                stroke: "",
                material: defaultForeFGroundMaterial.value,
              },
              backgroundSettings: {
                fill: "",
                stroke: "",
                material: defaultBackGroundMaterial.value,
              },
              enteredText: "",
              heatSealedLetter: selectedProduct.heatSealedLetter,
              fontFamilyName:
                fontStyleOptions.length > 0
                  ? {
                      label: fontStyleOptions[0].name,
                      value: fontStyleOptions[0]._id,
                    }
                  : FontFamilyDefault,
              customFiles: [],
            },
          },
        };
      }

      if (Boolean(backObj.value)) {
        this.state = {
          appliqueView: {
            front: this.state.appliqueView["front"],
            back: {
              ...appliqueView["back"],
            },
          },
        };
      } else {
        let selectDefaultLetterSizeArr:any = [];
        if(selectedProduct.subCategoryId.name.toLocaleUpperCase().includes("STOLE")){
          selectDefaultLetterSizeArr = greekLetterSizeId.filter((v) => v.size.indexOf("3") !== -1);
        } else {
          selectDefaultLetterSizeArr = greekLetterSizeId.filter((v) => v.size.indexOf("4") !== -1);
        }

        this.state = {
          appliqueView: {
            front: this.state.appliqueView["front"],
            back: {
              alphabetType: props.selectedApplique?.greekLetter
                ? ALPHABET_TYPES.GREEK
                : ALPHABET_TYPES.ROMAN,
              letterSize:
                greekLetterSizeId.length > 0 && selectDefaultLetterSizeArr[0]
                  ? {
                      _id: selectDefaultLetterSizeArr[0]._id,
                      size: selectDefaultLetterSizeArr[0].size,
                    }
                  : LetterSizeDefault,
              stitchingType:
                stitchingTypeId.length > 0
                  ? {
                      _id: stitchingTypeId[0]._id,
                      stitchingType: stitchingTypeId[0].stitchingType,
                      price: stitchingTypeId[0].price,
                      currency: stitchingTypeId[0].currency,
                    }
                  : StitchingTypeDefault,
              letterPlacement:
                letterplacementId.length > 0
                  ? letterplacementId
                      .filter((i) => i.side === this.props.selectedSide)
                      .map((i: any) => {
                        return {
                          label: i.name,
                          value: {
                            x_axis: i.x_axis,
                            y_axis: i.y_axis,
                          },
                        };
                      })[0]
                  : LetterSizeDefault,
              // letterPlacement:
              //   letterplacementId.length > 0
              //     ? {
              //         label: letterplacementId[0].name,
              //         value: {
              //           x_axis: letterplacementId[0].x_axis,
              //           y_axis: letterplacementId[0].y_axis,
              //         },
              //       }
              //     : LetterplacementDefault,
              specialInstructions: "",
              isCenterApplique: true,
              heatSealedLetter: selectedProduct.heatSealedLetter,
              greekorNumber: "numbers",
              selectedGreekText: [
                GREEK_ALPHABETS["Select Letter"],
                GREEK_ALPHABETS["Select Letter"],
                GREEK_ALPHABETS["Select Letter"],
                GREEK_ALPHABETS["Select Letter"],
                GREEK_ALPHABETS["Select Letter"],
                GREEK_ALPHABETS["Select Letter"],
              ],
              selectedNumber: [
                NUMBERS["Select Numbers"],
                NUMBERS["Select Numbers"],
                NUMBERS["Select Numbers"],
              ],
              foreGroundSettings: {
                fill: "",
                stroke: "",
                material: defaultForeFGroundMaterial.value,
              },
              backgroundSettings: {
                fill: "",
                stroke: "",
                material: defaultBackGroundMaterial.value,
              },
              enteredText: "",
              fontFamilyName:
                fontStyleOptions.length > 0
                  ? {
                      label: fontStyleOptions[0].name,
                      value: fontStyleOptions[0]._id,
                    }
                  : FontFamilyDefault,
              customFiles: [],
            },
          },
        };
      }
    }

    let sectionTotalPrice =
      selectedSide == "front"
        ? this.props.cartInfo.cartData.appliqueFrontPageTotalPrice
        : this.props.cartInfo.cartData.appliqueBackPageTotalPrice;
    /* console.log("sectionTotalPrice - updatePriceToCart"); */
    this.props.updatePriceToCart({
      totalPrice: sectionTotalPrice,
    });
    /* console.log(
      "this.props.cartInfo.cartData.totalPrice ========================= ",
      sectionTotalPrice
    ); */
    if (sectionTotalPrice == 0) {
      /* add heat seal letter cost in cart price calculation */
      if (
        this.state.appliqueView[selectedSide].heatSealedLetter &&
        this.state.appliqueView[selectedSide].heatSealedLetter.isactive
      ) {
        /* console.log("heatSealedLetter - addPriceToCart"); */
        this.props.addPriceToCart({
          price: parseFloat(
            (this.state.appliqueView[selectedSide].heatSealedLetter as any)[
              "cost"
            ]
          ),
        });
      }
    }

    this.setAlphabetType = this.setAlphabetType.bind(this);
    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.contentText,
        settingVal: {
          value: defaulGreekText,
        },
      },
      DesignerViewStates.Applique
    );
    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.customFilePreview,
        settingVal: {
          value: "",
        },
      },
      DesignerViewStates.Applique
    );
    /* console.log("updateAppliqueRedux : ", this.state.appliqueView); */
    this.props.updateAppliqueRedux(this.state.appliqueView);
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.selectedSide !== this.props.selectedSide) {
      /* console.log("componentDidUpdate called : ", this.props.selectedSide);
      console.log("componentDidUpdate - updatePriceToCart"); */

      /* save the last total price into previous side */
      if (prevProps.selectedSide == "front") {
        /* console.log("componentDidUpdate - updateAppqeFrontPriceToCart"); */
        this.props.updateAppqeFrontPriceToCart({
          totalPrice: this.props.cartInfo.cartData.totalPrice,
        });
      } else {
        /* console.log("componentDidUpdate - updateAppqeBackPriceToCart"); */
        this.props.updateAppqeBackPriceToCart({
          totalPrice: this.props.cartInfo.cartData.totalPrice,
        });
      }

      let totalPrice =
        this.props.selectedSide == "front"
          ? this.props.cartInfo.cartData.appliqueFrontPageTotalPrice
          : this.props.cartInfo.cartData.appliqueBackPageTotalPrice;

      this.props.updatePriceToCart({
        totalPrice: totalPrice,
      });

      if (totalPrice == 0) {
        /* add heat seal letter cost in cart price calculation */
        if (
          this.state.appliqueView[this.props.selectedSide].heatSealedLetter &&
          this.state.appliqueView[this.props.selectedSide].heatSealedLetter
            .isactive
        ) {
          /* console.log("heatSealedLetter - addPriceToCart"); */
          this.props.addPriceToCart({
            price: parseFloat(
              (
                this.state.appliqueView[this.props.selectedSide]
                  .heatSealedLetter as any
              )["cost"]
            ),
          });
        }
      }

      this.setState({
        ...this.state,
        appliqueView: {
          ...this.state.appliqueView,
          [this.props.selectedSide]: {
            ...this.state.appliqueView[this.props.selectedSide],
            letterPlacement: this.props.letterplacementId
              .filter((i) => i.side === this.props.selectedSide)
              .map((i: any) => {
                return {
                  label: i.name,
                  value: {
                    x_axis: i.x_axis,
                    y_axis: i.y_axis,
                  },
                };
              })[0],
          },
        },
      });
    }
  }

  resetForm = (selectedSide: string, _props?: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      const {
        availableForegroundOptions,
        availableBackgroundOptions,
        availableThreadOptions,
        letterplacementId,
        stitchingTypeId,
        greekLetterSizeId,
        selectedApplique,
        fontStyleOptions,
        selectedProduct,
      } = this.props;

      //console.log("letterplacementId from applique.tsx", letterplacementId);

      const defaultForeFGroundMaterial =
        availableForegroundOptions[Object.keys(availableForegroundOptions)[0]];

      const defaultSashMaterial = availableForegroundOptions[Object.keys(availableForegroundOptions)[1]];

      const defaultBackGroundMaterial =
        availableBackgroundOptions[Object.keys(availableBackgroundOptions)[0]];

      if (selectedSide === "front") {
        let selectDefaultLetterSizeArr = greekLetterSizeId.filter((v) => v.size.indexOf("4") !== -1);
        this.setState(
          {
            appliqueView: {
              ...this.state.appliqueView,
              [selectedSide]: {
                alphabetType: selectedApplique?.greekLetter
                  ? ALPHABET_TYPES.GREEK
                  : ALPHABET_TYPES.ROMAN,
                letterSize:
                  greekLetterSizeId.length > 0 && selectDefaultLetterSizeArr[0]
                    ? {
                        _id: selectDefaultLetterSizeArr[0]._id,
                        size: selectDefaultLetterSizeArr[0].size,
                      }
                    : LetterSizeDefault,
                stitchingType:
                  stitchingTypeId.length > 0
                    ? {
                        _id: stitchingTypeId[0]._id,
                        stitchingType: stitchingTypeId[0].stitchingType,
                        price: stitchingTypeId[0].price,
                        currency: stitchingTypeId[0].currency,
                      }
                    : StitchingTypeDefault,
                letterPlacement:
                  letterplacementId.length > 0
                    ? letterplacementId
                        .filter((i) => i.side === this.props.selectedSide)
                        .map((i: any) => {
                          return {
                            label: i.name,
                            value: {
                              x_axis: i.x_axis,
                              y_axis: i.y_axis,
                            },
                          };
                        })[0]
                    : LetterSizeDefault,
                // letterPlacement:
                //   letterplacementId.length > 0
                //     ? {
                //         label: letterplacementId[0].name,
                //         value: {
                //           x_axis: letterplacementId[0].x_axis,
                //           y_axis: letterplacementId[0].y_axis,
                //         },
                //       }
                //     : LetterplacementDefault,
                specialInstructions: "",
                greekorNumber: "greek",
                selectedGreekText: [
                  GREEK_ALPHABETS["Select Letter"],
                  GREEK_ALPHABETS["Select Letter"],
                  GREEK_ALPHABETS["Select Letter"],
                  GREEK_ALPHABETS["Select Letter"],
                  GREEK_ALPHABETS["Select Letter"],
                  GREEK_ALPHABETS["Select Letter"],
                ],
                foreGroundSettings: {
                  fill: "",
                  stroke: "",
                  material: defaultForeFGroundMaterial.value,
                },
                sashSettings: {
                  fill: "",
                  stroke: "",
                  material: defaultSashMaterial.value
                },
                backgroundSettings: {
                  fill: "",
                  stroke: "",
                  material: defaultBackGroundMaterial.value,
                },
                heatSealedLetter: selectedProduct.heatSealedLetter,
                enteredText: "",
                fontFamilyName:
                  fontStyleOptions.length > 0
                    ? {
                        label: fontStyleOptions[0].name,
                        value: fontStyleOptions[0]._id,
                      }
                    : FontFamilyDefault,
                customFiles: [],
                ..._props,
              },
            },
          },
          resolve
        );
      } else {
        let selectDefaultLetterSizeArr = greekLetterSizeId.filter((v) => v.size.indexOf("4") !== -1);
        this.setState(
          {
            appliqueView: {
              ...this.state.appliqueView,
              [selectedSide]: {
                alphabetType: selectedApplique?.greekLetter
                  ? ALPHABET_TYPES.GREEK
                  : ALPHABET_TYPES.ROMAN,
                letterSize:
                  greekLetterSizeId.length > 0 && selectDefaultLetterSizeArr[0]
                    ? {
                        _id: selectDefaultLetterSizeArr[0]._id,
                        size: selectDefaultLetterSizeArr[0].size,
                      }
                    : LetterSizeDefault,
                stitchingType:
                  stitchingTypeId.length > 0
                    ? {
                        _id: stitchingTypeId[0]._id,
                        stitchingType: stitchingTypeId[0].stitchingType,
                        price: stitchingTypeId[0].price,
                        currency: stitchingTypeId[0].currency,
                      }
                    : StitchingTypeDefault,
                letterPlacement:
                  letterplacementId.length > 0
                    ? letterplacementId
                        .filter((i) => i.side === this.props.selectedSide)
                        .map((i: any) => {
                          return {
                            label: i.name,
                            value: {
                              x_axis: i.x_axis,
                              y_axis: i.y_axis,
                            },
                          };
                        })[0]
                    : LetterSizeDefault,
                // letterPlacement:
                //   letterplacementId.length > 0
                //     ? {
                //         label: letterplacementId[0].name,
                //         value: {
                //           x_axis: letterplacementId[0].x_axis,
                //           y_axis: letterplacementId[0].y_axis,
                //         },
                //       }
                //     : LetterSizeDefault,
                specialInstructions: "",
                isCenterApplique: true,
                heatSealedLetter: selectedProduct.heatSealedLetter,
                greekorNumber: "numbers",
                selectedGreekText: [
                  GREEK_ALPHABETS["Select Letter"],
                  GREEK_ALPHABETS["Select Letter"],
                  GREEK_ALPHABETS["Select Letter"],
                  GREEK_ALPHABETS["Select Letter"],
                  GREEK_ALPHABETS["Select Letter"],
                  GREEK_ALPHABETS["Select Letter"],
                ],
                selectedNumber: [
                  NUMBERS["Select Numbers"],
                  NUMBERS["Select Numbers"],
                  NUMBERS["Select Numbers"],
                ],
                foreGroundSettings: {
                  fill: "",
                  stroke: "",
                  material: defaultForeFGroundMaterial.value,
                },
                backgroundSettings: {
                  fill: "",
                  stroke: "",
                  material: defaultBackGroundMaterial.value,
                },
                enteredText: "",
                fontFamilyName:
                  fontStyleOptions.length > 0
                    ? {
                        label: fontStyleOptions[0].name,
                        value: fontStyleOptions[0]._id,
                      }
                    : FontFamilyDefault,
                customFiles: [],
                ..._props,
              },
            },
          },
          resolve
        );
      }
    });
  };

  setAlphabetType(alphabetType: ALPHABET_TYPES) {
    const { selectedSide } = this.props;
    this.resetForm(selectedSide, { alphabetType }).then(() => {
      //this.props.clearPreview().then(() => {
      /* console.log("this.props.cartData : ", this.props.cartInfo.cartData);
      console.log("this.props.selectedColor : ", this.props.selectedColor);
      console.log("this.props.selectedSizes : ", this.props.selectedSizes); */
      /* revalidate cart price calculation */
      if (
        typeof this.props.selectedColor !== "undefined" &&
        typeof this.props.selectedSizes !== "undefined"
      ) {
        const selectedProductColor: any =
          this.props.selectedProduct.colors.find(
            (i: any) => i.colorCode === this.props?.selectedColor?.colorCode
          );
        const c = selectedProductColor["size&quantity"]
          .filter((i: any) => {
            return (
              this.props.selectedSizes![i["sizeCode"]] !== 0 && {
                ...i,
              }
            );
          })
          .map((i: any) => {
            return {
              sizeCode: i["sizeCode"],
              sizeName: i["sizeName"],
              sizePrice: i["sizePrice"],
              quantity: this.props.selectedSizes![i["sizeCode"]],
            };
          });
        const allQuantitySelection = Object.values(this.props.selectedSizes);
        const isSomeQuantitySelected: any = allQuantitySelection.reduce(
          (quantity1: any, quantity2: any) => quantity1 + quantity2
        );
        const totalPriceByType = c.reduce(
          (acc: any, curr: any) => acc + curr.sizePrice * curr.quantity,
          0
        );
        const totalPrice =
          this.props.selectedProduct.price * isSomeQuantitySelected +
          totalPriceByType;

        //console.log("totalPrice : ", totalPrice);

        /* console.log("setAlphabetType - updatePriceToCart"); */
        this.props.updatePriceToCart({
          totalPrice: 0,
        });

        //if (this.props.cartInfo.cartData.totalPrice == 0) {
        /* add heat seal letter cost in cart price calculation */
        if (
          this.state.appliqueView[selectedSide].heatSealedLetter &&
          this.state.appliqueView[selectedSide].heatSealedLetter.isactive
        ) {
          /* console.log("heatSealedLetter - addPriceToCart"); */
          this.props.addPriceToCart({
            price: parseFloat(
              (this.state.appliqueView[selectedSide].heatSealedLetter as any)[
                "cost"
              ]
            ),
          });
        }
        //}
      }

      if (alphabetType === ALPHABET_TYPES.ROMAN) {
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.contentText,
            settingVal: {
              value: defaultEnglishText,
            },
          },
          DesignerViewStates.Applique
        );
        if(this.state.appliqueView[this.props.selectedSide].letterPlacement){
          const { value: letterPlacementValue } =
            this.state.appliqueView[this.props.selectedSide].letterPlacement;
          this.props.updatePreview(
            {
              settingName: PreviewSettingNames.letterPlacement,
              settingVal: {
                value: letterPlacementValue,
              },
            },
            DesignerViewStates.Applique
          );
        }
        const letterSize =
          this.state.appliqueView[this.props.selectedSide].letterSize;
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.letterSize,
            settingVal: {
              value: letterSize.size.split('"')[0],
            },
          },
          DesignerViewStates.Applique
        );

        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.customFilePreview,
            settingVal: {
              value: "",
            },
          },
          DesignerViewStates.Applique
        );

        const fontFamilyName =
          this.state.appliqueView[this.props.selectedSide].fontFamilyName;
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.fontFamily,
            settingVal: {
              value: fontFamilyName,
            },
          },
          DesignerViewStates.Applique
        );
      } else {
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.contentText,
            settingVal: {
              value: defaulGreekText,
            },
          },
          DesignerViewStates.Applique
        );

        if(this.state.appliqueView[this.props.selectedSide].letterPlacement){
          const { value: letterPlacementValue } =
            this.state.appliqueView[this.props.selectedSide].letterPlacement;
          this.props.updatePreview(
            {
              settingName: PreviewSettingNames.letterPlacement,
              settingVal: {
                value: letterPlacementValue,
              },
            },
            DesignerViewStates.Applique
          );
        }

        const letterSize =
          this.state.appliqueView[this.props.selectedSide].letterSize;
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.letterSize,
            settingVal: {
              value: letterSize.size.split('"')[0],
            },
          },
          DesignerViewStates.Applique
        );

        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.customFilePreview,
            settingVal: {
              value: "",
            },
          },
          DesignerViewStates.Applique
        );

        const fontFamilyName =
          this.state.appliqueView[this.props.selectedSide].fontFamilyName;
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.fontFamily,
            settingVal: {
              value: fontFamilyName,
            },
          },
          DesignerViewStates.Applique
        );
      }
      //});
    });
  }

  updateAppliqueView = (applique: any) => {
    this.setState({
      appliqueView: { ...this.state.appliqueView, ...applique },
    });
  };

  appliqueNext = () => {
    //for front
    let foregroundObjMaterial_front: any = {};
    let foregroundObjThread_front: any = {};
    let backgroundObjMaterial_front: any = {};
    let backgroundObjThread_front: any = {};
    let sashObjMaterial_front: any = {};
    let sashObjThread_front: any = {};

    const foreground_front = {
      foregroundMaterial: {},
      foregroundColor: {},
      threadColor: {},
    };

    const background_front = {
      backgroundMaterial: {},
      backgroundColor: {},
      threadColor: {},
    };

    const sash_front = {
      foregroundMaterial: {},
      foregroundColor: {},
      threadColor: {},
    };

    const { fill, material, stroke } =
      this.state.appliqueView["front"].foreGroundSettings;

    const { fill: fillSB, material: materialSB, stroke: strokeSB } =
      this.state.appliqueView["front"].sashSettings || {};

    const {
      fill: bgFill,
      material: bgMaterial,
      stroke: bgStroke,
    } = this.state.appliqueView["front"].backgroundSettings;

    for (let i in this.props.availableForegroundOptions) {
      if (this.props.availableForegroundOptions[i].value === material) {
        foregroundObjMaterial_front = this.props.availableForegroundOptions[i];
      }
    }

    for (let i in this.props.availableThreadOptions) {
      if (this.props.availableThreadOptions[i].value === stroke) {
        foregroundObjThread_front = this.props.availableThreadOptions[i];
      }
    }

    let foreGroundObjColor = foregroundObjMaterial_front.availableFills?.find(
      (i: any) => {
        return i.value == fill;
      }
    );

    for (let i in this.props.availableForegroundOptions) {
      if (this.props.availableForegroundOptions[i].value === materialSB) {
        sashObjMaterial_front = this.props.availableForegroundOptions[i];
      }
    }

    for (let i in this.props.availableThreadOptions) {
      if (this.props.availableThreadOptions[i].value === strokeSB) {
        sashObjThread_front = this.props.availableThreadOptions[i];
      }
    }

    let sashObjColor = foregroundObjMaterial_front.availableFills?.find(
      (i: any) => {
        return i.value == fillSB;
      }
    );

    for (let i in this.props.availableBackgroundOptions) {
      if (this.props.availableBackgroundOptions[i].value === bgMaterial) {
        backgroundObjMaterial_front = this.props.availableBackgroundOptions[i];
      }
    }

    for (let i in this.props.availableThreadOptions) {
      if (this.props.availableThreadOptions[i].value === bgStroke) {
        backgroundObjThread_front = this.props.availableThreadOptions[i];
      }
    }

    let backGroundObjColor = backgroundObjMaterial_front.availableFills?.find(
      (i: any) => {
        return i.value == bgFill;
      }
    );

    foreground_front["foregroundMaterial"] = {
      _id: foregroundObjMaterial_front?.value,
      name: foregroundObjMaterial_front?.label,
      value: foregroundObjMaterial_front?.value1,
      currency: foregroundObjMaterial_front?.currency,
      price: foregroundObjMaterial_front?.price,
    };

    foreground_front["foregroundColor"] = {
      foregroundMaterialId: foregroundObjMaterial_front?.value,
      name: foreGroundObjColor?.label,
      value: foreGroundObjColor?.value,
      currency: foreGroundObjColor?.currency,
      price: foreGroundObjColor?.price,
    };

    foreground_front["threadColor"] = {
      _id: foregroundObjThread_front?._id,
      name: foregroundObjThread_front?.name,
      value: foregroundObjThread_front?.value,
      price: foregroundObjThread_front?.price,
      currency: foregroundObjThread_front?.currency,
      active: foregroundObjThread_front?.active,
    };

    sash_front["foregroundMaterial"] = {
      _id: sashObjMaterial_front?.value,
      name: sashObjMaterial_front?.label,
      value: sashObjMaterial_front?.value1,
      currency: sashObjMaterial_front?.currency,
      price: sashObjMaterial_front?.price,
    };

    sash_front["foregroundColor"] = {
      foregroundMaterialId: sashObjMaterial_front?.value,
      name: sashObjColor?.label,
      value: sashObjColor?.value,
      currency: sashObjColor?.currency,
      price: sashObjColor?.price,
    };

    sash_front["threadColor"] = {
      _id: sashObjThread_front?._id,
      name: sashObjThread_front?.name,
      value: sashObjThread_front?.value,
      price: sashObjThread_front?.price,
      currency: sashObjThread_front?.currency,
      active: sashObjThread_front?.active,
    };

    background_front["backgroundMaterial"] = {
      _id: backgroundObjMaterial_front?.value,
      name: backgroundObjMaterial_front?.label,
      value: backgroundObjMaterial_front?.value1,
      currency: backgroundObjMaterial_front?.currency,
      price: backgroundObjMaterial_front?.price,
    };

    background_front["backgroundColor"] = {
      foregroundMaterialId: backgroundObjMaterial_front?.value,
      name: backGroundObjColor?.label,
      value: backGroundObjColor?.value,
      currency: backGroundObjColor?.currency,
      price: backGroundObjColor?.price,
    };

    background_front["threadColor"] = {
      _id: backgroundObjThread_front?._id,
      name: backgroundObjThread_front.name,
      value: backgroundObjThread_front.value,
      price: backgroundObjThread_front?.price,
      currency: backgroundObjThread_front?.currency,
      active: backgroundObjThread_front?.active,
    };

    //for back
    let foregroundObjMaterial_back: any = {};
    let foregroundObjThread_back: any = {};
    let backgroundObjMaterial_back: any = {};
    let backgroundObjThread_back: any = {};

    const foreground_back = {
      foregroundMaterial: {},
      foregroundColor: {},
      threadColor: {},
    };

    const background_back = {
      backgroundMaterial: {},
      backgroundColor: {},
      threadColor: {},
    };

    const {
      fill: fill_back,
      material: material_back,
      stroke: stroke_back,
    } = this.state.appliqueView["back"].foreGroundSettings;

    const {
      fill: bgFill_back,
      material: bgMaterial_back,
      stroke: bgStroke_back,
    } = this.state.appliqueView["back"].backgroundSettings;

    for (let i in this.props.availableForegroundOptions) {
      if (this.props.availableForegroundOptions[i].value === material_back) {
        foregroundObjMaterial_back = this.props.availableForegroundOptions[i];
      }
    }

    for (let i in this.props.availableThreadOptions) {
      if (this.props.availableThreadOptions[i].value === stroke_back) {
        foregroundObjThread_back = this.props.availableThreadOptions[i];
      }
    }

    let foreGroundObjColor_back =
      foregroundObjMaterial_back.availableFills.find((i: any) => {
        return i.value == fill_back;
      });

    for (let i in this.props.availableBackgroundOptions) {
      if (this.props.availableBackgroundOptions[i].value === bgMaterial_back) {
        backgroundObjMaterial_back = this.props.availableBackgroundOptions[i];
      }
    }

    for (let i in this.props.availableThreadOptions) {
      if (this.props.availableThreadOptions[i].value === bgStroke_back) {
        backgroundObjThread_back = this.props.availableThreadOptions[i];
      }
    }

    let backGroundObjColor_back =
      backgroundObjMaterial_back.availableFills.find((i: any) => {
        return i.value == bgFill_back;
      });

    foreground_back["foregroundMaterial"] = {
      _id: foregroundObjMaterial_back?.value,
      name: foregroundObjMaterial_back?.label,
      value: foregroundObjMaterial_back?.value1,
      currency: foregroundObjMaterial_back?.currency,
      price: foregroundObjMaterial_back?.price,
    };

    foreground_back["foregroundColor"] = {
      foregroundMaterialId: foregroundObjMaterial_back?.value,
      name: foreGroundObjColor_back?.label,
      value: foreGroundObjColor_back?.value,
      currency: foreGroundObjColor_back?.currency,
      price: foreGroundObjColor_back?.price,
    };

    foreground_back["threadColor"] = {
      _id: foregroundObjThread_back?._id,
      name: foregroundObjThread_back?.name,
      value: foregroundObjThread_back?.value,
      price: foregroundObjThread_back?.price,
      currency: foregroundObjThread_back?.currency,
      active: foregroundObjThread_back?.active,
    };

    background_back["backgroundMaterial"] = {
      _id: backgroundObjMaterial_back?.value,
      name: backgroundObjMaterial_back?.label,
      value: backgroundObjMaterial_back?.value1,
      currency: backgroundObjMaterial_back?.currency,
      price: backgroundObjMaterial_back?.price,
    };

    background_back["backgroundColor"] = {
      foregroundMaterialId: backgroundObjMaterial_back?.value,
      name: backGroundObjColor_back?.label,
      value: backGroundObjColor_back?.value,
      currency: backGroundObjColor_back?.currency,
      price: backGroundObjColor_back?.price,
    };

    background_back["threadColor"] = {
      _id: backgroundObjThread_back?._id,
      name: backgroundObjThread_back?.name,
      value: backgroundObjThread_back?.value,
      price: backgroundObjThread_back?.price,
      currency: backgroundObjThread_back?.currency,
      active: backgroundObjThread_back?.active,
    };

    const appliqueId = {
      front: {
        letterPlacement:
          this.state.appliqueView["front"].letterPlacement?.label,
        foreground: foreground_front,
        background: background_front,
        sash:sash_front,
        specialInstruction:
          this.state.appliqueView["front"]?.specialInstructions,
        letterSize: this.state.appliqueView["front"]?.letterSize,
        stitchingType: this.state.appliqueView["front"]?.stitchingType,
        heatSealedLetter: this.state.appliqueView["front"].heatSealedLetter,
        customFiles: this.state.appliqueView["front"].customFiles,
      },
      back: {
        letterPlacement: this.state.appliqueView["back"].letterPlacement?.label,
        foreground: foreground_back,
        background: background_back,
        specialInstruction:
          this.state.appliqueView["back"]?.specialInstructions,
        letterSize: this.state.appliqueView["back"]?.letterSize,
        stitchingType: this.state.appliqueView["back"]?.stitchingType,
        heatSealedLetter: this.state.appliqueView["back"].heatSealedLetter,
        customFiles: this.state.appliqueView["back"].customFiles,
      },
    };

    // if (this.state.appliqueView["front"].heatSealedLetter) {
    //   (appliqueId["front"] as any).heatSealedLetter = {
    //     name: true,
    //     price: 2,
    //     currency: "$",
    //   };
    // }

    // if (this.state.appliqueView["back"].heatSealedLetter) {
    //   (appliqueId["back"] as any).heatSealedLetter = {
    //     name: true,
    //     price: 2,
    //     currency: "$",
    //   };
    // }

    if (
      this.state.appliqueView["front"].selectedGreekText.join("").trim()
        .length > 0
    ) {
      (appliqueId["front"] as any).organization = this.state.appliqueView[
        "front"
      ].selectedGreekText
        .join("")
        .replace(/\s/g, "");
    }

    if (this.state.appliqueView["front"]?.enteredText.trim().length > 1) {
      (appliqueId["front"] as any).organization =
        this.state.appliqueView["front"]?.enteredText.trim();
    }

    if (
      this.state.appliqueView["back"].selectedGreekText.join("").trim().length >
      0
    ) {
      (appliqueId["back"] as any).organization = this.state.appliqueView[
        "back"
      ].selectedGreekText
        .join("")
        .replace(/\s/g, "");
    }

    if (this.state.appliqueView["back"]?.enteredText.trim().length > 1) {
      (appliqueId["back"] as any).organization =
        this.state.appliqueView["back"]?.enteredText.trim();
    }

    //console.log(appliqueId, "appliqueIdappliqueId", this.state.appliqueView);

    /* if (this.props.selectedApplique?.name == "Monogram") {
      let enteredText =
        this.state.appliqueView[this.props.selectedSide].enteredText.trim();
      if (enteredText.length < 1 || enteredText.length > 3) {
        alert(
          "Text length should be minimum 1 character and maximum 3 characters."
        );
        return false;
      }
    } */

    /* console.log(
      "this.props.cartInfo.cartData before applique total : ",
      this.props.cartInfo.cartData
    ); */
    if (this.props.selectedSide == "front") {
      /* console.log("appliqueNext - updateAppqeFrontPriceToCart"); */
      this.props.updateAppqeFrontPriceToCart({
        totalPrice: this.props.cartInfo.cartData.totalPrice,
      });
    } else {
      /* console.log("appliqueNext - updateAppqeBackPriceToCart"); */
      this.props.updateAppqeBackPriceToCart({
        totalPrice: this.props.cartInfo.cartData.totalPrice,
      });
    }
    /* console.log(
      "this.props.cartInfo.cartData after applique total : ",
      this.props.cartInfo.cartData
    ); */

    this.props.updateAppliqueRedux(this.state.appliqueView);
    this.props.onNext(appliqueId);
  };

  render() {
    const {
      updatePreview,
      onNext,
      onBack,
      availableBackgroundOptions,
      availableForegroundOptions,
      fontStyleOptions,
      isCustomizationOptionsLoaded,
      availableThreadOptions,
      selectedApplique,
      selectedSide,
      selectedProduct,
    } = this.props;

    const { appliqueView } = this.state;
    /*console.log(
      appliqueView,
      "appliqueViewappliqueView",
      selectedApplique,
      availableForegroundOptions,
      "availableForegroundOptions"
    );*/
    console.log("this.state.appliqueView : ", this.state.appliqueView);
    return (
      <>
        <SettingHeader>
          <TextBeta className="bold">{selectedSide.toLocaleUpperCase()==="back".toLocaleUpperCase()  && selectedApplique?.name.toLocaleUpperCase() ==="Twill Letters".toLocaleUpperCase() ?"TWILL NUMBERS / LETTERS" :selectedApplique?.name.toLocaleUpperCase() }</TextBeta>
          <p
            style={{
              fontSize: "18px",
              lineHeight: "22px",
              marginBottom: 0,
            }}
          >
            <b>{selectedProduct.name}</b>
          </p>
          <p
            style={{
              fontSize: "18px",
              lineHeight: "22px",
              marginBottom: "10px",
              marginTop: 0,
            }}
          >
            {selectedProduct.currency}
            <b>{selectedProduct.price} </b>
            (Base Price)
          </p>
          {/* <TinyText>
            Select your applique choices and preview your selection below.
            Twill/Material may show through if contrasting threads are used.
            Customized thread requires Double/Satin.
          </TinyText> */}
        </SettingHeader>
        {isCustomizationOptionsLoaded && selectedApplique && (
          <>
            <RadioContainer ref={this.alphabetTypeContainer}>
              {selectedApplique.greekLetter && (
                <>
                  <RadioButton
                    name="alphabetType"
                    label="Numbers / Greek Alphabates"
                    checked={
                      appliqueView[selectedSide].alphabetType ===
                      ALPHABET_TYPES.GREEK
                    }
                    onChange={() => {}}
                    onClick={() => this.setAlphabetType(ALPHABET_TYPES.GREEK)}
                  />
                  &nbsp;&nbsp; &nbsp;&nbsp;
                </>
              )}
              {selectedApplique.alphabetLetter && (
                <RadioButton
                  name="alphabetType"
                  label="English Alphabates"
                  checked={
                    appliqueView[selectedSide].alphabetType ===
                    ALPHABET_TYPES.ROMAN
                  }
                  onChange={() => {}}
                  onClick={(e: any) =>
                    this.setAlphabetType(ALPHABET_TYPES.ROMAN)
                  }
                />
              )}
            </RadioContainer>
            <div>
              {appliqueView[selectedSide].alphabetType ===
                ALPHABET_TYPES.ROMAN && (
                <EnglishTextSettings
                  alphabetTypeContainer={this.alphabetTypeContainer}
                  availableBackgroundOptions={availableBackgroundOptions}
                  availableForegroundOptions={availableForegroundOptions}
                  fontStyleOptions={fontStyleOptions}
                  updatePreview={updatePreview}
                  appliqueData={selectedApplique}
                  availableThreadOptions={availableThreadOptions}
                  selectedProduct={selectedProduct}
                  selectedSide={selectedSide}
                  appliqueView={appliqueView}
                  updateAppliqueView={this.updateAppliqueView}
                />
              )}
              {appliqueView[selectedSide].alphabetType ===
                ALPHABET_TYPES.GREEK && (
                <GreekTextSettings
                  appliqueData={selectedApplique}
                  alphabetTypeContainer={this.alphabetTypeContainer}
                  availableBackgroundOptions={availableBackgroundOptions}
                  availableForegroundOptions={availableForegroundOptions}
                  fontStyleOptions={fontStyleOptions}
                  updatePreview={updatePreview}
                  availableThreadOptions={availableThreadOptions}
                  selectedProduct={selectedProduct}
                  selectedSide={selectedSide}
                  appliqueView={appliqueView}
                  updateAppliqueView={this.updateAppliqueView}
                  resetForm={this.resetForm}
                  cartAddedData={this.props.cartAddedData}
                />
              )}
            </div>

            <Divider></Divider>
            <CtaContainer>
              <ClearStepBtn onClick={onBack}>Previous</ClearStepBtn>
              <NextpBtn onClick={this.appliqueNext}>Next</NextpBtn>
            </CtaContainer>
          </>
        )}
      </>
    );
  }
}

const RadioContainer = styled.div<{ ref:any }>`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const SettingHeader = styled.div`
  margin-bottom: ${marginXL};
  p {
    margin-top: ${marginS};
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #e3e3e3;
  margin: ${marginM} 0;
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ClearStepBtn = styled.button`
  margin-right: ${marginS};
  background: #dde3e6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
`;

const NextpBtn = styled.button`
  margin-right: ${marginS};
  background: ${primaryColor} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
  color: #ffffff;
`;

const AppliqueList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const AppliqueOptions = styled.div<{ isSelected: boolean }>`
  width: 170px;
  height: 170px;
  text-align: center;
  border: 1px solid #dde3e6;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 2px 5px #00000029;
  margin-bottom: ${marginM};
  position: relative;
  cursor: pointer;
  img {
    width: 120px;
    height: 150px;
  }
  p {
    background: ${(props) =>
      props.isSelected
        ? "#142F8F 0% 0% no-repeat padding-box;"
        : "#efefef 0% 0% no-repeat padding-box"};
    border-radius: 15px 15px 0px 0px;
    padding: ${paddingS} 0;
    width: 100%;
    position: absolute;
    bottom: -20px;
    color: ${(props) => (props.isSelected ? "#ffffff" : "#424447")};
  }
`;

const mapStateToProps = (state: IAppState) => {
  const {
    appliqueOptions: {
      availableBackgroundOptions,
      availableForegroundOptions,
      fontStyleOptions,
      isCustomizationOptionsLoaded,
      availableThreadOptions,
    },
    appliques,
    selectedApplique,
    appliqueView,
  } = state.applique;
  const {
    selectedProduct: { letterplacementId, stitchingTypeId, greekLetterSizeId, subCategoryId },
  } = state.products;

  const cartInfo = state.cart;
 
  let newAvailableForegroundOption = {
    "Select Any":{'label':"Select Foreground Material", 'value':"",currency:'$',
    price: 0,value1:"",availableFills:[]},
    ...availableForegroundOptions
  };
  let newAvailableBackgroundOptions = {
    "Select Any":{'label':"Select Background Material", 'value':"",currency:'$',
    price: 0,value1:"",availableFills:[]},
    ...availableBackgroundOptions
  };
  
  return {
    availableBackgroundOptions:newAvailableBackgroundOptions,
    availableForegroundOptions:newAvailableForegroundOption,
    fontStyleOptions,
    isCustomizationOptionsLoaded,
    availableThreadOptions,
    appliques,
    selectedApplique,
    subCategoryId,
    letterplacementId,
    stitchingTypeId,
    greekLetterSizeId : greekLetterSizeId.sort((a, b) => {
      if ( a._id < b._id ){
        return 1;
      }
      if ( a._id > b._id ){
        return -1;
      }
      return 0;
    }),
    appliqueView,
    cartInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAppliqueRedux: (appliqueView: AppliqueView) =>
      dispatch(updateAppliqueRedux(appliqueView)),
    addPriceToCart: (data: any) => dispatch(addPriceToCart(data)),
    updatePriceToCart: (data: any) => dispatch(updatePriceToCart(data)),
    updateAppqeFrontPriceToCart: (data: any) =>
      dispatch(updateAppqeFrontPriceToCart(data)),
    updateAppqeBackPriceToCart: (data: any) =>
      dispatch(updateAppqeBackPriceToCart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Applique);
