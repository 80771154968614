import styled from "styled-components";

//MainLayout Styles
export const MainLayoutSection = styled.div`
display: flex;
height:83vh

`;
//Side bar style starts here.
export const BarSection = styled.div`
  display: flex;
 margin-top: -18px;
`;

export const SidebarWrapper = styled.div`
  width: 200px;
  height: 100vh;
  background-color: rgb(20 47 142);;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
`;

export const BarHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

export const MenuList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const MenuItemWrapper = styled.li`
  margin-bottom: 10px;
  border-bottom: 0.25px solid;
`;

export const MenuButton = styled.button`
  width: 100%;
  padding: 10px;
  text-align: left;
  border:#fff;
  color: #fff;
  background: transparent;
    color: rgb(255, 255, 255);
  // background-color: rgb(253 254 255);
  //   color: rgb(10 10 10);
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #fff;
    color: rgb(10 10 10);
  }
`;
export const Link = styled.a`
text-decoration:none;`
export const MainContent = styled.div`
  flex: 1;
  padding: 20px;
`;
//Side bar style ends here.

//Page styles starts here.
export const PageContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  overflow:auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    display: none;
}
`;

export const MainHeading = styled.h1`
  font-size: 2.5rem;
  color: #333;
  text-align: center;
`;

export const SectionContainer = styled.div`
  margin-top: 20px;
`;

export const Section = styled.div<{margin?:string}>`
  margin-bottom: ${({ margin }) => (`${margin}`)};;
`;

export const SubHeading = styled.h2`
  font-size: 1.8rem;
  color: #555;
  margin-bottom: 8px;
`;
export const List = styled.li<{color?:string}>`
margin:0;
list-style-type: none;
color:${({ color }) => (color ? `${color}` : '#666')};
`;

export const Paragraph = styled.p<{color?:string}>`
  font-size: 1rem;
  text-align: justify;
  color:${({ color }) => (color ? `${color}` : '#666')};
  line-height: 1.5;
`;
//Page styles ends here.

// table related components for shipping rates. export
export const TableContainer = styled.div`
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
`;
export const TableHeader = styled.th`
  padding: 12px 15px;
  background-color: rgb(20, 47, 143);
  color: white;
  text-align: left;
`;
export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgb(221, 221, 221);
  }
`;
export const TableData = styled.td`
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;
export const FormContainer = styled.div`
  width: 40%;
`;
