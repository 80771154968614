import React, {
  useState,
  useEffect,
  ChangeEvent,
  ReactComponentElement,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ConfirmModal } from "@happeouikit/modals";

import {
  FormContainer,
  LeftSection,
  FormGroup,
  FormGroupColumn,
  RightSection,
  ButtonAlpha,
  ModalWrapper,
  ModalContainer,
  FormStyledGroup,
} from "./styled.component";
import { Input, Dropdown } from "@happeouikit/form-elements";
import { profileClient } from "../../ApiClients/ProfileClient";
import { toast } from "@happeouikit/toast";

import { ProfileResponseDatum, IProfileAddressState } from "../../models";

import {maskingPhone} from "../../Utils/common";

type AddressFormProps = {
  title: string;
  isOpen: boolean;
  close(): void;
  onDone(): void;
  disabled?: boolean;
  addressType: string;
  profileData: ProfileResponseDatum;
  selectedAddress?: IProfileAddressState & boolean;
  isDelete?: boolean;
};

export const AddressForm = ({
  title,
  isOpen,
  close,
  disabled,
  onDone,
  addressType,
  profileData,
  selectedAddress,
  isDelete,
}: AddressFormProps) => {
  const [fullName, setFullName] = useState("");
  const [ext, setExt] = useState("");
  const [mobile, setMobile] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  //const [landmark, setLandmark] = useState("");
  const [city, setCity] = useState("");
  const [address_type, setAddressType] = useState("");
  const [state, setState] = useState("");
  const [selectedState, setSelectedState] = useState({});
  const [pincode, setPincode] = useState("");
  const [orgName, setOrgName] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [error,setError] = useState("");
  const USStateList = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

  const dispatch = useDispatch();

  // const handleMobile = (event: ChangeEvent<HTMLInputElement>) => {
  //   let num = maskingPhone(event.target.value);
  //   setMobile(num);
  // }

  const handleMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formattedInput = e.target.value;

    // Remove all non-numeric characters (hyphens and spaces can be kept for formatting)
    formattedInput = formattedInput.replace(/\D/g, '');

    // Apply formatting: XXX-XXX-XXXX
    if (formattedInput.length > 6) {
      formattedInput = formattedInput.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3');
    } else if (formattedInput.length > 3) {
      formattedInput = formattedInput.replace(/(\d{3})(\d{0,3})/, '$1-$2');
    }

    // Ensure the phone number is only 10 digits long
    if (formattedInput.length <= 12) {
      setMobile(formattedInput); // Update state with the formatted value
      setError(''); // Clear any previous errors
    }
  };

  // Handle blur event (validate 10-digit phone number with correct formatting)
  const handlePhoneBlur = () => {
    const phonePattern = /^\d{3}-\d{3}-\d{4}$/; // Validate that the input is exactly in the format XXX-XXX-XXXX
    if (!phonePattern.test(mobile)) {
      setError('Invalid Phone Number');
    }
  };

console.log(selectedAddress?.address_type,"test asdsdas",address_type)
  useEffect(() => {
    if (selectedAddress) {
      setFullName(selectedAddress.fullName);
      setExt(selectedAddress.extension);
      setMobile(maskingPhone(selectedAddress.mobileNumber));
      
      setAddressLine1(selectedAddress.addressLine1);
      setAddressLine2(selectedAddress?.addressLine2?.trim());
      //setLandmark(selectedAddress.landmark);
      setCity(selectedAddress.city);
      setAddressType(selectedAddress?.address_type);
      setState(selectedAddress.state);
      setSelectedState({
        'label': `${selectedAddress.state}`,
        'value': selectedAddress.state
      });
      setPincode(selectedAddress.pinCode);
      setOrgName(selectedAddress.orgName != ""?selectedAddress.orgName:"");
      setSchoolName(selectedAddress.schoolName != ""?selectedAddress.schoolName:"");
    } else {
      setFullName("");
      setExt("");
      setMobile("");
      setAddressLine1("");
      setAddressLine2("");
      setCity("");
      setState("");
      setSelectedState("");
      setPincode("");
      setOrgName("");
      setSchoolName("");
    }
  }, [selectedAddress]);

  const handleAddAddressSave = async () => {
    const profileAddAddressResponse = await profileClient.addAddress(
      {
        userId: profileData._id,
        fullName: fullName,
        extension: ext,
        mobileNumber: mobile,
        addressLine1: addressLine1,
        addressLine2: addressLine2?addressLine2:' ',
        landmark: " ",
        city: city,
        state: state,
        pinCode: pincode,
        orgName: orgName,
        schoolName: schoolName,
        address_type: address_type? address_type:' ',
      },
      dispatch
    );
    console.log(profileAddAddressResponse);
    if (
      profileAddAddressResponse?.message != null &&
      profileAddAddressResponse?.error == null
    ) {
      toast.success({
        message: `${profileAddAddressResponse?.message}`,
        delay: 4000,
      });

      setFullName("");
      setExt("");
      setMobile("");
      setAddressLine1("");
      setAddressLine2("");
      setCity("");
      setState("");
      setSelectedState("");
      setPincode("");
      setOrgName("");
      setSchoolName("");

      onDone();
      close();
    }

    if (profileAddAddressResponse?.error != null) {
      toast.error({
        message: `${profileAddAddressResponse?.error}`,
        delay: 4000,
      });
    }
  };

  const handleUpdateAddressSave = async () => {
    if (selectedAddress) {
      const profileUpdateAddressResponse = await profileClient.updateAddress(
        {
          _id: selectedAddress._id,
          fullName: fullName,
          extension: ext,
          mobileNumber: mobile,
          addressLine1: addressLine1,
          addressLine2: addressLine2? addressLine2:' ',
          landmark: " ",
          city: city,
          state: state,
          pinCode: pincode,
          orgName: orgName,
          schoolName: schoolName,
          address_type: address_type?address_type:' ',
        },
        dispatch
      );
      console.log(profileUpdateAddressResponse);
      if (
        profileUpdateAddressResponse?.message != null &&
        profileUpdateAddressResponse?.error == null
      ) {
        toast.success({
          message: `${profileUpdateAddressResponse?.message.replace("Shipping ", "")}`,
          delay: 4000,
        });

        onDone();
        close();
      }

      if (profileUpdateAddressResponse?.error != null) {
        toast.error({
          message: `${profileUpdateAddressResponse?.error}`,
          delay: 4000,
        });
      }
    }
  };

  const handleDeleteAddress = async () => {
    if (selectedAddress && isDelete) {
      const profileDeleteAddressResponse = await profileClient.deleteAddress(
        {
          _id: selectedAddress._id,
          fullName: fullName,
          extension: ext,
          mobileNumber: mobile,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          landmark: " ",
          city: city,
          state: state,
          pinCode: pincode,
          orgName: orgName,
          schoolName: schoolName,
          address_type: addressType,
        },
        dispatch
      );
      console.log(profileDeleteAddressResponse);
      if (
        profileDeleteAddressResponse?.message != null &&
        profileDeleteAddressResponse?.error == null
      ) {
        toast.success({
          message: `${profileDeleteAddressResponse?.message}`,
          delay: 4000,
        });

        onDone();
        close();
      }

      if (profileDeleteAddressResponse?.error != null) {
        toast.error({
          message: `${profileDeleteAddressResponse?.error}`,
          delay: 4000,
        });
      }
    }
  };

  const checkValue = (str: string, max: number): string => {
    if (str.charAt(0) !== '0' || str === '00') {
      let num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
    }
    return str;
  };
  
  const dobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    let formattedInput = input;
    if (/\D\/$/.test(input)) formattedInput = input.substr(0, input.length - 3);
    const values = formattedInput.split('/').map(v => v.replace(/\D/g, ''));
    if (values[0]) values[0] = checkValue(values[0], 12); 
    if (values[1]) values[1] = checkValue(values[1], 31); 
    const output = values.map((v, i) => (v.length === 2 && i < 2 ? v + ' / ' : v)).join('').substring(0, 7);
    setExt(output);
  };
  
  const dobBlur = () => {
    const values = ext.split('/').map(v => v.replace(/\D/g, ''));
    let output = '';
  
    if (values.length >= 2) {
      const day = parseInt(values[0]); 
      const month = parseInt(values[1]); 
      if (!isNaN(day) && !isNaN(month) && day <= 31 && month <= 12) {
        output = [day, month].map(v => (v < 10 ? '0' + v : v)).join(' / ');
      }
    }
    setExt(output);
  };
  
  return (
    <>
      <Modal
        isOpen={isOpen}
        close={close}
        headerText={<><span style={{fontFamily: "helvetica sans-serif !important;", fontSize: "x-large"}}>{title}</span></> || ""}
        width={"984px"}
        disabled={disabled}
        okText={"Save"}
        okCallback={() => {
          !selectedAddress ? handleAddAddressSave() : handleUpdateAddressSave();
        }}
        cancelText={"Cancel"}
        overlayStyle={{ zIndex: "1061" }}
      >
        <ModalWrapper>
              <p style={{color: "red"}}> * indicates required fields</p>
          <FormContainer style={{'display':'flex', 'justifyContent': 'space-between'}}>
              <FormGroup>
                <Input
                  placeholder="First and Last Name"
                  autoComplete="off"
                  value={fullName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFullName(e.target.value)
                  }
                  label="Full Name (First and Last Name)*"
                  style={{width:"200%"}}
                />
              </FormGroup>
              <FormStyledGroup style={{marginLeft:"23%"}}>
                  {error?<p style={{color: "red"}} >{error}</p>:''}
                <Input
                  placeholder="Phone Number"
                  autoComplete="off"
                  value={mobile}
                  onChange={handleMobile}
                  onBlur={handlePhoneBlur}
                  label="Phone Number*"
                />
              </FormStyledGroup>
              {/* <FormGroup>
                <Input
                  placeholder="Month / Day"
                  autoComplete="off"
                  value={ext}
                  onChange={dobChange}
                  onBlur={dobBlur}
                  label="Date of Birth (MM / DD)*"
                />
              </FormGroup> */}
              
          </FormContainer>
          <ModalContainer>
            <LeftSection>
              <FormGroup >
                <Input
                  placeholder="Address type"
                  autoComplete="off"
                  value={address_type}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAddressType(e.target.value)
                  }
                  label="Address Nickname (Ex. Home)*"
                  style={{width:"100%"}}
                />
              </FormGroup>
              
              {/* <FormGroup>
                <Input
                  placeholder="Landmark"
                  autoComplete="off"
                  value={landmark}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setLandmark(e.target.value)
                  }
                  label="Landmark"
                />
              </FormGroup> */}
              
            </LeftSection>
            <RightSection>
              <FormGroup>
                <Input
                  placeholder="Address Line 1"
                  autoComplete="off"
                  value={addressLine1}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAddressLine1(e.target.value)
                  }
                  label="Address Line 1*"
                />
              </FormGroup>
              
              
            </RightSection>
            <br />
            
          </ModalContainer>
          <FormContainer style={{'display':'flex', 'justifyContent': 'space-between'}}>
            <FormGroup>
                <Input
                  placeholder="Address Line 2"
                  autoComplete="off"
                  value={addressLine2}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAddressLine2(e.target.value)
                  }
                  label="Address Line 2"
                />
            </FormGroup>
            <FormGroup  style={{marginLeft:"5%"}} >
              <Input
                placeholder="City"
                autoComplete="off"
                value={city}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCity(e.target.value)
                }
                label="City*"
                style={{width:"100%"}}
              />
            </FormGroup>
            <FormGroup style={{marginLeft:"5%"}}>
            {/* <Input
              placeholder="State"
              autoComplete="off"
              value={state}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setState(e.target.value)
              }
              label="State"
            /> */}
            
              <Dropdown
                label="State*"
                options={USStateList?.map(
                  (code:string) => ({
                    label: `${code}`,
                    value: code
                  })
                )}
                onChange={(addressCode:any) => {
                  setSelectedState(addressCode);
                  setState(addressCode.value);
                }}
                value={selectedState}
              />
            </FormGroup>
            
            <FormGroup>
              <Input
                placeholder="ZIP Code"
                autoComplete="off"
                value={pincode}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPincode(e.target.value)
                }
                label="ZIP Code*"
              />
            </FormGroup>
          </FormContainer>
          <ModalContainer>
            <LeftSection>
            <FormGroup >
              <Input
                placeholder="Organization Name"
                autoComplete="off"
                value={orgName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setOrgName(e.target.value)
                }
                label="Organization Name*"
                
              />
            </FormGroup>
            </LeftSection>
            <RightSection>
            <FormGroup>
              <Input
                placeholder="School Name"
                autoComplete="off"
                value={schoolName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSchoolName(e.target.value)
                }
                label="School Name*"
                
              />
            </FormGroup>
            </RightSection>
          </ModalContainer>
        </ModalWrapper>
      </Modal>

      {isDelete && (
        <ConfirmModal
          isOpen={isDelete}
          close={() => {
            onDone();
            close();
          }}
          header={"Deleting address"}
          bodyText={"Are you sure you want to delete the address?"}
          okCb={handleDeleteAddress}
          okText={"Delete"}
          state={"alert"}
        />
      )}
    </>
  );
};
