import React from "react";
import styled from "styled-components";
import { paddingL } from "../../constants";
import {
  FloaterIcon1,
  FloaterIcon2,
  FloaterIcon3,
  FloaterIcon4,
  FloaterIcon5,
} from "../../components";
import { DesignerViewStates } from "../../models";
import { Tooltip } from "@happeouikit/tooltip";

export const FloaterMenu = ({ isSubmited, changeViewState, designerViewState }: any) => {
  return (
    <Floater>
      <FloatingMenu id="preview_float_menu">
        <FloaterButton 
          
          onClick={() => changeViewState(DesignerViewStates.ColorAndQuantity)}
          selected={designerViewState == DesignerViewStates.ColorAndQuantity?true:false}
        >
          <Tooltip
            id="Color_Quantity_tooltip"
            content="Color & Quantity section"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />
          <FloaterIcon1 />
        </FloaterButton>
        <FloaterButton
          onClick={() =>
            changeViewState(DesignerViewStates.AppliqueTypeSelector)
          }
          selected={designerViewState == DesignerViewStates.AppliqueTypeSelector?true:false}
        >
          <Tooltip
            id="Choose_Style_tooltip"
            content="Applique Type section"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />
          <FloaterIcon2 />
        </FloaterButton>
        <FloaterButton
          onClick={() => changeViewState(DesignerViewStates.Applique)}
          selected={designerViewState == DesignerViewStates.Applique?true:false}
        >
          <Tooltip
            id="Applique_tooltip"
            content="Applique section"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />
          <FloaterIcon3 />
        </FloaterButton>
        <FloaterButton
          onClick={() => changeViewState(DesignerViewStates.EmboideryLocations)}
          selected={designerViewState == DesignerViewStates.EmboideryLocations?true:false}
        >
          <Tooltip
            id="Embroidery_tooltip"
            content="Embroidery Location section"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />
          <FloaterIcon4 />
        </FloaterButton>
        <FloaterButton 
          onClick={() => {
            if(isSubmited){
              changeViewState(DesignerViewStates.Cart);
            }
          }}
          enabled={isSubmited}
          name={DesignerViewStates.Cart}
          selected={designerViewState == DesignerViewStates.Cart?true:false}
        >
          <Tooltip
            id="Cart_tooltip"
            content="Cart section"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />
          <FloaterIcon5 />
        </FloaterButton>
      </FloatingMenu>
    </Floater>
  );
};

const Floater = styled.div`
  height: 100%;
  width: 65px;
  position: relative;
  background-color: transparent;
`;

const FloatingMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  right: -25%;
  transform: translate(0, -50%);
  width: 60px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  cursor: pointer;
  border-color: transparent;
`;

const FloaterButton = styled.button<{ selected?:boolean, enabled?:boolean, name?:string }>`
  border: none;
  background-color: transparent;
  padding: ${paddingL} 0;
  svg {
    path {
      fill: lightgray;
      stroke: ${(props) => props.selected?`blue`:`${!props.enabled && props.name == DesignerViewStates.Cart?'lightgray':'gray'}`};
    }
  }
  
`;
