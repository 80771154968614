import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dropdown } from "@happeouikit/form-elements";

import Tab from "./Tab";

const OrderInfo = ({ orderDataItem }) => {
  const [tab, setTab] = useState(1);
  const [appliqueView, setAppliqueView] = useState({
    label: "Front",
    value: "front",
  });

  const [embroidaryLocations, setEmbroidaryLocations] = useState([]);
  const [embroidaryLocationView, setEmbroidaryLocationView] = useState({});

  useEffect(() => {
    if (orderDataItem) {
      if (orderDataItem.customizedProducts.embroidaryId.length > 0) {
        const newA = orderDataItem.customizedProducts.embroidaryId.map((i) => {
          return {
            label: i.name,
            value: i.name,
          };
        });
        setEmbroidaryLocations(newA);
        setEmbroidaryLocationView(newA[0]);
      }
    }
  }, [orderDataItem]);

  const renderEm = orderDataItem.customizedProducts.embroidaryId.find((i) => {
    return i.name === embroidaryLocationView.value;
  });

  console.log(
    renderEm,
    "renderEm",
    orderDataItem,
    orderDataItem.customizedProducts.appliqueId[appliqueView.value]
      .stitchingType
  );

  

  const formatPrice = (price) => {
    let formattedPrice = parseFloat(price).toFixed(2);
    return isNaN(price)?"0.00":formattedPrice;
  }

  return (
    <Container>
      <Flex>
        <Title></Title>
        <Tab onChange={setTab} tab={tab} />
      </Flex>
      {tab === 1 && (
        <Flex>
          <Table>
            <tbody border="collapse">
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Name:
                </td>

                <td style={{ color: "#888383" }}>
                  {orderDataItem.productId.name && orderDataItem.productId.name}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Description:
                </td>

                <td style={{ color: "#888383" }}>
                  {orderDataItem.productId.description &&
                    orderDataItem.productId.description}
                </td>
                <td></td>
                <td></td>
                {/*orderDataItem.customizedProducts.previewImages && (
                  <td rowspan="8">
                    {orderDataItem.customizedProducts.previewImages
                      .Applique_front && (
                      <>
                        <h6>{`Applique Front`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .Applique_front
                          }
                          alt={`Applique_front preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                    {orderDataItem.customizedProducts.previewImages
                      .Applique_back && (
                      <>
                        <h6>{`Applique Back`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .Applique_back
                          }
                          alt={`Applique_back preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                    {orderDataItem.customizedProducts.previewImages
                      .EmboideryLocations_front && (
                      <>
                        <h6>{`Emboidery Front`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .EmboideryLocations_front
                          }
                          alt={`EmboideryLocations_front preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                    {orderDataItem.customizedProducts.previewImages
                      .EmboideryLocations_back && (
                      <>
                        <h6>{`Emboidery Back`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .EmboideryLocations_back
                          }
                          alt={`EmboideryLocations_back preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                    {orderDataItem.customizedProducts.previewImages
                      .EmboideryLocations_arm_right && (
                      <>
                        <h6>{`Emboidery Right Arm`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .EmboideryLocations_arm_right
                          }
                          alt={`EmboideryLocations_arm_right preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                    {orderDataItem.customizedProducts.previewImages
                      .EmboideryLocations_arm_left && (
                      <>
                        <h6>{`Emboidery Left Arm`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .EmboideryLocations_arm_left
                          }
                          alt={`EmboideryLocations_arm_left preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                  </td>
                        )*/}
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Item:
                </td>

                <td style={{ color: "#888383" }}>
                  {orderDataItem.productId.item && orderDataItem.productId.item}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Includes:
                </td>

                <td style={{ color: "#888383" }}>
                  {orderDataItem.productId.includes &&
                    orderDataItem.productId.includes}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Color:
                </td>

                <td style={{ color: "#888383" }}>
                  {orderDataItem.customizedProducts?.colors &&
                    orderDataItem.customizedProducts?.colors?.colorName
                      .charAt(0)
                      .toUpperCase() +
                      orderDataItem.customizedProducts?.colors?.colorName.slice(
                        1
                      )}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Base Price:
                </td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.totalQuantity} item(s)
                </td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.totalQuantity || 0} x {orderDataItem.currency}
                  {formatPrice(orderDataItem?.productId?.price)}
                </td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.currency}
                  {formatPrice(orderDataItem.totalQuantity * orderDataItem?.productId?.price)}
                </td>
              </tr>
              <tr>
                <td>Qty & Size:</td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.customizedProducts?.colors &&
                    orderDataItem.customizedProducts?.colors[
                      "size&quantity"
                    ].map((i) => (
                      <div>
                        {i.quantity} - {i.sizeCode}
                      </div>
                    ))}
                </td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.customizedProducts?.colors &&
                    orderDataItem.customizedProducts.colors[
                      "size&quantity"
                    ].map((i) => (
                      <div>
                        {i.quantity} x {orderDataItem.currency} {formatPrice(i.sizePrice)}
                      </div>
                    ))}
                </td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.customizedProducts?.colors &&
                    orderDataItem.customizedProducts.colors[
                      "size&quantity"
                    ].map((i) => (
                      <div>
                        {orderDataItem.currency} {formatPrice(i.quantity * i.sizePrice)}
                      </div>
                    ))}
                </td>
              </tr>
            </tbody>
          </Table>
        </Flex>
      )}
      {tab === 2 && (
        <>
          <Flex>
            <h4>Applique</h4>
            <Dropdown
              options={[
                { label: "Front", value: "front" },
                { label: "Back", value: "back" },
              ]}
              onChange={(val) => {
                setAppliqueView(val);
              }}
              value={appliqueView}
            />
          </Flex>
          <Flex>
            <Table>
              <tbody>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].stitchingType && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Stitching:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.stitchingType
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {orderDataItem.totalQuantity} x{" "}
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.currency
                        }
                        {
                          formatPrice(orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.currency
                        }
                        {formatPrice(orderDataItem.totalQuantity *
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.price)}
                      </td>
                      {orderDataItem.customizedProducts.previewImages ? (
                        <th colspan="1">Preview</th>
                      ) : (
                        <></>
                      )}
                      {orderDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ].customFiles ? (
                        <th colspan="2">Custom Upload</th>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      fontSize: "1em",
                      textTransform: "uppercase",
                    }}
                  >
                    Organization:
                  </td>
                  <td style={{ color: "#888383" }}>
                    {
                      orderDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ]?.organization
                    }
                  </td>
                  <td></td>
                  <td></td>
                  {orderDataItem.customizedProducts.previewImages && (
                    <td rowspan="8">
                      {appliqueView.value == "front" &&
                        orderDataItem.customizedProducts.previewImages
                          .Applique_front && (
                          <>
                            <img
                              src={
                                orderDataItem.customizedProducts.previewImages
                                  .Applique_front
                              }
                              alt={`Applique_front preview`}
                            />
                          </>
                        )}

                      {appliqueView.value == "back" &&
                        orderDataItem.customizedProducts.previewImages
                          .Applique_back && (
                          <>
                            <img
                              src={
                                orderDataItem.customizedProducts.previewImages
                                  .Applique_back
                              }
                              alt={`Applique_back preview`}
                            />
                          </>
                        )}
                    </td>
                  )}
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].customFiles && (
                    <td rowspan="8">
                      {orderDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ].customFiles.map((imgObj) => (
                        <img
                          src={imgObj.content}
                          alt={imgObj.name}
                          width={"250"}
                          height={"100%"}
                        />
                      ))}
                    </td>
                  )}
                </tr>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].letterSize && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Letter Size:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.letterSize?.size
                        }
                      </td>
                      <td></td>
                      <td></td>
                    </>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      fontSize: "1em",
                      textTransform: "uppercase",
                    }}
                  >
                    Placement:
                  </td>
                  <td style={{ color: "#888383" }}>
                    {
                      orderDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ]?.letterPlacement
                    }
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].foreground.foregroundMaterial && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Foreground:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.name
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {orderDataItem.totalQuantity} x{" "}
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.currency
                        }
                        {
                          formatPrice(orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.currency
                        }
                        {formatPrice(orderDataItem.totalQuantity *
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.price)}
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].foreground.threadColor && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        FG Thread:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.name
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {orderDataItem.totalQuantity} x{" "}
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.currency
                        }
                        {
                          formatPrice(orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.currency
                        }
                        {formatPrice(orderDataItem.totalQuantity *
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.price)}
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].background.backgroundMaterial && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Background:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.name
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {orderDataItem.totalQuantity} x{" "}
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.currency
                        }
                        {
                          formatPrice(orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.currency
                        }
                        {formatPrice(orderDataItem.totalQuantity *
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.price)}
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].background.threadColor && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        BG Thread:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.name
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {orderDataItem.totalQuantity} x{" "}
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.currency
                        }
                        {
                          formatPrice(orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.price)
                        }
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.currency
                        }
                        {formatPrice(orderDataItem.totalQuantity *
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.price)}
                      </td>
                    </>
                  )}
                </tr>
                {orderDataItem.customizedProducts.appliqueId[appliqueView.value]
                  .heatSealedLetter && (
                  <tr>
                    <td
                      style={{
                        fontSize: "1em",
                        textTransform: "uppercase",
                      }}
                    >
                      Heat Sealed:
                    </td>
                    <td style={{ color: "#888383" }}>
                      {
                        orderDataItem.customizedProducts.appliqueId[
                          appliqueView.value
                        ]?.heatSealedLetter?.name
                      }
                    </td>
                    <td style={{ color: "#888383" }}>
                      {orderDataItem.totalQuantity} x{" "}
                      {orderDataItem.currency}
                      {
                        formatPrice(orderDataItem.customizedProducts.appliqueId[
                          appliqueView.value
                        ]?.heatSealedLetter?.cost)
                      }
                    </td>
                    <td style={{ color: "#888383" }}>
                      {orderDataItem.currency}
                      {formatPrice(orderDataItem.totalQuantity *
                        orderDataItem.customizedProducts.appliqueId[
                          appliqueView.value
                        ]?.heatSealedLetter?.cost)}
                    </td>
                  </tr>
                )}

                <tr>
                  <td
                    style={{
                      fontSize: "1em",
                      textTransform: "uppercase",
                    }}
                  >
                    Special Instruction's:
                  </td>
                  <td style={{ color: "#888383" }}>
                    {
                      orderDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ]?.specialInstruction
                    }
                  </td>
                </tr>
              </tbody>
            </Table>
          </Flex>
        </>
      )}
      {tab === 3 && (
        <>
          <Flex>
            <h4>
              <strong>Embroidary Locations</strong>
            </h4>
            <Dropdown
              options={embroidaryLocations}
              onChange={(val) => {
                console.log(val, "valval");
                setEmbroidaryLocationView(val);
              }}
              value={embroidaryLocationView}
            />
          </Flex>
          <Flex>
            <Table>
              <tbody>
                {renderEm &&
                  (renderEm.name !==
                  "Crest and Images ( Price varies with size and design )" ? (
                    <>
                      <tr>
                        <td></td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td
                            style={{
                              color: "#888383",
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }}
                          >
                            {k}
                          </td>
                        ))}
                        <td></td>
                        <td></td>
                        {orderDataItem.customizedProducts.previewImages ? (
                        <th colspan="1">Preview</th>
                      ) : (
                        <></>
                      )}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Base Price:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => {
                          const sel = renderEm.basePrice.find(
                            (l) => k === l.name
                          );
                          return (
                            <>
                              <td style={{ color: "#888383" }}>
                                {orderDataItem.totalQuantity}
                                {"x"}
                                {sel.currency}
                                {formatPrice(sel.price)}
                              </td>
                            </>
                          );
                        })}
                        <td></td>
                        <td></td>
                        {orderDataItem.customizedProducts.previewImages && (
                          <td rowspan="8">
                            {embroidaryLocationView.value == "Front" &&
                              orderDataItem.customizedProducts.previewImages
                                .EmboideryLocations_front && (
                                <>
                                  <img
                                    src={
                                      orderDataItem.customizedProducts.previewImages
                                        .EmboideryLocations_front
                                    }
                                    alt={`Emboidery front preview`}
                                  />
                                </>
                              )}

                            {embroidaryLocationView.value == "Back" &&
                              orderDataItem.customizedProducts.previewImages
                                .EmboideryLocations_back && (
                                <>
                                  <img
                                    src={
                                      orderDataItem.customizedProducts.previewImages
                                        .EmboideryLocations_back
                                    }
                                    alt={`Emboidery back preview`}
                                  />
                                </>
                              )}
                              {embroidaryLocationView.value == "Right Arm" &&
                              orderDataItem.customizedProducts.previewImages
                                .EmboideryLocations_arm_right && (
                                <>
                                  <img
                                    src={
                                      orderDataItem.customizedProducts.previewImages
                                        .EmboideryLocations_arm_right
                                    }
                                    alt={`Emboidery right arm preview`}
                                  />
                                </>
                              )}

                            {embroidaryLocationView.value == "Left Arm" &&
                              orderDataItem.customizedProducts.previewImages
                                .EmboideryLocations_arm_left && (
                                <>
                                  <img
                                    src={
                                      orderDataItem.customizedProducts.previewImages
                                        .EmboideryLocations_arm_left
                                    }
                                    alt={`Emboidery left arm preview`}
                                  />
                                </>
                              )}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: "1em",
                            textTransform: "uppercase",
                          }}
                        >
                          <b>Font Style:</b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k]?.fontStyle?.name}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Font Size:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k]?.fontSize?.name}{" "}
                            {renderEm.keys[k]?.fontSize?.currency}
                            {formatPrice(renderEm.keys[k]?.fontSize?.price)}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Thread Color:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k]?.threadColor?.name}{" "}
                            {renderEm.keys[k]?.threadColor?.currency}
                            {formatPrice(renderEm.keys[k]?.threadColor?.price)}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Text Line 1:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k]?.textLine1?.value}
                          </td>
                        ))}
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td></td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td>{k}</td>
                        ))}
                        <td></td>
                        <td></td>
                        {orderDataItem.customizedProducts.previewImages ? (
                          <th colspan="1">Preview</th>
                        ) : (
                          <></>
                        )}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Base Price:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => {
                          const sel = renderEm.basePrice.find(
                            (l) => k === l.name
                          );
                          return (
                            <>
                              <td style={{ color: "#888383" }}>
                                {orderDataItem.totalQuantity}
                                {"x"}
                                {sel.currency}
                                {formatPrice(sel.price)}
                              </td>
                            </>
                          );
                        })}
                        <td></td>
                        <td></td>
                        {orderDataItem.customizedProducts.previewImages && (
                          <td rowspan="8">
                            {embroidaryLocationView.value == "Crest and Images(price varies with size and design)" &&
                              orderDataItem.customizedProducts.previewImages
                                .EmboideryLocations_front && (
                                <>
                                  <img
                                    src={
                                      orderDataItem.customizedProducts.previewImages
                                        .EmboideryLocations_front
                                    }
                                    alt={`Emboidery front preview`}
                                  />
                                </>
                              )}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Embroidary Location:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].embroidaryLocation.name}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Image Size:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].imageSize.name}{" "}
                          </td>
                        ))}
                      </tr>
                      {/* <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Text
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].text.value}{" "}
                          </td>
                        ))}
                      </tr> */}
                    </>
                  ))}
              </tbody>
            </Table>
          </Flex>
        </>
      )}
    </Container>
  );
};

export default OrderInfo;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px dotted black;
`;

const Container = styled.div`
  margin: 15px 0 15px 0;
`;

const Title = styled.div`
  max-width: 50%;
  text-align: left;
  font: normal normal normal 16px/20px Source Sans Pro;
  letter-spacing: 0.51px;
  color: #424447;
  opacity: 1;
`;

const Table = styled.table`
  all: unset;
  margin-top: 10px;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    padding: 8px;
    width: 22%;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    /*background-color: #04aa6d;
    color: white;*/
  }
`;
