import { shippingRates } from "./StaticData.Constant";
import * as Tag from "./Styled.component";

interface ShippingRate {
  ordervalue: string;
  standard: string;
  priority: string;
  express: string;
}

const ShippingRatesTable: React.FC = () => {
    return (
      <Tag.TableContainer>
        <Tag.Table>
          <thead>
            <tr>
              <Tag.TableHeader>Order Value</Tag.TableHeader>
              <Tag.TableHeader>Standard 7-10 days</Tag.TableHeader>
              <Tag.TableHeader>Priority 2-5 days</Tag.TableHeader>
              <Tag.TableHeader>Express 1-3 days</Tag.TableHeader>
            </tr>
          </thead>
          <tbody>
            {shippingRates.map((rate, index) => (
              <Tag.TableRow key={index}>
                <Tag.TableData>{rate.ordervalue}</Tag.TableData>
                <Tag.TableData>{rate.standard}</Tag.TableData>
                <Tag.TableData>{rate.priority}</Tag.TableData>
                <Tag.TableData>{rate.express}</Tag.TableData>
              </Tag.TableRow>
            ))}
          </tbody>
        </Tag.Table>
      </Tag.TableContainer>
    );
  };
  
  export default ShippingRatesTable;