import React from "react";
import styled from "styled-components";
import { designWindowHeight, designWindowHeightValue, GreekLetterSVGs } from "../../constants";
import {
  DesignerViewStates,
  IFontStyle,
  IMaterialFillStrokeBackGround,
  IMaterialFillStrokeForeGround,
  PreviewSettingNames,
  UpdatePreviewParams,
  GREEK_ALPHABETS
} from "../../models";
import { Validation, isNumberLetters, isGreekLetters, generateGreekLetterSVG, generatePreviewSVG } from "../../Utils";
import RenderEmbroidaryPreview from "./RenderEmbroidaryPreview";
import {
  IOneProductV2
} from "../../models/ApiResponses/IGetProductResponseV2";
import Parser from 'html-react-parser';
import { isConstTypeReference } from "typescript";

interface PreviewProps {
  designAtrributes: UpdatePreviewParams[];
  image: string;
  fontOptions: IFontStyle[];
  updatePreview(params: UpdatePreviewParams, key: string): void;
  selectedSide: string;
  svgRef: any;
  selectedProduct: IOneProductV2;
  selectedApplique: any;
}

interface PreviewState {
  appliqueBound: any;
  letterCount: number;
  letterPlacement: any;
}

class Preview extends React.Component<PreviewProps, PreviewState> {
  private appliqueView: React.RefObject<any>;

  constructor(props: PreviewProps) {
    super(props);
    this.parsePreviewParams = this.parsePreviewParams.bind(this);
    this.appliqueView = React.createRef();

    this.state = {
      appliqueBound: {},
      letterCount: 0,
      letterPlacement: {}
    };
  }

  componentDidUpdate(
    prevProps: Readonly<PreviewProps>,
    prevState: Readonly<PreviewState>,
    snapshot?: any
  ): void {
    if (prevProps.selectedSide !== this.props.selectedSide) {
      if(this.appliqueView.current){
        this.setState({
          appliqueBound: this.appliqueView.current.getBoundingClientRect()
        });
      }
    }
  }

  parsePreviewParams(designAtrributes: any) {
    const previewValue: Record<string, any> = {
      // [PreviewSettingNames.letterPlacement]: `${
      //   designWindowHeightValue * 0.25
      // }in`,
      [PreviewSettingNames.letterPlacement]: { x_axis: 40, y_axis: 30 }, //{ x_axis: 0, y_axis: 0 }
      [PreviewSettingNames.contentText]: "",
      [PreviewSettingNames.clothColor]: "",
      [PreviewSettingNames.letterSize]: `${3.5 / designWindowHeightValue}in`,
      [PreviewSettingNames.fontFamily]: "inherit",
      [PreviewSettingNames.sashFillStrokeMarker]: null,
      [PreviewSettingNames.backGroundFillStrokeMarker]: null,
      [PreviewSettingNames.foreGroundFillStrokeMarker]: null,
      [PreviewSettingNames.design]: "",
      [PreviewSettingNames.imageSize]: "",
      [PreviewSettingNames.embroidaryLocation]: "",
      [PreviewSettingNames.customFilePreview]: "",
    };

    console.log(designAtrributes, "======>designAtrributes");

    if (designAtrributes && designAtrributes.length) {
      designAtrributes.forEach(({ settingName, settingVal }: any) => {
        switch (settingName) {
          case PreviewSettingNames.letterPlacement:
            previewValue[PreviewSettingNames.letterPlacement] = {
              x_axis: settingVal.value.x_axis,
              y_axis: settingVal.value.y_axis,
            };
            break;
          case PreviewSettingNames.contentText:
            previewValue[PreviewSettingNames.contentText] = settingVal.value
              .toString()
              .trim();
            break;
          case PreviewSettingNames.customFilePreview:
            previewValue[PreviewSettingNames.customFilePreview] =
              settingVal.value;
            break;
          case PreviewSettingNames.clothColor:
            previewValue[PreviewSettingNames.clothColor] =
              settingVal.value.toString();
            break;
          case PreviewSettingNames.letterSize:
            let letterSize = settingVal.value.toString();
            let actualLetterSize = letterSize;
            const { designAtrributes, svgRef, selectedSide, selectedProduct, selectedApplique } = this.props;
            if(selectedProduct.subCategoryId.name.toLocaleUpperCase().includes("STOLE")){
              switch(letterSize){
                case "3" : actualLetterSize = 4; break;
                case "2" : actualLetterSize = 3; break;
              }
            }
            // const letterSize = NUMBER_REGREX.exec(settingVal.value.toString());
            let size = (/^[0-9]*$/.test(actualLetterSize))?+actualLetterSize/ designWindowHeightValue:+actualLetterSize / designWindowHeightValue;
            // let size = Array.isArray(letterSize)
            //   ? +letterSize[0] / designWindowHeightValue
            //   : 1;
            
            previewValue[PreviewSettingNames.letterSize] = `${(size)}in`;
            break;
          case PreviewSettingNames.imageSize:
            let imageSize = settingVal.label.split(" ")[0];
            if (imageSize.indexOf('"') !== -1) {
              imageSize = imageSize.split('"')[0];
            }
            //console.log(imageSize, "imageSizeimageSize");
            let Isize = +imageSize / designWindowHeightValue;
            previewValue[PreviewSettingNames.imageSize] = `${Isize}in`;
            break;
          case PreviewSettingNames.embroidaryLocation:
            const location = settingVal.label;
            previewValue[PreviewSettingNames.embroidaryLocation] = location;
            break;
          case PreviewSettingNames.fontFamily:
            const { value } = settingVal.value;
            previewValue[PreviewSettingNames.fontFamily] = value.toString();
            break;
          case PreviewSettingNames.sashFillStrokeMarker:
            previewValue[PreviewSettingNames.sashFillStrokeMarker] =
              settingVal.value;
            break;
          case PreviewSettingNames.backGroundFillStrokeMarker:
            previewValue[PreviewSettingNames.backGroundFillStrokeMarker] =
              settingVal.value;
            break;
          case PreviewSettingNames.foreGroundFillStrokeMarker:
            previewValue[PreviewSettingNames.foreGroundFillStrokeMarker] =
              settingVal.value;
            break;
          case PreviewSettingNames.design:
            previewValue[PreviewSettingNames.design] = settingVal.value;
            break;
        }
      });
    }
    return previewValue;
  }

  getPreviewSvgTextProperties(foregroundStyles: any, sashStyles: any, backgroundStyles?: any) {
    //console.log("foregroundStyles : ", foregroundStyles);
    //console.log("backgroundStyles : ", backgroundStyles);
    const result = {
      fill1: "transparent",
      fill2: "transparent",
      fill3: "transparent",
      sashBody: "transparent",
      sashTrim: "#ffffff",
      stroke1: "#000000",
      stroke2: "transparent",
      stroke3: "#000000",
    };

    const urlDefs: string[] = [];

    if (foregroundStyles) {
      if (!Validation.isValidUrl(foregroundStyles.fill)) {
        result.fill3 = foregroundStyles.fill;
      } else {
        result.fill3 = "url(#patternImg1)";
        const patternImgUrlFront = foregroundStyles.fill; //.replace("http:","https:")
        urlDefs.push(patternImgUrlFront);
      }

      if (foregroundStyles.stroke) {
        result.stroke3 = foregroundStyles.stroke;
      } else if(!Validation.isValidUrl(foregroundStyles.fill)){
        result.stroke3 = foregroundStyles.fill;
      }
    }
    console.log("===result===", result);
    if (sashStyles) {
      if (!Validation.isValidUrl(sashStyles.fill)) {
        result.sashBody = sashStyles.fill;
      } else {
        result.sashBody = "url(#patternImg1)";
        const patternImgUrlFront = sashStyles.fill; //.replace("http:","https:")
        urlDefs.push(patternImgUrlFront);
      }

      if (sashStyles.stroke) {
        result.sashTrim = sashStyles.stroke;
      }
    }

    if (backgroundStyles) {
      if (!Validation.isValidUrl(backgroundStyles.fill)) {
        result.stroke2 = backgroundStyles.fill;
      } else {
        result.stroke2 = "url(#patternImg2)";
        const patternImgUrlBack = backgroundStyles.fill; //.replace("http:","https:")
        urlDefs.push(patternImgUrlBack);
      }

      if (backgroundStyles.stroke) {
        result.stroke1 = backgroundStyles.stroke;
      } else if(!Validation.isValidUrl(backgroundStyles.fill)){
        result.stroke1 = backgroundStyles.fill;
      }
    }

    if(result.stroke1 == "" && result.stroke2 == ""){
      result.stroke2 = foregroundStyles.fill;
      result.stroke1 = foregroundStyles.fill;
    }
    
    return { result, urlDefs };
  }

  elementOffsetRightForTail(text: string) {
    text = text.trim();
    if (text) {
      let rect:any = this.state.appliqueBound; //this.appliqueView.current.getBoundingClientRect();
      //let width = document.documentElement.getBoundingClientRect().width;
      console.log("text.length : ", text.length);
      return text.length == 1 ? rect.x + rect.width - 100 : rect.x + rect.width - 200;
    } else return 0;
  }

  elementOffsetRight(text: string) {
    text = text.trim();
    const ele =
      text != ""
        ? this.appliqueView.current
        : document.querySelector("#previewText");
    if (ele) {
      let rect = ele.getBoundingClientRect();
      //let appliqueArea = this.appliqueView.current.getBoundingClientRect();
      //let width = document.documentElement.getBoundingClientRect().width;
      //console.log("text.length :::::::::: ", text.length);
      if (text != "") {
        return text.length == 1 ? 320 : rect.x + rect.width - 120;
      } else {
        return 249; //rect.x + rect.width - 200;
      }
      //return text != "" ? rect.x + rect.width - 120 : rect.x + rect.width - 200;
    } else return 0;
  }

  elementOffsetTop(text: string) {
    text = text.trim();
    //console.log("text :::::::::::::::::::", text);
    const ele =
      text != ""
        ? this.appliqueView.current
        : document.querySelector("#previewText");
    //console.log("ele :::::::::::::::::::", ele);
    if (ele) {
      let rect = ele.getBoundingClientRect();
      //let appliqueArea = this.appliqueView.current.getBoundingClientRect();
      //let height = document.documentElement.getBoundingClientRect().height;
      //console.log("rect ::::::::::::::::::::::::::::", rect);
      if (text != "") {
        return text.length == 1 ? 96 : rect.y + rect.height - 180;
      } else {
        return 96; //rect.y + rect.height - 180;
      }
      /*return text != ""
        ? rect.y + rect.height - 180
        : rect.y + rect.height - 180;*/
    } else return 0;
  }

  render() {
    /* console.log("GREEK_ALPHABETS ", GREEK_ALPHABETS); */

    const { designAtrributes, svgRef, selectedSide, selectedProduct, selectedApplique } = this.props;
    //console.log("designAtrributes == ", designAtrributes);
    const parseddAppliquePreviewParams = this.parsePreviewParams(
      (designAtrributes as any)[DesignerViewStates.Applique]
    );
    console.log("parseddAppliquePreviewParams : ",parseddAppliquePreviewParams);
    const letterPlacement =
      parseddAppliquePreviewParams[PreviewSettingNames.letterPlacement];
    
      console.log("==]LetterPlacement : ", letterPlacement);

    const displayText =
      parseddAppliquePreviewParams[PreviewSettingNames.contentText];
    const customFilePreview =
      parseddAppliquePreviewParams[PreviewSettingNames.customFilePreview] !=
      "" ? (
        <>
          <img
            src={
              parseddAppliquePreviewParams[
                PreviewSettingNames.customFilePreview
              ]
            }
            alt="custom"
            style={{
              position: "absolute",
              top: `${this.elementOffsetTop(displayText)}px`,
              left: `${this.elementOffsetRight(displayText)}px`,
              width: "100px",
            }}
          />
        </>
      ) : (
        ""
      );

    const fill = parseddAppliquePreviewParams[PreviewSettingNames.clothColor];
    const fontSize =
      parseddAppliquePreviewParams[PreviewSettingNames.letterSize];
    console.log("fontSize ::::::::::::::::::: ", fontSize);
    const fontFamily =
      parseddAppliquePreviewParams[PreviewSettingNames.fontFamily];
    const foreGroundStyle =
      parseddAppliquePreviewParams[
        PreviewSettingNames.foreGroundFillStrokeMarker
      ];
    const sashStyle =
      parseddAppliquePreviewParams[
        PreviewSettingNames.sashFillStrokeMarker
      ];
    const backGroundStyle =
      parseddAppliquePreviewParams[
        PreviewSettingNames.backGroundFillStrokeMarker
      ];
    const { result: svgTextStyles, urlDefs } = this.getPreviewSvgTextProperties(
      foreGroundStyle,
      sashStyle,
      backGroundStyle
    );
    //console.log("urlDefs : ", urlDefs);
    /* console.log("this.props.fontOptions : ", this.props.fontOptions); */
    const fontFamilyValue = this.props.fontOptions.find(
      ({ _id }) => _id === fontFamily
    );
    /* console.log("fontFamilyValue : ", fontFamilyValue); */
    const fontFamilyCssValue = fontFamilyValue
      ? fontFamilyValue.name
      : (isNumberLetters(displayText)?"College Slab":"inherit");
    /* console.log("fontFamilyCssValue : ", fontFamilyCssValue);
    console.log("selectedProduct : ", selectedProduct);
    console.log("selectedApplique", selectedApplique); */
    /*console.log("svgTextStyles.fill1 : ", svgTextStyles.fill1);
    console.log("svgTextStyles.fill2 : ", svgTextStyles.fill2);
    console.log("svgTextStyles.fill3 : ", svgTextStyles.fill3);*/

    /* set writting mode in style */
    let containerStyle = {};           
    if(selectedSide === "front" && ((selectedProduct.subCategoryId.name.toLocaleUpperCase().includes("JACKETS") && selectedApplique && selectedApplique.name != "Script with Tail" && selectedApplique.name != "Monogram" && selectedApplique.name != "Simple Text") || selectedProduct.subCategoryId.name.toLocaleUpperCase().includes("ZIP") || selectedProduct.subCategoryId.name.toLocaleUpperCase().includes("STOLE"))){
      containerStyle = {width: "72%", writingMode: "vertical-lr", textOrientation: "upright"};
    } else {
      containerStyle = {width: "72%"};
    }

    /* if(selectedApplique && selectedApplique.name == "Script with Tail"){
      this.elementOffsetRightForTail("");
      setTimeout(function(obj){
        obj.elementOffsetRightForTail(displayText);
      }, 500, this);
    } */
    //console.log("containerStyle : ", containerStyle);
    /* console.log(`displayText:${displayText.trim()}`);
    console.log('isGreekLetters(displayText) : ', isGreekLetters(displayText)); */
    let ltCount:number = displayText.trim().length;
    //console.log("letterCount : ", ltCount);
    //console.log("this.state.letterCount : ", this.state.letterCount);
    if(ltCount != this.state.letterCount){
      //console.log("this.state.letterCount ====: ", this.state.letterCount);
      if(this.appliqueView.current){
        this.setState({
          appliqueBound: this.appliqueView.current.getBoundingClientRect(),
          letterCount: ltCount
        });
      }
    }

    /* if(letterPlacement != this.state.letterPlacement){
      if(this.appliqueView.current){
        this.setState({
          appliqueBound: this.appliqueView.current.getBoundingClientRect(),
          letterCount: ltCount,
          letterPlacement : letterPlacement
        });
      }
    } */
    
    let letterCountIndex:number = 0;
    let firstPathTagFound:boolean = false;
    let firstPolygonTagFound:boolean = false;

    let firstPreviewPathTagFound:boolean = false;
    let firstPreviewRectTagFound:boolean = false;

    let previewPathTagCount:number = 0;
    let previewRectTagCount:number = 0;

    return (
      <>
        <style type="text/css">
          {this.props.fontOptions.length > 0 &&
            this.props.fontOptions.map(
              (font) => `@font-face {
                  font-family: ${font.name};
                  src: url('${font.value.replace("http:","https:")}')  format('truetype'), /* Safari, Android, iOS */
              }`
            )}
        </style>
        <Container ref={svgRef} fontFamily={`${isNumberLetters(displayText)?"College Slab":fontFamilyCssValue} !important`} style={containerStyle} >
          <PreviewText
            fontsize={fontSize}
            letterPlacement={letterPlacement}
            fontFamily={`${isNumberLetters(displayText)?"College Slab":fontFamilyCssValue} !important`}
            foregroundSettings={foreGroundStyle}
          >
            {fontFamilyCssValue == "Athletic Script" && displayText != "" && (
              <svg
                width="0.5in"
                height="8.5in"
                version="1.1"
                style={{
                  shapeRendering: "geometricPrecision",
                  textRendering: "geometricPrecision",
                  fillRule: "evenodd",
                  clipRule: "evenodd",
                  position: "absolute",
                  top: `-200px`,
                  left: `${this.elementOffsetRightForTail(displayText)}px`,
                }}
                viewBox="0 0 14000 8500"
              >
                <defs>
                  <style type="text/css">
                    {`<![CDATA[
                    .str1 {stroke:#00923F;stroke-width:3;stroke-miterlimit:2.61313}
                    .str0 {stroke:#28166F;stroke-width:3;stroke-miterlimit:2.61313}
                    .fil0 {fill:none}
                    ]]>`}
                  </style>
                </defs>
                <g id="Layer_x0020_1">
                  <metadata id="CorelCorpID_0Corel-Layer" />
                  <path
                    style={{
                      fill: `${
                        svgTextStyles.fill2 == "transparent"
                          ? svgTextStyles.stroke1
                          : "green"
                      }`,
                      stroke: svgTextStyles.stroke2,
                      strokeWidth: 18,
                    }}
                    className=" str0"
                    d="M344.81 4946.14c90.55,15.97 179.57,39.15 266.41,69.56 246.28,85.96 470.69,222.44 689.63,364.83 255.25,166.01 492.13,358.05 718.29,561.9 214.35,193.13 415.57,400.04 602.58,619.86 28.44,8.31 57.53,14.22 86.83,17.28 47.02,5.03 93.39,2.84 139.11,-5.69 117.02,-62.34 239.07,-114.61 365.05,-156.39 199.26,-66.05 404.63,-115.92 614.17,-115.92l3892.61 0 0 -5.91 4644.14 -4.16c115.27,-37.18 219.16,-101.92 324.58,-162.29 137.14,-78.74 277.56,-154.2 393.92,-260.72 200.35,-183.73 309.49,-442.48 428.7,-689.2 133.42,-276.03 244.75,-562.99 307.09,-863.08 65.83,-316.71 98.43,-641.07 57.96,-961.5 -29.96,-237.09 -96.46,-467.63 -185.48,-689.41 -76.11,-189.41 -160.98,-376.2 -283.9,-538.71 -91.43,-121.17 -185.48,-243 -312.78,-324.37 -67.15,-42.87 -141.95,-72.4 -220.25,-86.83 -218.07,62.06 -665.31,694.05 -445.98,886.26 57.74,19.03 97.33,69.99 138.89,115.7 72.61,79.83 113.95,181.76 150.7,283.9 61.02,169.51 89.67,350.39 57.96,527.12 -21,116.58 -71.96,225.07 -127.52,330.27 -44.4,84.21 -79.83,177.6 -156.39,231.63 -68.9,48.56 -152.89,69.56 -237.54,69.56l-6276.9 5.69 0 4.38 -4901.79 0c-119.43,0 -237.75,21.65 -353.46,52.27 -110.24,29.09 -217.85,68.02 -318.68,121.61 -66.71,35.43 -129.04,78.52 -191.16,121.61 -45.71,31.72 -96.89,58.62 -127.52,104.33 -36.96,55.34 -60.15,120.74 -46.37,185.26 10.72,50.3 42.21,93.17 81.15,127.52 61.68,54.46 145.02,77.43 225.94,63.65z"
                  />
                  <path
                    style={{
                      fill: `${
                        svgTextStyles.fill3 == "transparent"
                          ? "blue"
                          : svgTextStyles.fill3
                      }`,
                      stroke: svgTextStyles.stroke3,
                      strokeWidth: 18,
                    }}
                    className=" str1"
                    d="M1080.81 4396.06c-38.72,0 -77.43,2.19 -115.93,5.69 -60.37,5.69 -120.52,15.09 -179.57,29.09 -96.89,22.75 -190.94,56.43 -278,104.33 -85.08,46.81 -163.17,105.21 -231.85,173.67 97.99,14.44 194.66,35.87 289.59,63.65 135.83,39.81 265.96,96.46 394.14,156.39 231.19,108.05 448.16,243.88 654.63,393.92 220.69,160.32 421.26,345.8 619.86,533.03 173.01,162.95 339.24,333.11 498.25,509.62 186.57,-91.65 382.77,-161.63 585.3,-208.44 214.35,-49.65 434.38,-69.56 654.64,-69.56l3746.06 0 0 -8.09 4664.48 -6.13c173.23,-69.99 333.77,-167.76 475.06,-289.81 133.2,-115.05 239.94,-256.56 339.02,-402.45 225.5,-331.37 343.4,-720.69 423.01,-1115.05 47.24,-234.25 82.89,-471.56 72.4,-709.75 -7.44,-166.23 -31.72,-331.15 -72.4,-492.35 -68.46,-271.87 -170.82,-535.87 -336.18,-761.59 -55.56,-75.9 -113.95,-150.04 -182.41,-214.35 -69.78,-65.4 -140.86,-131.45 -228.78,-167.98 -64.94,-26.8 -135.95,-59.65 -203.72,-44.53 -74.23,76.99 -277.6,320.68 -287.93,500.17 -0.6,10.45 20.72,3.16 31.02,4.77 75.02,11.15 146.32,39.15 211.5,78.3 96.89,58.18 166.23,151.14 228.78,246.06 111.33,168.85 156.39,370.74 167.98,573.49 8.96,158.35 -12.69,316.93 -57.96,469.16 -32.81,109.8 -72.18,218.29 -136.04,312.99 -66.28,98.43 -143.48,191.17 -246.28,248.91 -72.83,40.9 -151.57,73.71 -234.69,75.46l-6372.48 0 0 7 -4891.51 4.37z"
                  />
                </g>
              </svg>
            )}
            {customFilePreview}
            <svg
              style={{
                dominantBaseline: "central",
                width: "100%"
              }}
              height={designWindowHeightValue * 1.25 + "in"}
              width={designWindowHeightValue / 1.25 + "in"}
            >
              {/* <rect
              x="0"
              y="0"
              height={designWindowHeightValue * 1.25 + "in"}
            width={designWindowHeightValue / 1.25 + "in"}
              stroke="red"
              stroke-width="3px"
              fill="white"
            /> */}
              <>
                <defs>
                  {urlDefs.map((imageUrl, index) => {
                    //console.log(imageUrl, "imageUrl");
                    return (
                      <pattern
                        id={`patternImg${index + 1}`}
                        patternUnits="userSpaceOnUse"
                        width="100"
                        height="100"
                        key={index}
                      >
                        <image
                          href={imageUrl}
                          x="0"
                          y="0"
                          width="100"
                          height="100"
                        />
                      </pattern>
                    );
                  })}
                </defs>
                {/* <div id="greekL" dangerouslySetInnerHTML={ { __html: GreekLetterSVGs["A"] } }></div> */}
                
                <g
                  id="previewText"
                  style={{
                    overflow: "hidden",
                    textAnchor: "middle",
                    //fontWeight: "bold",
                    fontFamily: `"${fontFamilyCssValue}" !important`
                  }}
                  ref={this.appliqueView}
                >
                  {!isGreekLetters(displayText) 
                  ? (isNumberLetters(displayText)?<><text
                    className="svg-text"
                    x={`${letterPlacement.x_axis}%`}
                    y={`${letterPlacement.y_axis}%`}
                    style={{
                      fill: svgTextStyles.fill1,
                      stroke: svgTextStyles.stroke1,
                      strokeWidth: 18,
                      fontFamily: `"${fontFamilyCssValue}" !important`,
                    }}
                  >
                    {displayText}
                  </text>

                  <text
                    className="svg-text"
                    x={`${letterPlacement.x_axis}%`}
                    y={`${letterPlacement.y_axis}%`}
                    style={{
                      fill: svgTextStyles.fill2,
                      stroke: svgTextStyles.stroke2,
                      strokeWidth: 12,
                      fontFamily: `"${fontFamilyCssValue}" !important`,
                    }}
                  >
                    {displayText}
                  </text>

                  <text
                    
                    className="svg-text"
                    x={`${letterPlacement.x_axis}%`}
                    y={`${letterPlacement.y_axis}%`}
                    style={{
                      fill: svgTextStyles.fill3,
                      stroke: svgTextStyles.stroke3,
                      strokeWidth: 6,
                      fontFamily: `"${fontFamilyCssValue}" !important`,
                    }}
                  >
                    {displayText}
                  </text></>
                  : <><text
                      className="svg-text"
                      x={`${letterPlacement.x_axis}%`}
                      y={`${letterPlacement.y_axis}%`}
                      style={{
                        fill: svgTextStyles.fill1,
                        stroke: svgTextStyles.stroke1,
                        strokeWidth: 1,
                        fontFamily: `"${fontFamilyCssValue}" !important`,
                      }}
                    >
                      {displayText}
                    </text>

                    <text
                      className="svg-text"
                      x={`${letterPlacement.x_axis}%`}
                      y={`${letterPlacement.y_axis}%`}
                      style={{
                        fill: svgTextStyles.fill2,
                        stroke: svgTextStyles.stroke2,
                        strokeWidth: 1,
                        fontFamily: `"${fontFamilyCssValue}" !important`,
                      }}
                    >
                      {displayText}
                    </text>

                    <text
                      className="svg-text"
                      x={`${letterPlacement.x_axis}%`}
                      y={`${letterPlacement.y_axis}%`}
                      style={{
                        fill: svgTextStyles.fill3,
                        stroke: svgTextStyles.stroke3,
                        strokeWidth: 1,
                        fontFamily: `"${fontFamilyCssValue}" !important`,
                      }}
                    >
                      {displayText}
                    </text></>
                  )
                  : <>{displayText != "" && Parser(generateGreekLetterSVG(displayText), {
                    replace: (node:any) => {
                        console.log("================== generateGreekLetterSVG fontSize ==================== ", fontSize);
                        //console.log("node type: ", node.type);
                        //console.log(node);
                        const attribs = node.attribs || {};
                        // Check if the node is an 'img' tag
                        if (node.type === 'tag' && node.name === 'svg') { /*  && node.name === 'svg' */
                          /* adjust svg dimension by font size */
                          let xAxisDelta = 0;
                          let yAxisDelta = 0;
                          switch(fontSize){
                            case "0.4in": 
                              attribs.width = "40px";
                              attribs.height = "40px";
                              xAxisDelta = 10;
                              yAxisDelta = 7;
                            break;
                            case "0.6in": 
                              attribs.width = "60px";
                              attribs.height = "60px";
                              xAxisDelta = 15;
                              yAxisDelta = 10;
                            break;
                            case "0.8in": 
                              attribs.width = "80px";
                              attribs.height = "80px";
                              xAxisDelta = 20;
                              yAxisDelta = 13;
                            break;
                            case "1.2in": 
                              attribs.width = "120px";
                              attribs.height = "120px";
                              xAxisDelta = 30;
                              yAxisDelta = 23;
                            break;
                          }

                          /* console.log("Update the 'x' & 'y' attribute"); */
                          
                          // Update the 'x' & 'y' attribute
                          
                          if(selectedSide === "front" && (selectedProduct.subCategoryId.name.toLocaleUpperCase().includes("JACKETS") || selectedProduct.subCategoryId.name.toLocaleUpperCase().includes("ZIP") || selectedProduct.subCategoryId.name.toLocaleUpperCase().includes("STOLE"))){
                            attribs.x = `${letterPlacement.x_axis}%`;
                            attribs.y = `${parseInt(letterPlacement.y_axis)+(letterCountIndex*yAxisDelta)}%`;
                          } else {
                            let xAxisShiftDelta = 8;
                            switch(true){
                              case displayText.length == 1 && (fontSize == "0.4in" || fontSize == "0.6in"): 
                                attribs.x = `${parseInt(letterPlacement.x_axis) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 1 && fontSize == "0.8in": 
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta/2) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 1 && fontSize == "1.2in": 
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 2 && fontSize == "0.4in": 
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta/2) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 2 && fontSize == "0.6in": 
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 2 && fontSize == "0.8in": 
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta*1.5) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 2 && fontSize == "1.2in": 
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta*3) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 3 && fontSize == "0.4in": 
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 3 && fontSize == "0.6in": 
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta*2) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 3 && fontSize == "0.8in": 
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta*3) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 4 && fontSize == "0.4in":
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta*1.5) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 4 && fontSize == "0.6in":
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta*3) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 5 && fontSize == "0.4in": 
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta*2.5) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              case displayText.length == 6 && fontSize == "0.4in": 
                                attribs.x = `${parseInt(letterPlacement.x_axis) - (xAxisShiftDelta*3) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                              break;
                              default:
                                attribs.x = `${parseInt(letterPlacement.x_axis) + (letterCountIndex*xAxisDelta)}%`;
                                attribs.y = `${letterPlacement.y_axis}%`;
                            }
                          }
                         
                          firstPathTagFound = false;
                          firstPolygonTagFound = false;
                          letterCountIndex++;
                        }

                        if (node.type === 'tag' && node.name === 'path') {
                          //console.log("firstPathTagFound : ", firstPathTagFound);
                          //console.log("displayText : ", displayText);
                          /* console.log("Update the 'fill' & 'stroke' attribute");
                          console.log("svgTextStyles : ", svgTextStyles); */
                          // Update the 'fill' & 'stroke' attribute
                          if(displayText.indexOf("Ξ") !== -1 && (attribs.id == "fgXi1" || attribs.id == "fgXi3")){
                            if(firstPathTagFound){
                            //if(displayText.indexOf("Ξ") !== -1 && (attribs.id == "bgXi" || attribs.id == "fgXi3")){
                              attribs.fill = `${svgTextStyles.fill3}`;
                              attribs.stroke = `${svgTextStyles.stroke3}`;
                            } else {
                              firstPathTagFound = true;
                              attribs.fill = `${svgTextStyles.fill3}`;
                              attribs.stroke = `${svgTextStyles.stroke3}`;
                            }
                          } else {
                            if(firstPathTagFound){
                              attribs.fill = `${svgTextStyles.fill3}`;
                              attribs.stroke = `${svgTextStyles.stroke3}`;
                            } else {
                              firstPathTagFound = true;
                              attribs.fill = `${svgTextStyles.stroke2}`;
                              attribs.stroke = `${svgTextStyles.stroke1}`;
                            }
                          }
                          attribs["stroke-width"] = `4`;
                          /* console.log("node : ", node); */
                        }

                        if (node.type === 'tag' && node.name === 'polygon' && (attribs.id == "fgXi2" || attribs.id == "bgXi")) {
                          //console.log("firstPolygonTagFound: ", firstPolygonTagFound); 
                          /* console.log("Update the 'fill' & 'stroke' attribute");
                          console.log("svgTextStyles : ", svgTextStyles); */
                          // Update the 'fill' & 'stroke' attribute
                          if(firstPolygonTagFound){ 
                            attribs.fill = `${svgTextStyles.fill3}`;
                            attribs.stroke = `${svgTextStyles.stroke3}`;
                          } else {
                            firstPolygonTagFound = true;
                            attribs.fill = `${svgTextStyles.stroke2}`;
                            attribs.stroke = `${svgTextStyles.stroke1}`;
                          }
                          attribs["stroke-width"] = `4`;
                          /* console.log("node : ", node); */
                        }
                        /* console.log("attribs : ", attribs); */
                        node.attribs = attribs;
                        return node;
                      },
                    })}</>
                  }

                  <RenderEmbroidaryPreview
                    EmDesignAtrributes={
                      (this?.props?.designAtrributes as any)[
                        DesignerViewStates.EmboideryLocations
                      ]
                    }
                    selectedSide={this.props.selectedSide}
                    fontOptions={this.props.fontOptions}
                    parsePreviewParams={this.parsePreviewParams}
                    parseddAppliquePreviewParams={parseddAppliquePreviewParams}
                    appliqueRef={this.appliqueView.current}
                    appliText={displayText}
                    svgRef={svgRef.current}
                    appliqueBound={this.state.appliqueBound}
                    selectedProduct={selectedProduct}
                  />
                </g>
              </>
            </svg>
          </PreviewText>
          {selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")
            ?<>{Parser(generatePreviewSVG(selectedProduct.typeId?.type.toLocaleUpperCase()), {
              replace: (node:any) => {
                  //console.log("================== generatePreviewSVG fontSize ==================== ", fontSize);
                  console.log("node type: ", node.type);
                  console.log("node name: ", node.name);
                  console.log(node);
                  const attribs = node.attribs || {};
                  // Check if the node is an 'img' tag
                  if (node.type === 'tag' && node.name === 'svg') { /*  && node.name === 'svg' */
                    previewPathTagCount = 1;
                    previewRectTagCount = 1;
                  }

                  if (node.type === 'tag' && node.name === 'path') {
                    //console.log("firstPathTagFound : ", firstPathTagFound);
                    //console.log("displayText : ", displayText);
                    /* console.log("Update the 'fill' & 'stroke' attribute");
                    console.log("svgTextStyles : ", svgTextStyles); */
                    // Update the 'fill' & 'stroke' attribute
                    /* if(displayText.indexOf("Ξ") !== -1 && (attribs.id == "fgXi1" || attribs.id == "fgXi3")){
                      if(firstPathTagFound){
                      //if(displayText.indexOf("Ξ") !== -1 && (attribs.id == "bgXi" || attribs.id == "fgXi3")){
                        attribs.fill = `${svgTextStyles.fill3}`;
                        attribs.stroke = `${svgTextStyles.stroke3}`;
                      } else {
                        firstPathTagFound = true;
                        attribs.fill = `${svgTextStyles.fill3}`;
                        attribs.stroke = `${svgTextStyles.stroke3}`;
                      }
                    } else {
                      if(firstPathTagFound){
                        attribs.fill = `${svgTextStyles.fill3}`;
                        attribs.stroke = `${svgTextStyles.stroke3}`;
                      } else {
                        firstPathTagFound = true;
                        attribs.fill = `${svgTextStyles.stroke2}`;
                        attribs.stroke = `${svgTextStyles.stroke1}`;
                      }
                    } */
                    /* if(firstPreviewPathTagFound){
                      attribs.fill = `${svgTextStyles.sashBody}`;
                      attribs.stroke = `${svgTextStyles.sashBody}`;
                    } else {
                      firstPreviewPathTagFound = true;
                      attribs.fill = `${svgTextStyles.sashBody}`;
                      attribs.stroke = `${svgTextStyles.sashBody}`;
                    } */

                    switch(previewPathTagCount){
                      case 1:
                        attribs.fill = `${svgTextStyles.sashBody}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                      case 2:
                        attribs.fill = `${svgTextStyles.sashBody}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                      case 3:
                        attribs.fill = `${svgTextStyles.sashTrim}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                      case 4:
                        attribs.fill = `${svgTextStyles.sashTrim}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                      case 5:
                        attribs.fill = `${svgTextStyles.sashTrim}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                      case 6:
                        attribs.fill = `${svgTextStyles.sashTrim}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                    }

                    attribs["stroke-width"] = `1`;
                    /* console.log("node : ", node); */
                    previewPathTagCount++;
                  }

                  if (node.type === 'tag' && node.name === 'rect') {
                    /* if(firstPreviewRectTagFound){
                      attribs.fill = `${svgTextStyles.sashBody}`;
                      attribs.stroke = `${svgTextStyles.sashTrim}`;
                    } else {
                      firstPreviewRectTagFound = true;
                      attribs.fill = `${svgTextStyles.sashTrim}`;
                      attribs.stroke = `${svgTextStyles.sashBody}`;
                    } */

                    switch(previewRectTagCount){
                      case 1:
                        attribs.fill = `${svgTextStyles.sashBody}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                      case 2:
                        attribs.fill = `${svgTextStyles.sashBody}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                      case 3:
                        attribs.fill = `${svgTextStyles.sashTrim}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                      case 4:
                        attribs.fill = `${svgTextStyles.sashTrim}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                      case 5:
                        attribs.fill = `${svgTextStyles.sashTrim}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                      case 6:
                        attribs.fill = `${svgTextStyles.sashTrim}`;
                        attribs.stroke = `${svgTextStyles.sashTrim}`;
                      break;
                    }

                    attribs["stroke-width"] = `1`;
                    previewRectTagCount++;
                  }

                  /* if (node.type === 'tag' && node.name === 'polygon' && (attribs.id == "fgXi2" || attribs.id == "bgXi")) {
                    //console.log("firstPolygonTagFound: ", firstPolygonTagFound); 
                    
                    // Update the 'fill' & 'stroke' attribute
                    if(firstPolygonTagFound){ 
                      attribs.fill = `${svgTextStyles.fill3}`;
                      attribs.stroke = `${svgTextStyles.stroke3}`;
                    } else {
                      firstPolygonTagFound = true;
                      attribs.fill = `${svgTextStyles.stroke2}`;
                      attribs.stroke = `${svgTextStyles.stroke1}`;
                    }
                    attribs["stroke-width"] = `1`;
                   
                  } */
                  /* console.log("attribs : ", attribs); */
                  node.attribs = attribs;
                  return node;
                },
              })}</>
            :<PreviewImage
                src={this.props.image}
                alt="tshirt preview"
                fill={fill}
                style={{maxWidth:"450px"}}
              />
          }
        </Container>
      </>
    );
  }
}

const Container = styled.div<{
  fontFamily: string;
}>`
  position: relative;
  height: 100%;
  font-family: ${(props) => props.fontFamily};
`;

const PreviewText = styled.p<{
  letterPlacement: string;
  fontsize: string;
  fontFamily: string;
  foregroundSettings?: IMaterialFillStrokeForeGround;
  backgroundSettings?: IMaterialFillStrokeBackGround;
}>`
  font-family: ${(props) => props.fontFamily};
  position: absolute;
  top: 0.6in;
  font-size: ${(props) => props.fontsize};
  text-align: center;
  margin: 0;
  width: 100%;
  padding: 0;
  height: ${designWindowHeight};
  width: ${designWindowHeightValue * 1.25 + "in"};

  svg {
    text.svg-text {
      paint-order: stroke fill markers;
      letter-spacing: 5px;
      font-family: ${(props) => `${props.fontFamily} !important`};
    }
  }
`;

const PreviewImage = styled.img<{ fill: string }>`
  /*height: ${designWindowHeight};
  width: ${designWindowHeightValue * 1.25 + "in"};
  height: 100%;*/
  margin-top: 10%;
  path {
    fill: ${(props) => props.fill} !important;
  }
`;

const PreviewSVG = styled.svg<{ fill: string, stroke: string }>`
  /*height: ${designWindowHeight};
  width: ${designWindowHeightValue * 1.25 + "in"};
  height: 100%;*/
  margin-top: 10%;
  path {
    fill: ${(props) => props.fill} !important;
    stroke: ${(props) => props.stroke} !important;
  }
  rect{
    fill: ${(props) => props.fill} !important;
    stroke: ${(props) => props.stroke} !important;
  }
`;

export default Preview;
